import React, { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
const SelectPlaylist = lazy(() => import('./SelectPlaylist/SelectPlaylist'));
const PlaylistSelectMusic = lazy(() => import('./PlaylistSelectMusic/PlaylistSelectMusic')) ;
const PlaylistMusicSequence = lazy(() => import('./PlaylistMusicSequence/PlaylistMusicSequence')) ;
const PlaylistName = lazy(() => import('./PlaylistName/PlaylistName')) ;
const MusicUpload = lazy(() => import('./MusicUpload/MusicUpload')) ;
const MusicOverview = lazy(() => import('./MusicOverview/MusicOverview')) ;

export default () => (
  <Switch>
    <Route exact path="/music/selectPlaylist/:playlistId?" component={SelectPlaylist} />
    <Route
      exact
      path={["/music/new/playlistSelectMusic", "/music/:editPlaylistId/playlistSelectMusic"]}
      component={PlaylistSelectMusic}
    />
    <Route
      exact
      path={["/music/new/playlistMusicSequence", "/music/:editPlaylistId/playlistMusicSequence"]}
      component={PlaylistMusicSequence}
    />
    <Route exact path="/music/new/playlistName" component={PlaylistName} />
    <Route exact path="/music/upload" component={MusicUpload} />
    <Route exact path="/music/overview" component={MusicOverview} />
    <Redirect from="/music" to="/music/selectPlaylist" />
  </Switch>
);

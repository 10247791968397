import {
	EMAIL_CHANGE_CLEAR,
	EMAIL_CHANGE_CLEAR_ERROR,
	EMAIL_CHANGE_START,
	EMAIL_CHANGE_FINISH
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	success: false,
	error: false
};

const emailChangeClear = initialState => ({...initialState});

const emailChangeClearError = state => ({...state, error: false});

const emailChangeStart = state => ({...state, done: false});

const emailChangeFinish = (state, action) => ({
	...state,
	done: true,
	error: action.payload.error,
	success: action.payload.success
});

export const emailChange = (state = initialState, action) => {
	switch (action.type) {
		case EMAIL_CHANGE_CLEAR: return emailChangeClear(initialState);
		case EMAIL_CHANGE_CLEAR_ERROR: return emailChangeClearError(state);
		case EMAIL_CHANGE_START: return emailChangeStart(state);
		case EMAIL_CHANGE_FINISH: return emailChangeFinish(state, action);
		default: return state;
	}
};

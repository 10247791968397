import axios from "axios";
import { getUrls } from "../../common/constants";

export const checkInvite = ({ inviteCode }: {
    inviteCode: string
}) => {
    const url = getUrls('checkpoint', 'check-invite').replace('{inviteCode}', inviteCode);
    return axios.get(url);
};

export const acceptInvite = ({ inviteCode }: {
    inviteCode: string
}) => {
    const url = getUrls('checkpoint', 'accept-invite');
    return axios.post(url, {inviteCode});
};
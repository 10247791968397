import { PAGE_TITLE_SET, PAGE_TITLE_CLEAR, LANG_SET } from '../constants/ui.actionTypes';
import i18n from '../../../localization/i18n';
import moment from 'moment';

const locales = {
	nl: {
		dynamicLocaleLangCode: 'nl-nl',
		momentLangCode: 'nl'
	},
	en: {
		dynamicLocaleLangCode: 'en-gb',
		momentLangCode: 'en'
	}
};

export const setPageTitle = title => ({ type: PAGE_TITLE_SET, payload: title });

export const clearPageTitle = () => ({ type: PAGE_TITLE_CLEAR });

export const setLang = lang => {
	if (locales[lang]) {
		moment.locale(locales[lang].momentLangCode);
		i18n.changeLanguage(lang);
		localStorage.setItem('Tinybots.lang', JSON.stringify(lang));
		return {type: LANG_SET, payload: lang}
	}
};


/**
 * @file Manage state for offline notifications
 * @author Max van Loosbroek
 */

import { createSlice, createAsyncThunk, createEntityAdapter, createAction, createSelector } from '@reduxjs/toolkit'
import { RobotContact, OfflineNotificationsState, RobotContactDto, UpdateNotificationSettingsDto, ContactResponseBody } from './types'
import { getContacts, putContact, deleteContact, patchNotificationSettings, verifyContact, unsubscribeContact } from './api'
import { RootState } from '../../../root.reducer'

export const robotContactsAdapter = createEntityAdapter<RobotContact>({ selectId: (entity) => entity.robotContactId })
export const initialStateOfflineNotifications: OfflineNotificationsState = robotContactsAdapter.getInitialState({
    getting: true,
    getError: false,
    patching: false,
    patchError: false,
    verifying: false,
    verifyError: false,
    verifySuccess: false,
    unsubscribing: false,
    unsubscribeError: false,
    unsubscribeSuccess: false,
    checkErrors: false
})

export const setCheckErrors = createAction<boolean>('offlineNotifications/setCheckErrors')
export const clearState = createAction('offlineNotifications/clearState')

export const fetchRobotContacts = createAsyncThunk(
    'offlineNotifications/fetchRobotContacts',
    async (robotId: number) => {
        const response: { data: RobotContact[] } = await getContacts(robotId)
        return response.data
    }
)

export const verifyRobotContactThunk = createAsyncThunk(
    'offlineNotifications/verifyRobotContact',
    async ({ robotId, robotContactId, code }: { robotId: string, robotContactId: string, code: string }) => {
        try {
            const response: { data: RobotContact[] } = await verifyContact({ robotId, robotContactId, code })
            return response.data
        } catch (error) {
            throw error?.response ? Error(error?.response?.data?.message) : error
        }
    }
)

export const unsubscribeRobotContactThunk = createAsyncThunk(
    'offlineNotifications/unsubscribeContact',
    async ({ robotId, robotContactId, code }: { robotId: string, robotContactId: string, code: string }) => {
        const response: { data: RobotContact[] } = await unsubscribeContact({ robotId, robotContactId, code })
        return response.data
    }
)

export const resendInvite = createAsyncThunk(
    'offlineNotifications/resendInvite',
    async ({ robotId, contact }: { robotId: number, contact: RobotContactDto }) => {
        const response: { data: RobotContact[] } = await putContact(robotId, contact)
        return response.data
    }
)

export const disableNotifications = createAsyncThunk(
    'offlineNotifications/disableNotifications',
    async (robotId: number, thunkApi) => {
        const { offlineNotifications } = thunkApi.getState() as RootState & { offlineNotifications: OfflineNotificationsState }
        const contacts = contactsSelector(offlineNotifications)
        for (const c of contacts) {
            await deleteContact(robotId, c.robotContactId)
        }
        return
    }
)

export const updateOfflineNotifications = createAsyncThunk(
    'offlineNotifications/updateOfflineNotifications',
    async ({ robotId, contact, settings }: { robotId: number, contact: RobotContactDto, settings: UpdateNotificationSettingsDto }, thunkApi): Promise<ContactResponseBody | undefined> => {
        const { offlineNotifications } = thunkApi.getState() as RootState & { offlineNotifications: OfflineNotificationsState }
        const email = contact?.email
        const contacts = robotContactsAdapter.getSelectors().selectAll(offlineNotifications)
        const currentContact = contacts[0]
        const done = !offlineNotifications.getting || offlineNotifications.getError
        const createNewContact = !currentContact && email && done
        const replaceContact = email && (currentContact && email !== currentContact.email)
        const editSettings = (settings.notificationDelay || settings.onlineNotification) && (settings.notificationDelay !== currentContact?.offlineNotificationSettings.notificationDelay 
            || settings.onlineNotification !== currentContact?.offlineNotificationSettings.onlineNotification) 
        if (createNewContact) {
            const { data } = await putContact(robotId, contact)
            const newContact = data.find(c => c.email === email)
            if (!newContact) {
                throw Error('No contact added')
            } else if(editSettings) {
                return patchNotificationSettings({ robotId, robotContactId: newContact.robotContactId, settings }).then(({data}) => data)
            } else {
                return data
            }
        } else if (replaceContact) {
            for (const c of contacts) {
                await deleteContact(robotId, c.robotContactId)
            }
            const { data } = await putContact(robotId, contact)
            const newContact = data.find(c => c.email === email)
            if (!newContact) {
                throw Error('No contact added')
            } else if(editSettings) {
                return patchNotificationSettings({ robotId, robotContactId: newContact.robotContactId, settings }).then(({data}) => data)
            } else {
                return data
            }
        } else if (editSettings) {
            return patchNotificationSettings({ robotId, robotContactId: currentContact.robotContactId, settings }).then(({data}) => data)
        }
        return undefined
    }
)



export const offlineNotificationsSlice = createSlice({
    name: 'offlineNotifications',
    initialState: initialStateOfflineNotifications,
    reducers: {
        clearError: state => ({ ...state, getError: false, patchError: false }),
    },
    extraReducers: builder => {
        builder.addCase(fetchRobotContacts.pending, (state) => ({
            ...state,
            getting: true,
            getError: false
        }))
        builder.addCase(fetchRobotContacts.fulfilled, (state, action) => {
            state.getting = false
            state.getError = false
            robotContactsAdapter.setAll(state, action.payload)
        })
        builder.addCase(fetchRobotContacts.rejected, (state) => ({
            ...state,
            getting: false,
            getError: true
        }))
        builder.addCase(updateOfflineNotifications.pending, (state) => ({
            ...state,
            patching: true,
            patchError: false
        }))
        builder.addCase(updateOfflineNotifications.fulfilled, (state, action) => {
            state.patching = false
            state.patchError = false
            if(action.payload) {
                robotContactsAdapter.setAll(state, action.payload)
            }
        })
        builder.addCase(updateOfflineNotifications.rejected, (state) => ({
            ...state,
            patching: false,
            patchError: true
        }))
        builder.addCase(resendInvite.pending, (state) => ({
            ...state,
            patching: true,
            patchError: false
        }))
        builder.addCase(resendInvite.fulfilled, (state, action) => {
            state.patching = false
            state.patchError = false
            robotContactsAdapter.setAll(state, action.payload)
        })
        builder.addCase(resendInvite.rejected, (state) => ({
            ...state,
            patching: false,
            patchError: true
        }))
        builder.addCase(disableNotifications.pending, (state) => ({
            ...state,
            patching: true,
            patchError: false
        }))
        builder.addCase(disableNotifications.fulfilled, () => {
            return { ...initialStateOfflineNotifications, getting: false }
        })
        builder.addCase(disableNotifications.rejected, (state) => ({
            ...state,
            patching: false,
            patchError: true
        }))
        builder.addCase(verifyRobotContactThunk.pending, (state) => {
            state.verifying = true
            state.verifyError = false
            state.verifySuccess = false
        })
        builder.addCase(verifyRobotContactThunk.fulfilled, (state) => {
            state.verifying = false
            state.verifyError = false
            state.verifySuccess = true
        })
        builder.addCase(verifyRobotContactThunk.rejected, (state, action) => {
            if (action?.error?.message) {
                state.verifyError = action?.error?.message
            } else {
                state.verifyError = true
            }
            state.verifying = false
            state.verifySuccess = false
        })
        builder.addCase(unsubscribeRobotContactThunk.pending, (state) => {
            state.unsubscribing = true
            state.unsubscribeError = false
            state.unsubscribeSuccess = false
        })
        builder.addCase(unsubscribeRobotContactThunk.fulfilled, (state) => {
            state.unsubscribing = false
            state.unsubscribeError = false
            state.unsubscribeSuccess = true
        })
        builder.addCase(unsubscribeRobotContactThunk.rejected, (state) => {
            state.unsubscribing = false
            state.unsubscribeError = true
            state.unsubscribeSuccess = false
        })
        builder.addCase(setCheckErrors, (state, action) => {
            state.checkErrors = action.payload
        })
    }

})

const selfSelector = (state: OfflineNotificationsState) => state
export const contactsSelector = createSelector(selfSelector, robotContactsAdapter.getSelectors().selectAll)

export const contactSelector = createSelector(
    contactsSelector,
    (contacts) => {
        return contacts[0]
    }
)

// Extract the action creators object and the reducer
const { reducer } = offlineNotificationsSlice

export const offlineNotifications = reducer
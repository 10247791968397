/**
 * @file
 * API queries for speech interaction single
 * @author Max van Loosbroek
 */

import axios from 'axios'
import { SpeechInteraction, SpeechInteractionDto } from '../../types'
import { getUrls } from '../../../../common/constants'

export const getSpeechInteractions = (
  robotId: number,
  scriptReferenceId?: number
) => {
  const url = getUrls('custom_commands', 'speech-interactions').replace(
    '{robotId}',
    robotId
  )
  return axios.get<SpeechInteraction[]>(url, {
    params: scriptReferenceId ? { scriptReferenceId } : {}
  })
}

export const getSpeechInteraction = (robotId: number, id: number) => {
  const url =
    getUrls('custom_commands', 'speech-interactions').replace(
      '{robotId}',
      robotId
    ) + `/${id}`
  return axios.get<SpeechInteraction>(url)
}

export const checkSimilarSpeechInteraction = (
  robotId: number,
  body: { command: string }
) => {
  const url =
    getUrls('custom_commands', 'speech-interactions').replace(
      '{robotId}',
      robotId
    ) + `/similar-commands`
  return axios.post<
    {
      id: number
      title: string
      commands: string[]
    }[]
  >(url, body)
}

export const putSpeechInteraction = (
  interaction: Omit<SpeechInteractionDto, 'robotId'>,
  robotId: number
) => {
  const url = getUrls('custom_commands', 'speech-interactions').replace(
    '{robotId}',
    robotId
  )
  return axios.put<SpeechInteraction>(url, interaction)
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import onlineIcon from '../../../assets/images/icon/tessa-online.svg';
import offlineIcon from '../../../assets/images/icon/no-connection.svg';
import intermittentIcon from '../../../assets/images/icon/tessa-intermittent.svg';
import errorIcon from '../../../assets/images/icon/tessa-error.svg';

const Status = styled.div`
	display: flex;
  align-items: flex-start;
  min-height: 50px;
  padding: 10px 0;
  ${props => props.heartbeat.status === 'online' && css`
		color: ${props.theme.colors.applegreen};
	`}
  ${props => props.heartbeat.status === 'offline' && css`
		color: #fa3505;
	`}
  ${props => props.heartbeat.status === 'intermittent' && css`
		color: #e6c029;
	`}
`;
Status.propTypes = {
	heartbeat: PropTypes.object,
	updateStatus: PropTypes.string
};

const Icon = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-width: 65px;
  height: 16px;
  background-size: 15px;
  background: url(${props => {
		return {
			online: onlineIcon,
			offline: offlineIcon,
			intermittent: intermittentIcon,
			error: errorIcon,
			noConnection: errorIcon,
		}[props.status] || offlineIcon
	}}) no-repeat 50% 50%;
`;
Icon.propsTypes = {
	status: PropTypes.string
};
Icon.defaultProps = {
	status: 'error'
};

const Title = styled.h4`
	margin: 0;
	font-size: 14px;
	font-family: Montserrat-Bold;
  ${props => ['error', 'noConnection'].includes(props.status) && css`color: #8f8f8f;`}
`;
Title.propsTypes = {
	status: PropTypes.string
};
Title.defaultProps = {
	status: 'error'
};

const Text = styled.div`
	padding-right: 20px;
	${props => ['error', 'noConnection'].includes(props.status) && css`color: #7f7d77;`}
`;
Text.propsTypes = {
	status: PropTypes.string
};
Text.defaultProps = {
	status: 'error'
};

const Message = styled.p`
	margin: 7px 0 0 0;
  line-height: normal;
  font-family: Montserrat-Light;
  font-size: 12px;
  ${props => props.duration && css`
		color: #75736e;
    strong {
      font-family: Montserrat-Bold;
    }
	`}
`;
Message.propTypes = {
	duration: PropTypes.element
};
Message.defaultProps = {
	duration: null
};

const Update = styled.div`
	line-height: normal;
  margin: 5px 0 15px 0;
`;

const UpdateButton = styled.button`
	margin-left: 25px;
  padding: 5px 15px;
  background-color: transparent;
  text-transform: uppercase;
  line-height: 14px;
  border: 1px solid #595855;
  border-radius: 12px;
  font-size: 14px;
  font-family: Montserrat;
  color: ${props => props.theme.colors.cremewhite};
`;

const stripesMove = keyframes`
	from { background-position: 0 0; }
	to { background-position: 18px 18px;}
`;

const UpdateProgressBar = styled.div`
	margin: 0 20px 0 60px;
  height: 8px;
  &>span {
    display: block;
    position: relative;
  	height: 100%;
    overflow: hidden;
    background-color: #d2cfc6;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-size: 18px 18px;
      overflow: hidden;
      animation: ${stripesMove} 1.5s linear infinite;
      background-image: ${props => {
		const { darkgrey80 } = props.theme.colors;
		return css`
					linear-gradient(-45deg, ${darkgrey80} 25%, transparent 25%, transparent 50%, ${darkgrey80} 50%, ${darkgrey80} 75%, transparent 75%, transparent);
			`}}
    }
  }
`;

const getHeartbeatStatusMessage = status => {
	const heartbeatStatuses = {
		offline: {
			titleTranslationKey: 'HAMBURGER.STATUS_OFFLINE_TITLE',
			messageTranslationKey: 'HAMBURGER.STATUS_OFFLINE_MESSAGE'
		},
		online: {
			titleTranslationKey: 'HAMBURGER.STATUS_ONLINE_TITLE',
			messageTranslationKey: ''
		},
		intermittent: {
			titleTranslationKey: 'HAMBURGER.STATUS_INTERMITTENT_TITLE',
			messageTranslationKey: 'HAMBURGER.STATUS_INTERMITTENT_MESSAGE'
		},
		error: {
			titleTranslationKey: 'HAMBURGER.STATUS_ERROR_TITLE',
			messageTranslationKey: 'HAMBURGER.STATUS_ERROR_MESSAGE'
		},
		noConnection: {
			titleTranslationKey: 'HAMBURGER.STATUS_NO_CONNECTION_TITLE',
			messageTranslationKey: 'HAMBURGER.STATUS_NO_CONNECTION_MESSAGE'
		}
	};

	return heartbeatStatuses[status] || heartbeatStatuses.noConnection;
};

const getDurationMessage = (status, duration, t) => {
	if (status !== 'offline') {
		return null;
	}
	let message;
	if (duration.days) {
		message = duration.days >= 30
			? (
				<>
					<span>{t('HAMBURGER.DURATION_MONTH1')}</span>
					<strong> {
						duration.months < 2 ?
						t('HAMBURGER.DURATION_MONTH2') :
						t('HAMBURGER.DURATION_MONTH2B', { months: duration.months })
					}</strong>
					<span> {t('HAMBURGER.DURATION_MONTH3')}</span>
				</>
			)
			: duration.days >= 2 && duration.days < 30
				? (
					<>
						<span>{t('HAMBURGER.DURATION_DAYS1')}</span>
						<strong> {t('HAMBURGER.DURATION_DAYS2', { days: duration.days })}</strong>
						<span> {t('HAMBURGER.DURATION_DAYS3')}</span>
					</>
				)
				: (
					<>
						<span>{t('HAMBURGER.DURATION_DAY1')}</span>
						<strong> {t('HAMBURGER.DURATION_DAY2')}</strong>
						<span> {t('HAMBURGER.DURATION_DAY3')}</span>
					</>
				)
	}
	else if (duration.hours) {
		message = duration.hours >= 2 && duration.hours <= 23
			? (
				<>
					<span>{t('HAMBURGER.DURATION_HOURS1')}</span>
					<strong> {t('HAMBURGER.DURATION_HOURS2', { hours: duration.hours })}</strong>
					<span> {t('HAMBURGER.DURATION_HOURS3')}</span>
				</>
			)
			: (
				<>
					<span>{t('HAMBURGER.DURATION_HOUR1')}</span>
					<strong> {t('HAMBURGER.DURATION_HOUR2')}</strong>
					<span> {t('HAMBURGER.DURATION_HOUR3')}</span>
				</>
			);
	}

	else if (duration.minutes) {
		message = duration.minutes >= 2 && duration.minutes <= 59
			? (
				<>
					<span>{t('HAMBURGER.DURATION_MINUTES1')}</span>
					<strong> {t('HAMBURGER.DURATION_MINUTES2', { minutes: duration.minutes })}</strong>
					<span> {t('HAMBURGER.DURATION_MINUTES3')}</span>
				</>
			)
			: (
				<>
					<span>{t('HAMBURGER.DURATION_MINUTE1')}</span>
					<strong> {t('HAMBURGER.DURATION_MINUTE2')}</strong>
					<span> {t('HAMBURGER.DURATION_MINUTE3')}</span>
				</>
			);
	}
	else {
		message = null
	}
	return message;
};

const NavbarStatus = ({ email, robotName, heartbeat, updateStatus, onUpdateDownload, onUpdateInstall }) => {
	const { t } = useTranslation();
	const [statusMessage, setStatusMessage] = useState({
		titleTranslationKey: '',
		messageTranslationKey: ''
	});

	const [duration, setDuration] = useState(null);

	const [updateMessage, setUpdateMessage] = useState('');


	useEffect(() => {
		if (heartbeat) {
			setStatusMessage(getHeartbeatStatusMessage(heartbeat.status));
			setDuration(getDurationMessage(heartbeat.status, heartbeat.duration, t))
		}
	}, [heartbeat]);

	useEffect(() => {
		if (updateStatus) {
			setUpdateMessage({
				outdated: t('HAMBURGER.UPDATE_AVAILABLE_MESSAGE'),
				downloading: t('HAMBURGER.UPDATE_DOWNLOADING_MESSAGE'),
				readyToInstall: t('HAMBURGER.UPDATE_LOADED_MESSAGE')
			}[updateStatus] || '')
		}
	}, [updateStatus]);

	return (
		<div style={{maxWidth: '100%'}}>
			<Status heartbeat={heartbeat} updateStatus={updateStatus}>
				<Icon status={heartbeat.status} />
				<Text status={heartbeat.status} style={{overflow: 'hidden'}}>
					{statusMessage.titleTranslationKey && (
						<Title status={heartbeat.status} style={{ marginTop: '-3px', overflow: 'hidden' }}>
							<span style={{ fontFamily: 'Montserrat-Light', fontSize: 12 }}>{email}</span>
							<br></br>
							{ t(statusMessage.titleTranslationKey, { robotName: robotName.substr(robotName.length - 4) })}</Title>
					)}
					{statusMessage.messageTranslationKey && (
						<Message>{t(statusMessage.messageTranslationKey)}</Message>
					)}
					{updateMessage && ['online', 'intermittent'].includes(heartbeat.status) && (
						<Message>{updateMessage}</Message>
					)}
					{duration && <Message duration={duration}>{duration}</Message>}
				</Text>
			</Status>
			{['online', 'intermittent'].includes(heartbeat.status) && updateStatus && updateStatus !== 'upToDate' && (
				<Update>
					{ updateStatus === 'outdated' && (
						<UpdateButton onClick={onUpdateDownload}>
							{ t('HAMBURGER.BUTTON_DOWNLOAD_UPDATE')}
						</UpdateButton>
					)}
					{ updateStatus === 'readyToInstall' && (
						<UpdateButton
							onClick={onUpdateInstall}>
							{ t('HAMBURGER.BUTTON_INSTALL_UPDATE')}
						</UpdateButton>
					)}
					{ updateStatus === 'downloading' && (
						<UpdateProgressBar>
							<span />
						</UpdateProgressBar>
					)}
				</Update>
			)}
		</div>
	)
};

NavbarStatus.propTypes = {
	email: PropTypes.string.isRequired,
	robotName: PropTypes.string.isRequired,
	heartbeat: PropTypes.object,
	updateStatus: PropTypes.string,
	onUpdateDownload: PropTypes.func,
	onUpdateInstall: PropTypes.func
};

export default NavbarStatus;

/* eslint-disable no-use-before-define */
import { APP_TIME_SET } from '../constants/time.actionTypes';
import { getServerTime } from '../../api';
import moment from 'moment';

let interval;
let timer = null;

export const startTimer = () => dispatch => {
  timer = setTimeout(() => dispatch(getTime()), 60000);
};

export const cancelTimer = () => {
  timer && clearTimeout(timer);
  timer = null;
};

export const resetTimer = () => dispatch => {
  cancelTimer();
  dispatch(startTimer());
};

export const startClock = () => (dispatch, getState) => {
  interval && clearInterval(interval);
  interval = setInterval(() => {
    const { msTime } = getState().appTime;
    const newTime = moment(msTime).add(1, 'seconds').valueOf();
    const payload = { msTime: newTime };

    dispatch({ type: APP_TIME_SET, payload });
  }, 1000);
};

export const getTime = () => async dispatch => {
  try {
    const response = await getServerTime();
    const { milliSeconds } = response.data;

    const payload = { msTime: milliSeconds };

    dispatch({ type: APP_TIME_SET, payload });
    dispatch(resetTimer());
    return Promise.resolve();
  } catch (e) {
    const browserTime = Date.now();
    dispatch({ type: APP_TIME_SET, payload: { msTime: browserTime } });
    dispatch(resetTimer());
    return Promise.resolve();
  }
};

export const initTime = () => async dispatch => {
  try {
    const response = await getServerTime();
    const { milliSeconds } = response.data;

    const payload = { msTime: milliSeconds };

    dispatch({ type: APP_TIME_SET, payload });
    dispatch(startClock());
    return Promise.resolve();
  } catch (e) {
    const browserTime = Date.now();
    dispatch({ type: APP_TIME_SET, payload: { msTime: browserTime } });
    dispatch(startClock());
    return Promise.resolve();
  }
};

import { PAGE_TITLE_SET, PAGE_TITLE_CLEAR, LANG_SET } from '../constants/ui.actionTypes';

const initialState = {
  pageTitle: '',
  lang: 'nl'
};

const pageTitleSet = (state, action) => ({...state, pageTitle: action.payload});

const pageTitleClear = state => ({...state, pageTitle: ''});

const langSet = (state, action) => ({...state, lang: action.payload});

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_TITLE_SET: return pageTitleSet(state, action);
    case PAGE_TITLE_CLEAR: return pageTitleClear();
    case LANG_SET: return langSet(state, action);
    default: return state;
  }
};

/**
 * @file Reducer for the state of speech interaction overview
 * @author Max van Loosbroek
 */

import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit'
import { SpeechInteraction } from '../../types'
import { deleteSpeechInteraction, getSpeechInteractions, toggleDefaultSpeechInteraction } from './api'
import { Category } from '../../../mScripts/tessaScriptOverview/types/Category'
import i18n from '../../../../localization/i18n'
import { CategoryItem } from '../../../mScripts/tessaScriptOverview/types/CategoryItem'
import { RootState } from '../../../../root.reducer'
import { createSelector } from '@reduxjs/toolkit'
import { Update } from '@reduxjs/toolkit'
import { putSpeechInteraction } from '../../SpeechInteractionSingle/redux/api'
import { getDefaultSpeechInteractions } from './api';
import { getTooltip } from '../../helpers'
import { maxInteractions } from '../../../../common/constants'

type CategoryEntity = Omit<Category, 'items' | 'itemIds'>

const personalCatLabel = 'SPEECH_INTERACTION.OVERVIEW.CATEGORY_REGULAR'
const defaultCatName = 'SPEECH_INTERACTION.OVERVIEW.CATEGORY_DEFAULT'

export type SpeechInteractionStateOverview = {
  getting: boolean
  getError: boolean
  patching: boolean
  patchError: boolean
  selectedId?: string
  searchString: string
  categories: EntityState<CategoryEntity>
  interactions: EntityState<CategoryItem>
}

export const interactionsAdapter = createEntityAdapter<CategoryItem>({
  selectId: entity => entity.tempId
})
const categoriesAdapter = createEntityAdapter<CategoryEntity>({
  selectId: entity => entity.label
})

export const initialState: SpeechInteractionStateOverview = {
  getting: true,
  getError: false,
  patching: false,
  patchError: false,
  searchString: '',
  interactions: interactionsAdapter.getInitialState(),
  categories: categoriesAdapter.getInitialState({
    ids: ['default', personalCatLabel],
    entities: {
      default: {
        label: 'default',
        name: defaultCatName,
        hide: false,
        overflow: false,
        expanded: false
      },
      [personalCatLabel]: {
        label: personalCatLabel,
        name: personalCatLabel,
        hide: false,
        overflow: false,
        expanded: true
      }
    }
  })
}

export const fetchSpeechInteractions = createAsyncThunk<
  { interactions: SpeechInteraction[],  defaultSpeechInteractions: SpeechInteraction[] },
  {
    robotId: number
  },
  {}
>(
  'speechInteractionOverviewSlice/fetchSpeechInteraction',
  async ({ robotId }: { robotId: number }) => {
    const { data: interactions } = await getSpeechInteractions(robotId)
    const { data: defaultSpeechInteractions } = await getDefaultSpeechInteractions(robotId)
    return { interactions, defaultSpeechInteractions }
  }
)

export const removeSpeechInteraction = createAsyncThunk<
  number | string,
  {
    robotId: number
    interactionId: number | string
  },
  {}
>(
  'speechInteractionOverviewSlice/removeSpeechInteraction',
  async ({ robotId, interactionId }: { robotId: number, interactionId: number | string }) => {
    await deleteSpeechInteraction(robotId, interactionId)
    return interactionId
  }
)

export const toggleSpeechInteraction = createAsyncThunk<
  SpeechInteraction,
  { speechInteraction: Update<SpeechInteraction>, robotId: number},
  {}
>(
  'speechInteractionOverviewSlice/updateSpeechInteraction',
  async (updateObj, thunkApi) => {
    const entities = (thunkApi.getState() as RootState)
      .speechInteractionOverview.interactions.entities
    const { speechInteraction, robotId } = updateObj
    const entity = entities[
      speechInteraction.id.toString()
    ]
    const {
      id,
      commands,
      title,
      scriptReferenceId
    } = entity?.original as SpeechInteraction
    const interaction = {
      id,
      commands,
      title,
      scriptReferenceId,
      enabled: speechInteraction.changes.enabled
    } as Omit<SpeechInteraction, 'robotId'>
    let data
    if (entity?.category === 'default') {
      data = (await toggleDefaultSpeechInteraction(interaction.id, robotId, !!speechInteraction.changes.enabled)).data
    } else {
      data = (await putSpeechInteraction(interaction, robotId)).data
    }
    return data
  }
)

export const speechInteractionOverviewSlice = createSlice({
  name: 'speechInteractionOverviewSlice',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    clearError: state => ({ ...state, getError: false, patchError: false }),
    selectInteraction: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      selectedId: payload
    }),
    searchInteractions: (state, { payload }) => {
      state.searchString = payload
      const interactions = interactionsAdapter
        .getSelectors()
        .selectAll(state.interactions)
      const matches = interactions.filter(i => i.name.toLowerCase().includes(payload || ''))
      if (matches.length > 0 && payload?.length > 0) {
        categoriesAdapter.updateMany(state.categories, matches.map(i => ({
          id: i.category,
          changes: {
            expanded: true
          }
        })))
      }
    },
    updateCategory: (
      state,
      { payload }: PayloadAction<Update<CategoryEntity>>
    ) => {
      categoriesAdapter.updateOne(state.categories, payload)
    },
    toggleMenu: (state, action) => {
      const overflow = !action.payload.overflow
      const toggleItemId = action.payload.tempId
      const items: CategoryItem[] = interactionsAdapter
        .getSelectors()
        .selectAll(state.interactions)
      const openItems = items
        .filter(i => i.overflow === true && i.tempId !== toggleItemId)
        .map(({ tempId }) => {
          return { id: tempId, changes: { overflow: false } }
        })
      const updates = [
        { id: toggleItemId, changes: { overflow } },
        ...openItems
      ]
      interactionsAdapter.updateMany(state.interactions, updates)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSpeechInteractions.pending, state => {
      state.getting = true
      state.getError = false
    })
    builder.addCase(fetchSpeechInteractions.fulfilled, (state, action) => {
      state.getting = false
      state.getError = false

      const items: CategoryItem[] = action.payload.interactions.map(interaction => {
        const item: CategoryItem = {
          tempId: interaction.id.toString(),
          name: interaction.title,
          category: personalCatLabel,
          type: 'interaction',
          showToggle: true,
          enabled: interaction.enabled,
          original: interaction,
          tooltip: getTooltip(interaction.commands)
        }
        return item
      })
      const itemsDefault: CategoryItem[] = action.payload.defaultSpeechInteractions.map(interaction => {
        const item: CategoryItem = {
          tempId: interaction.id.toString(),
          name: interaction.title,
          category: 'default',
          type: 'interaction',
          showToggle: true,
          enabled: interaction.enabled,
          original: interaction,
          locked: true,
          tooltip: getTooltip(interaction.commands)
        }
        return item
      })
      interactionsAdapter.setAll(state.interactions, [...items, ...itemsDefault])
    })
    builder.addCase(fetchSpeechInteractions.rejected, state => {
      state.getting = false
      state.getError = true
    })
    builder.addCase(toggleSpeechInteraction.pending, (state, action) => {
      state.patching = true
      state.patchError = false
      interactionsAdapter.updateOne(state.interactions, {
        id: action.meta.arg.speechInteraction.id,
        changes: action.meta.arg.speechInteraction.changes,
      })
    })
    builder.addCase(toggleSpeechInteraction.fulfilled, (state, { payload }) => {
      state.patching = false
      state.patchError = false
      interactionsAdapter.updateOne(state.interactions, {
        id: payload.id,
        changes: {
          enabled: payload.enabled
        }
      })
    })
    builder.addCase(toggleSpeechInteraction.rejected, (state, { meta: { arg } }) => {
      state.patching = false
      state.patchError = true
      interactionsAdapter.updateOne(state.interactions, {
        id: arg.speechInteraction.id,
        changes: {
          enabled: !arg.speechInteraction.changes.enabled
        }
      })
    })
    builder.addCase(removeSpeechInteraction.pending, (state) => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(removeSpeechInteraction.fulfilled, (state, { payload }) => {
      state.patching = false
      state.patchError = false
      interactionsAdapter.removeOne(state.interactions, payload)
    })
    builder.addCase(removeSpeechInteraction.rejected, (state, { meta: { arg } }) => {
      state.patching = false
      state.patchError = true
    })
  }
})

export const selectSpeechInteractionOverview = (
  state: RootState
): SpeechInteractionStateOverview => state.speechInteractionOverview
export const searchStringSelector = createSelector(
  selectSpeechInteractionOverview,
  state => state.searchString?.toLowerCase()
)
export const selectInteractions = createSelector(
  selectSpeechInteractionOverview,
  speechInteractionOverview =>
    interactionsAdapter
      .getSelectors()
      .selectAll(speechInteractionOverview.interactions)
)
export const selectCategories = createSelector(
  selectSpeechInteractionOverview,
  speechInteractionOverview =>
    categoriesAdapter
      .getSelectors()
      .selectAll(speechInteractionOverview.categories)
      .map(c => ({
        ...c,
        name: i18n.t(c.name)
      }))
)

export const selectedInteraction = createSelector(
  selectSpeechInteractionOverview,
  speechInteractionOverview =>
    speechInteractionOverview.selectedId !== undefined
      ? speechInteractionOverview.interactions.entities[
          speechInteractionOverview.selectedId
        ]
      : undefined
)

const gettingSelector = (state: RootState) => state.speechInteractionOverview.getting
const patchingSelector = (state: RootState) => state.speechInteractionOverview.patching
export const loadingSelector = createSelector(
  gettingSelector,
  patchingSelector,
  (getting, patching) => {
    return getting || patching
  }
)
export const selectOverviewInteractions = createSelector(
  selectInteractions,
  selectedInteraction,
  searchStringSelector,
  selectCategories,
  gettingSelector,
  (interactions, selected, searchString, categories, getting) => {
    if(!getting && !interactions.length) {
      return []
    }
    const items: CategoryItem[] = interactions.map(interaction => {
      const matchesSearch = interaction.name
        .toLowerCase()
        .includes(searchString || '')
      const item: CategoryItem = {
        ...interaction,
        selected: selected?.tempId === interaction.tempId,
        hide: !matchesSearch
      }
      return item
    })
    return categories.map(c => {
      const cItems = items.filter(i => i.category === c.label)
      let info = ''
      if(c.label !== 'default') {
        info = `${cItems.length} / ${maxInteractions}`
      }
      return {
        ...c,
        info,
        itemIds: interactions.filter(i => i.category === c.label).map(i => i.tempId),
        items: cItems,
        hide: !cItems.length
      }
    })
  }
)

// Extract the action creators object and the reducer
export const {
  clearState,
  clearError,
  selectInteraction,
  searchInteractions,
  updateCategory,
  toggleMenu
} = speechInteractionOverviewSlice.actions
const { reducer } = speechInteractionOverviewSlice
export const speechInteractionOverview = reducer

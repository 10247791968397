import { CaseReducer, Update, PayloadAction } from '@reduxjs/toolkit'
import { addScriptStep } from '../helpers/addScriptStep'
import { removeScriptStep } from '../helpers/removeStep'
import { ScriptStoreNext, NextGoTo, NextMultipleChoiceCommand } from '../types/Next'
import { ScriptFeedback } from '../types/ScriptExecution'
import { FullScriptState, ScriptVersion } from '../types/ScriptVersion'
import {
  initialState,
  scriptNextAdapter,
  scriptNodesAdapter,
  scriptStepsAdapter,
  scriptVersionsAdapter,
  EditCommandPayload
} from './reducer'
import { getFeedbackSteps, nextSelector } from './selectors'
import { v4 as uuidv4 } from 'uuid'
import { assignTreePositions } from '../helpers/assignTreePositions'
import { ScriptState } from './ScriptState'
import { ScriptStoreNode, ScriptNodeReport } from '../types/ScriptNode'
import { createFormFields } from '../helpers/createNewStep'

// General case reducers
export const clearScriptReducer: CaseReducer<ScriptState> = () => initialState
export const clearScriptsErrorsReducer: CaseReducer<ScriptState> = state => {
  state.getting = false
  state.putting = false
  state.getError = false
  state.putError = false
  state.conversionError = false
  state.permissionError = false
}
export const loadLocalStateReducer: CaseReducer<ScriptState> = state => {
  try {
    const serializedState = localStorage.getItem('tessaScript')
    if (serializedState === null) {
      return state
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return state
  }
}
// Edit script
export const editScriptReducer: CaseReducer<ScriptState> = (state, action) => {
  state.script.scriptName = action.payload.scriptName
  state.script.scriptCategory = action.payload.scriptCategory
  state.script.scriptLanguage = action.payload.scriptLanguage
}
// Fetch script
export const fetchTessaScriptPendingReducer: CaseReducer<ScriptState> = (
  state: ScriptState
) => {
  state.getting = true
  state.getError = false
}
export const fetchTessaScriptRejectedReducer: CaseReducer<ScriptState> = (
  state: ScriptState,
  action
) => {
  if (action.error && action.error.message === 'Conversion error') {
    state.conversionError = true
  } else {
    state.getError = true
  }
  state.getting = false
}
export const fetchTessaScriptFullfilledReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  if (!action.payload) {
    state.getting = false
    state.getError = false
    return
  }
  try {
    const { script, scriptSteps, scriptNodes, scriptNext }: FullScriptState =
      action.payload
    if (action.meta.arg?.template) {
      state.script = {} as ScriptVersion
    } else {
      state.script = script
      if (!script.scriptLanguage) {
        state.script.scriptLanguage = 'nl-NL'
      }
    }
    scriptStepsAdapter.setAll(state.scriptSteps, scriptSteps)
    scriptNodesAdapter.setAll(state.scriptNodes, scriptNodes)
    scriptNextAdapter.setAll(state.scriptNext, scriptNext)
    const treePositions = assignTreePositions(state)
    scriptStepsAdapter.updateMany(state.scriptSteps, treePositions)
    state.getting = false
    state.getError = false
  } catch (e) {
    state.getting = false
    state.getError = true
  }
}
// Put script
export const putTessaScriptPendingReducer: CaseReducer<ScriptState> = (
  state: ScriptState
) => {
  state.putting = true
  state.putError = false
  state.permissionError = false
}
export const putTessaScriptRejectedReducer: CaseReducer<ScriptState> = (
  state: ScriptState,
  action
) => {
  if (action.error && action.error.message === 'Missing permissions') {
    state.permissionError = true
    state.putting = false
  } else {
    state.putting = false
    state.putError = true
  }
}
export const putTessaScriptFulfilledReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  state.putting = false
  state.putError = false
  state.permissionError = false
  state.script.scriptReferenceId = action.payload.script.scriptReferenceId
  state.script.scriptCategory = action.payload.script.scriptCategory
}
// Get script names
export const getScriptNamesPendingReducer: CaseReducer<ScriptState> = (
  state: ScriptState
) => {
  state.getNames = true
  state.getNamesError = false
}
export const getScriptNamesRejectedReducer: CaseReducer<ScriptState> = (
  state: ScriptState
) => {
  state.getNames = false
  state.getNamesError = true
}
export const getScriptNamesFulfilledReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  state.getNames = false
  state.getNamesError = false
  scriptVersionsAdapter.addMany(
    state.scriptVersions,
    action.payload as (ScriptVersion & { id: number })[]
  )
}
// Get execution
export const getScriptExecutionFulfilledReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  const {
    scriptExecution: { planned, scheduleId },
    scriptExecutionSteps
  } = action.payload
  state.scriptExecution = action.payload.scriptExecution
  const feedback: ScriptFeedback = {
    planned,
    scheduleId,
    scriptFeedbackArray: scriptExecutionSteps
      ? getFeedbackSteps(scriptExecutionSteps)(state)
      : []
  }
  state.feedback = feedback
}
// Edit script steps
export const addScriptStepReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  addScriptStep({
    scriptStepsAdapter,
    scriptNodesAdapter,
    scriptNextAdapter,
    state,
    payload: action.payload
  })
}
export const removeStepReducer: CaseReducer<ScriptState> = (state, action) => {
  const step = state.scriptSteps.entities[action.payload]
  const scriptNext = nextSelector(state)
  if (step?.stepType === 'goto') {
    // remove extra next
    const removeNext: ScriptStoreNext = scriptNext.find(
      nxt =>
        nxt.scriptStepTempId === step.tempId &&
        (nxt as NextGoTo).maxReached === false
    )!
    scriptNextAdapter.removeOne(state.scriptNext, removeNext.tempId)
  }
  removeScriptStep({
    scriptStepsAdapter,
    scriptNodesAdapter,
    scriptNextAdapter,
    state,
    payload: action.payload
  })
}
export const changeStepTypeReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  const { stepType, tempId } = action.payload
  const step = state.scriptSteps.entities[tempId]
  if (step?.stepType === stepType) {
    return state
  }
  // remove/update goto steps
  if (step?.stepType === 'goto') {
    const scriptNext = nextSelector(state)
    const updateNext: ScriptStoreNext = scriptNext.find(
      nxt =>
        nxt.scriptStepTempId === tempId && (nxt as NextGoTo).maxReached === true
    )!
    const removeNext: ScriptStoreNext = scriptNext.find(
      nxt =>
        nxt.scriptStepTempId === tempId &&
        (nxt as NextGoTo).maxReached === false
    )!
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: updateNext.tempId,
      changes: {
        nextType: 'then'
      }
    })
    scriptNextAdapter.removeOne(state.scriptNext, removeNext.tempId)
  }
  const next = nextSelector(state)
  const parentNext = next.find(
    nxt =>
      nxt.nextStepTempId === tempId && (nxt as NextGoTo).maxReached !== false
  )
  const linkedGotos = next.filter(
    nxt =>
      nxt.nextStepTempId === tempId && (nxt as NextGoTo).maxReached === false
  )
  const linkedGotoIds = linkedGotos.map(nxt => nxt.tempId)
  removeScriptStep({
    scriptStepsAdapter,
    scriptNodesAdapter,
    scriptNextAdapter,
    state,
    payload: tempId
  })
  addScriptStep({
    scriptStepsAdapter,
    scriptNodesAdapter,
    scriptNextAdapter,
    state,
    payload: {
      stepType,
      parentNextTempId: parentNext?.tempId,
      tempId
    }
  })
  if (linkedGotoIds.length) {
    const updates = linkedGotoIds.map(id => ({
      id,
      changes: {
        nextStepTempId: tempId
      }
    }))
    scriptNextAdapter.updateMany(state.scriptNext, updates)
  }
  const treePositions = assignTreePositions(state)
  scriptStepsAdapter.updateMany(state.scriptSteps, treePositions)
}
export const editScriptStepReducer: CaseReducer<ScriptState> = (state, action) => {
  scriptStepsAdapter.updateOne(state.scriptSteps, action.payload)
}
// Edit script nodes
export const editNodeReducer: CaseReducer<ScriptState> = (state, action) => {
  scriptNodesAdapter.updateOne(state.scriptNodes, action.payload)
  state.stateHasChanged = true
}
// Edit report node form field
export const editFormFieldReducer: CaseReducer<ScriptState> = (state, action) => {
  const { nodeTempId, key, value } = action.payload
  const node = scriptNodesAdapter.getSelectors().selectById(state.scriptNodes, nodeTempId) as ScriptNodeReport
  const changes: Partial<ScriptStoreNode> = {
    formFields: node.formFields.map(field => {
      if(field.key === key) {
        return {
          ...field,
          value
        }
      } else {
        return field
      }
    })
  }
  const update: Update<ScriptStoreNode> = {
    id: nodeTempId,
    changes
  }
  scriptNodesAdapter.updateOne(state.scriptNodes, update)
  state.stateHasChanged = true
}
export const editReportTypeReducer: CaseReducer<ScriptState> = (state, action) => {
  const { nodeTempId, reportType } = action.payload
  const node = scriptNodesAdapter.getSelectors().selectById(state.scriptNodes, nodeTempId) as ScriptNodeReport
  const report = state.reports.find(r => reportType === r.reportType)
  if (!report) {
    throw Error('Report not found')
  }
  const formFields = createFormFields(report)
  const changes: Partial<ScriptStoreNode> = {
    tempId: uuidv4(),
    reportType,
    formFields
  }
  const update: Update<ScriptStoreNode> = {
    id: nodeTempId,
    changes
  }
  scriptNodesAdapter.updateOne(state.scriptNodes, update)
  state.stateHasChanged = true
}
// Multiple choice commands
export const addCommandReducer: CaseReducer<ScriptState> = (state, action) => {
  const next = scriptNextAdapter
    .getSelectors()
    .selectById(
      state.scriptNext,
      action.payload.nextTempId
    ) as NextMultipleChoiceCommand
  if (next?.secondaryCommands) {
    const secondaryCommands = [...next?.secondaryCommands, '']
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: action.payload.nextTempId,
      changes: { secondaryCommands }
    })
  }
}
export const editCommandReducer: CaseReducer<ScriptState, PayloadAction<EditCommandPayload>> = (state, action) => {
  const next = scriptNextAdapter
    .getSelectors()
    .selectById(
      state.scriptNext,
      action.payload.nextTempId
    ) as NextMultipleChoiceCommand
  const value = action.payload.value.slice(0, 64)
  if(action.payload.commandId === 'primary') {
    const primaryCommand = value
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: action.payload.nextTempId,
      changes: { primaryCommand }
    })
  } else if (next?.secondaryCommands?.length) {
    const secondaryCommands = [...next?.secondaryCommands]
    secondaryCommands[action.payload.commandId] = value
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: action.payload.nextTempId,
      changes: { secondaryCommands }
    })
  }
}
export const checkMultipleChoiceCommandReducer: CaseReducer<ScriptState> = (
  state,
  action
) => {
  const next = scriptNextAdapter
    .getSelectors()
    .selectById(
      state.scriptNext,
      action.payload.tempId
    ) as NextMultipleChoiceCommand
  if(action.payload.commands?.length) {
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: action.payload.tempId,
      changes: {
        similarCommands: {
          ...next.similarCommands,
          [action.payload.commandId]: {
            commands: action.payload.commands,
            command:  action.payload.command
          }
      }} as unknown as Partial<ScriptStoreNext>
    })
  } else {
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: action.payload.tempId,
      changes: {
        similarCommands: {
          ...next.similarCommands,
          [action.payload.commandId]: undefined
      }} as unknown as Partial<ScriptStoreNext>
    })
  }
}

// Can only remove secondary commands
export const removeCommandReducer: CaseReducer<ScriptState> = (state: ScriptState, action) => {
  const next = scriptNextAdapter
    .getSelectors()
    .selectById(
      state.scriptNext,
      action.payload.nextTempId
    ) as NextMultipleChoiceCommand
  const secondaryCommands = [...next?.secondaryCommands]
  secondaryCommands.splice(action.payload.commandId, 1)
  scriptNextAdapter.updateOne(state.scriptNext, {
    id: action.payload.nextTempId,
    changes: { secondaryCommands }
  })
}
// Multiple choice intentions
export const addIntentionReducer: CaseReducer<ScriptState> = (state: ScriptState, action) => {
  const step = scriptStepsAdapter
    .getSelectors()
    .selectById(
      state.scriptSteps,
      action.payload.stepTempId
    )
  const nextTempId = uuidv4()
  const nextStepTempId = uuidv4()
  if (step?.tempId) {
    scriptNextAdapter.addOne(state.scriptNext, {
      tempId: nextTempId,
      scriptStepTempId: step.tempId,
      nextType: 'multipleChoice',
      intentionType: 'command',
      primaryCommand: '',
      secondaryCommands: []
    })
    addScriptStep({
      scriptStepsAdapter,
      scriptNodesAdapter,
      scriptNextAdapter,
      state,
      payload: {
          stepType: 'say',
          parentNextTempId: nextTempId,
          tempId: nextStepTempId
      }
    })
  }
}
export const removeIntentionReducer: CaseReducer<ScriptState> = (state: ScriptState, action) => {
  const next = scriptNextAdapter
    .getSelectors()
    .selectById(
      state.scriptNext,
      action.payload.nextTempId
    ) as NextMultipleChoiceCommand
  if (next?.nextStepTempId) {
      removeScriptStep({
          scriptStepsAdapter,
          scriptNodesAdapter,
          scriptNextAdapter,
          state,
          payload: next?.nextStepTempId,
          removeBranch: true
        })
  }
  scriptNextAdapter.removeOne(state.scriptNext, action.payload.nextTempId)
}
// Go To
export const setGotoReducer: CaseReducer<ScriptState> = (state, action) => {
  const steps = scriptStepsAdapter
    .getSelectors()
    .selectAll(state.scriptSteps)
  const step = scriptStepsAdapter
    .getSelectors()
    .selectById(state.scriptSteps, action.payload.tempId)
  const nextStep = steps.find(
    s =>
      s.treePosition === action.payload.positionString &&
      s.treePosition !== step?.treePosition
  )
  const next = scriptNextAdapter
    .getSelectors()
    .selectAll(state.scriptNext)
  const nextGoTo = next.find(
    nxt =>
      nxt.scriptStepTempId === action.payload.tempId &&
      (nxt as NextGoTo).maxReached === false
  )
  if (!nextGoTo) {
    return state
  }
  if (!nextStep) {
    const update: Update<ScriptStoreNext> = {
      id: nextGoTo.tempId,
      changes: {
        nextStepTempId: undefined
      }
    }
    scriptNextAdapter.updateOne(state.scriptNext, update)
  } else {
    const update: Update<ScriptStoreNext> = {
      id: nextGoTo.tempId,
      changes: {
        nextStepTempId: nextStep.tempId
      }
    }
    scriptNextAdapter.updateOne(state.scriptNext, update)
  }
}
// Report reducers
export const getReportsFinishedReducer: CaseReducer<ScriptState> = (state, action) => {
  state.reports = action.payload
}
// UI reducers
export const setScrollToTempIdReducer: CaseReducer<ScriptState> = (state, action) => {
  state.scrollToStep = {
    tempId: action.payload.tempId,
    time: new Date().getTime()
  }
}

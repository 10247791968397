import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './root.reducer';


export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false
	})
});

export const initStore = () => {
	return store;
}

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch
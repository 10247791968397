import { minPasswordStrength } from '../../../../../common/constants/security';
import {
	RESET_PASSWORD_CLEAR,
	RESET_PASSWORD_SHOW_POP_UP,
	RESET_PASSWORD_HIDE_POP_UP,
	RESET_PASSWORD_SET_STRENGTH,
	RESET_PASSWORD_START,
	RESET_PASSWORD_FINISH
} from '../constants/actionsTypes';

export const initialState = {
	done: true,
	passwordStrength: '',
	success: false,
	error: false,
	newWeakPassword: ''
};

const resetPasswordClear = initialState => initialState;

const resetPasswordShowPopUp = (state, action) => ({ ...state, newWeakPassword: action.payload });

const resetPasswordHidePopUp = state => ({ ...state, newWeakPassword: '' });

const resetPasswordSetStrength = (state, action) => ({
	...state,
	passwordStrength: action.payload ? action.payload >= minPasswordStrength ? 'strong' : 'weak' : ''
});

const resetPasswordStart = state => ({ ...state, done: false, newWeakPassword: '' });

const resetPasswordFinish = (state, action) => ({ ...state, done: true, ...action.payload });

export const resetPassword = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD_CLEAR: return resetPasswordClear(initialState);
		case RESET_PASSWORD_SHOW_POP_UP: return resetPasswordShowPopUp(state, action);
		case RESET_PASSWORD_HIDE_POP_UP: return resetPasswordHidePopUp(state);
		case RESET_PASSWORD_SET_STRENGTH: return resetPasswordSetStrength(state, action);
		case RESET_PASSWORD_START: return resetPasswordStart(state);
		case RESET_PASSWORD_FINISH: return resetPasswordFinish(state, action);
		default: return state;
	}
};

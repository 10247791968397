import {
  SESSION_CREATE,
  SESSION_LOGOUT,
  SESSION_GET_DATA_START,
  SESSION_GET_DATA_FINISH,
  SESSION_ACTIVATE_ACCOUNT,
  SESSION_ENABLE_ACCOUNT_ACTIVATION,
  SESSION_CLEAR_ERROR,
  SET_EULA_EXPIRED
  
} from '../constants/actionTypes';
import { isAuthenticated } from '../../utils';

const initialState = {
  isAuthenticated: false,
  access_token: null,
  refresh_token: null,
  expires_in: null,
  token_creation_time: null,
  internal_date: null,
  done: false,
  error: false,
  activationLinkSent: false,
  eulaExpired: false
};

const sessionCreate = (startingState, state, action) => (
  isAuthenticated(action.session.access_token)
    ? { ...state, ...action.session, isAuthenticated: true, eulaExpired: false }
    : startingState
);

const sessionLogout = (startingState, action) => {
  localStorage.clear();
  return (action.payload
    ? { ...startingState, error: action.payload.error, eulaExpired: action.payload?.eulaExpired }
    : startingState);
};

const sessionGetDataStart = state => ({ ...state, done: false });

const sessionGetDataFinish = state => ({ ...state, done: true, eulaExpired: false });

const sessionActivateAccount = state => ({ ...state, activationLinkSent: true });

const sessionEnableAccountActivation = state => ({ ...state, activationLinkSent: false });

const sessionClearError = state => ({ ...state, error: false, eulaExpired: false });

const setEulaExpired = (state, action) => ({ ...state, eulaExpired: action.payload });


// eslint-disable-next-line import/prefer-default-export
export const session = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_CREATE: return sessionCreate(initialState, state, action);
    case SESSION_LOGOUT: return sessionLogout(initialState, action);
    case SESSION_GET_DATA_START: return sessionGetDataStart(state);
    case SESSION_GET_DATA_FINISH: return sessionGetDataFinish(state);
    case SESSION_ACTIVATE_ACCOUNT: return sessionActivateAccount(state);
    case SESSION_ENABLE_ACCOUNT_ACTIVATION: return sessionEnableAccountActivation(state);
    case SESSION_CLEAR_ERROR: return sessionClearError(state);
    case SET_EULA_EXPIRED: return setEulaExpired(state, action);
    default: return state;
  }
};

import {
  SET_ROBOTS,
  SET_ROBOT_CONFIG,
  ROBOTS_HIDE_UPDATE_BANNER,
  ROBOTS_SET_UPDATE_STATUS,
  INVITE_PAIR_ROBOT,
  INVITE_PAIR_ROBOT_ERROR,
  INVITE_PAIR_ROBOT_SUCCESS,
  SET_ACTIVE_ROBOT,
  SET_LOADING,
  SET_LOADING_FINISHED,
  ROBOTS_SHOW_UPDATE_POPUP
} from '../constants/actionTypes'
import moment from 'moment'
import { updateRobot } from '../../../../common/api'
import { downloadUpdate, installUpdate } from '../../api'
import { acceptInvite } from '../../../invite/api'
import { getConfiguration, getBots } from '../../api/index'
import { sendHeartbeat } from '../../../../common/redux/actions/heartbeat.actions'
import { fetchSettings } from '../../../robotSettings/redux/reducer'

export const invitePairRobot = () => ({ type: INVITE_PAIR_ROBOT })

export const invitePairRobotError = error => ({
  type: INVITE_PAIR_ROBOT_ERROR,
  payload: error
})

export const invitePairRobotSuccess = robots => ({
  type: INVITE_PAIR_ROBOT_SUCCESS,
  payload: robots
})

export const setRobots = robots => ({ type: SET_ROBOTS, robots })

export const setRobotConfig = config => ({ type: SET_ROBOT_CONFIG, config })

export const hideUpdateBanner = () => ({ type: ROBOTS_HIDE_UPDATE_BANNER })

export const setShowUpdatePopUp = payload => ({ type: ROBOTS_SHOW_UPDATE_POPUP, payload })

export const setRobotUpdateStatus = status => ({ type: ROBOTS_SET_UPDATE_STATUS, payload: status })

export const setActiveRobot = id => ({ type: SET_ACTIVE_ROBOT, payload: id })

export const setLoading = id => ({ type: SET_LOADING, payload: id })

export const setLoadingFinished = id => ({
  type: SET_LOADING_FINISHED,
  payload: id
})

export const getRobotConfig = (id) => async (dispatch) => {
  const robotConfig = await getConfiguration(id)
  dispatch(setRobotConfig(robotConfig.data))
}

export const setActiveRobotAsync = (id, history, destination) => async (dispatch, getState) => {
  localStorage.removeItem('tessaScriptState')
  dispatch(setLoading())
  try {
    const allRobots = getState().allRobots
    if (allRobots.length) {
      const robotConfig = await getConfiguration(id)
      dispatch(setActiveRobot(id))
      dispatch(setRobots(allRobots))
      dispatch(setRobotConfig(robotConfig.data))
      dispatch(sendHeartbeat(id))
      dispatch(fetchSettings(id))
    }
    dispatch(setLoadingFinished())
    if (history) {
      let historyState = {}
      if (destination === '/robot-settings') {
        historyState = {
          showProfile: true
        }
      }
      history.replace({ pathname: destination ?? '/', state: historyState })
    }
    return
  } catch (e) {
    dispatch(setLoadingFinished())
    return Promise.reject(e)
  }
}

export const fetchRobots = () => async dispatch => {
  const robots = await getBots()
  dispatch(setRobots(robots.data))
}

export const setTessaUpdating = () => dispatch => {
  const updateUntil = moment()
    .add(1, 'days')
    .format()
  const session = JSON.parse(window.localStorage.getItem('Tinybots.session'))
  session.tessaUpdating = updateUntil
  window.localStorage.setItem('Tinybots.session', JSON.stringify(session))
  dispatch(hideUpdateBanner())
}

export const updateTessa = robotId => async dispatch => {
  try {
    await updateRobot(robotId)
    dispatch(setTessaUpdating())
  } catch (e) {}
}

export const installTessaUpdate = robotId => async () => {
  await installUpdate(robotId)
}

export const downloadTessaUpdate = robotId => async dispatch => {
  try {
    await downloadUpdate(robotId)
    dispatch(setRobotUpdateStatus('downloading'))
  } catch (e) {}
}

export const acceptRobotInvite = inviteCode => async dispatch => {
  try {
    dispatch(invitePairRobot())
    const { data: robots } = await acceptInvite({ inviteCode })
    dispatch(invitePairRobotSuccess(robots))
  } catch (e) {
    dispatch(invitePairRobotError(e?.response))
  }
}

// declare document;

export const getPos = element => {
  if (element.selectionStart) {
    return element.selectionStart;
  }
  if (document.selection) {
    element.focus();
    const sel = document.selection.createRange();
    const selLen = document.selection.createRange().text.length;
    sel.moveStart('character', -element.value.length);
    return sel.text.length - selLen;
  }
  return 0;
};

export const setPos = (element, caretPos) => {
  if (element.createTextRange) {
    const range = element.createTextRange();
    range.move('character', caretPos);
    range.select();
  } else {
    element.focus();
    if (element.selectionStart !== undefined) {
      element.setSelectionRange(caretPos, caretPos);
    }
  }
};

export const setWindowTitle = (name, version) => {
  const shortVersion = version && version.substring(0, version.indexOf('-'));
  return `${name} ${shortVersion || version}`;
};

export const copyStringToClipboard = string => {
  const textarea = document.createElement('textarea');
  textarea.style.opacity = '0';
  textarea.style.position = 'absolute';
  textarea.style.top = '0';
  textarea.style.margin = '0';
  textarea.style.height = '0';
  textarea.style.overflow = 'hidden';
  textarea.style.pointerEvents = 'none';
  textarea.textContent = string;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

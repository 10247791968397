import { HEARTBEAT_SET } from '../constants/heartbeat.actionTypes';
import { heartbeatStatus } from '../../api';
import { parseISOTime } from '../../utils';
import { getSessionData } from '../../../features/auth/redux/actions/session.actions';

let heartbeatTimeout = null;
export const startHeartbeatTimer = () => dispatch => {
   heartbeatTimeout = setTimeout(() => dispatch(sendHeartbeat()), 30000)
};

export const cancelHeartbeatTimer = () => {
  heartbeatTimeout && clearTimeout(heartbeatTimeout);
  heartbeatTimeout = null;
};

export const resetHeartbeatTimer = () => dispatch => {
  cancelHeartbeatTimer();
  dispatch(startHeartbeatTimer())
};

export const sendHeartbeat = () => async (dispatch, getState) => {
  const { isAuthenticated } = getState().session || {};
  const robots = getState().robots;
  const hasRobot = robots.length;
  if (!isAuthenticated) {
    cancelHeartbeatTimer();
  }
  if(hasRobot){
    const isRobotOffline = await dispatch(getHeartbeatStatus(robots[0].id));
    dispatch(resetHeartbeatTimer());
    return Promise.resolve(isRobotOffline);
  }
  else {
    resetHeartbeatTimer();
  }
};

export const getHeartbeatStatus = robotId => async dispatch => {
  try {
    const heartbeatStatusResponse = await heartbeatStatus(robotId);
    let { status, lastSince } = heartbeatStatusResponse.data;

    const isRobotOffline = ['offline', 'error', 'noConnection'].includes(status);
    const payload = {
      status,
      isRobotOffline,
      duration: status === 'offline' ? parseISOTime(lastSince) : null
    };

    dispatch({ type: HEARTBEAT_SET, payload });

    return Promise.resolve(isRobotOffline);

  }
  catch (e) {
    const isRobotOffline = true;
    const noRobot = e?.response?.status === 403;
    const payload = {
      status: window.navigator.onLine ? 'error' : 'noConnection',
      isRobotOffline,
      duration: null,
      noRobot: noRobot
    };
    if(noRobot) {
      dispatch(getSessionData())
    }
    dispatch({ type: HEARTBEAT_SET, payload });

    return Promise.resolve(isRobotOffline);
  }
};

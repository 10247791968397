/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy } from 'react';
import { connect } from 'react-redux';
import { clearSignUp } from './redux/actions';
import { Route, Redirect, Switch } from 'react-router-dom';
const SignUpName = lazy(() => import('./SignUpName/SignUpName'));
const SignUpContacts = lazy(() => import('./SignUpContacts/SignUpContacts'));
const SignUpPassword = lazy(() => import('./SignUpPassword/SignUpPassword'));

const SignUp = props => {
	const inviteCode= props.match?.params?.inviteCode
	const email= props.match?.params?.email

	useEffect(() => {
		return () => props.clearSignUp();
	}, []);
	
	if(inviteCode && email) {
		return (
			<Switch>
				<Route exact path="/signup/invite/:inviteCode/:email/name" component={SignUpName} />
				<Route exact path="/signup/invite/:inviteCode/:email/contacts" component={SignUpContacts} />
				<Route exact path="/signup/invite/:inviteCode/:email/password" component={SignUpPassword} />
				<Redirect from={`/signup/invite/${inviteCode}/${email}`} to={`/signup/invite/${inviteCode}/${email}/name/`} />
			</Switch>
		)
	}
	return (
		<Switch>
			<Route exact path="/signup/name" component={SignUpName} />
			<Route exact path="/signup/contacts" component={SignUpContacts} />
			<Route exact path="/signup/password" component={SignUpPassword} />
			<Redirect from="/signup" to="/signup/name" />
		</Switch>
	)
};

const mapDispatchToProp = {
	clearSignUp
};

SignUp.propTypes = {};

export default connect(null, mapDispatchToProp)(SignUp);

import React from 'react';
import App from './App.jsx';
import { Provider, RootStateOrAny } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';
import styledComponentsTheme from './common/constants/styledComponentsTheme/styledComponentsTheme';
import overrideMomentLocaleNl from './localization/moment-locale-override/nl';
import { initApi } from './common/api/initApi';
import { initStore } from './store';
import moment from 'moment-timezone';
import { createTheme, MuiThemeProvider, ThemeOptions } from '@material-ui/core';
import { baseTheme } from './theme';

const store = initStore() as RootStateOrAny;

export const materialTheme = createTheme(baseTheme);

registerLocale('en', en);
registerLocale('nl', nl);

initApi(store);

overrideMomentLocaleNl();

const history = createBrowserHistory();
if (history.location.hash) {
  history.replace(`/${history.location.hash.replace('#/', '')}`);
}

const Root = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={materialTheme}>
      <ThemeProvider theme={styledComponentsTheme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>
);

export default Root;


/**
 * @file API functions for communicating with permissions microservice
 * @author Max van Loosbroek
 */

import axios, { AxiosResponse } from 'axios'
import { getUrls } from '../../../common/constants'
import { Permission, PermissionId } from '../redux/types'
import { ScriptVersion } from '../../mScripts/tessaScript/types/ScriptVersion'

export const getPermissions = async (robotId: number): Promise<AxiosResponse<Permission[]>> => {
  const url = getUrls('permissions', 'permissions').replace(
    '{robotId}',
    robotId
  )
  const response = await axios.get(url)
  return response
}

export const getAllPermissions = async (): Promise<AxiosResponse<{ permissions: PermissionId[] }>> => {
  const url = getUrls('permissions', 'all-permissions')
  const response = await axios.get<{ permissions: PermissionId[] }>(url)
  return response
}

export const putPermissions = async ({
  robotId,
  permissionIds
}: {
  robotId: number
  permissionIds: PermissionId[]
}): Promise<AxiosResponse<Permission[]>> => {
  const url = getUrls('permissions', 'permissions').replace(
    '{robotId}',
    robotId
  )
  const data = { permissions: permissionIds }
  const response = await axios.put(url, data)
  return response
}

export const deletePermission = async ({
  permissionId,
  robotId
}: {
  robotId: number
  permissionId: PermissionId
}): Promise<void> => {
  const url = getUrls('permissions', 'permission').replace(
    '{robotId}',
    robotId
  ).replace(
    '{permissionId}',
    permissionId
  )
  await axios.delete(url)
}


export const getMCQScripts = async (robotId: number): Promise<AxiosResponse<ScriptVersion[]>> => {
  const url = getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId)
  const response = await axios.get(url, {
    params: {
      scheduled: true,
      stepTypes: ['multipleChoiceOnline']
    }
  })
  return response
}

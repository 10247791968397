import axios from 'axios';
import { getUrls } from '../../../common/constants';
import moment from 'moment';

export const fetchOverview = (date1, date2, robotId, timeZone) => {
	const url = getUrls('eve', 'getscheduleswithdates')
		.replace('{robotId}', robotId)
		.replace('{fromDate}', moment(date1).format('YYYY-MM-DD'))
		.replace('{toDate}', moment(date2).format('YYYY-MM-DD'));

	return axios({
		method : 'GET',
		url,
		'headers': {
			"Content-Type": "application/json;charset=utf-8",
			"x-time-zone": timeZone
		}
	});
};

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const NotAuthenticatedRoute = ({ match, component: Component, isAuthenticated, eulaExpired, ...routeParams }) => {
	const inviteCode = routeParams?.computedMatch?.params?.inviteCode;
	const email = routeParams?.computedMatch?.params?.email;
	const to = inviteCode ? "/invite/" + inviteCode + '/' + email : "/overview"
	return (
		<Route
			{...routeParams}
			render={props => isAuthenticated ? <Redirect to={to} /> : <Component {...props}/>}
		/>
	)
};

const mapStateToProps = ({ session }) => ({
	isAuthenticated: session.isAuthenticated,
	eulaExpired: session.eulaExpired
});

NotAuthenticatedRoute.propTypes = {};

export default connect(mapStateToProps)(NotAuthenticatedRoute);

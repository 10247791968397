/**
 * @file Http queries for app notifications
 * @author Max van Loosbroek
 */

import axios from 'axios';
import { getUrls } from '../../../common/constants';
import { AppNotification } from '../types';

export const getNotifications = () => {
    const url = getUrls('hue', 'notifications')
    return axios.get<AppNotification[]>(url)
};

export const markNotificationAsSeen = (notificationUuid: string) => {
    const url = getUrls('hue', 'notification').replace('{notificationUuid}', notificationUuid)
    return axios.post(url)
};
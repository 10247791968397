import { createSelector, EntityId } from '@reduxjs/toolkit'
import { ScriptStoreNext, NextGoTo, NextMultipleChoiceCommand } from '../types/Next'
import {
  ScriptFeedback,
  ExecutionStep,
  AskFeedbackStep,
  SayFeedbackStep,
  WaitFeedbackStep,
  MultipleChoiceExecutionData
} from '../types/ScriptExecution'
import { ScriptNodeReport, ScriptStoreNode } from '../types/ScriptNode'
import { ScriptStepDTO, ScriptStepWithPosition } from '../types/ScriptStep'
import { ScriptVersion } from '../types/ScriptVersion'
import { validateScriptNodes, validateScriptNode, validateScriptNext, validateScriptNextSingle } from '../validation'
import { ScriptState } from './ScriptState'
import { formFieldMapper } from './formFieldMapper'

const scriptVersionSelector = (state: ScriptState) => state.script

export const scriptReferenceIdSelector = createSelector(scriptVersionSelector, script => {
  return script.scriptReferenceId
})
export const nodesSelector = (state: ScriptState) =>
  state.scriptNodes.ids.map(id => state.scriptNodes.entities[id] as ScriptStoreNode)
const nextEntitiesSelector = (state: ScriptState) => state.scriptNext.entities
const nextIdsSelector = (state: ScriptState) => state.scriptNext.ids
export const nextSelector = createSelector(nextEntitiesSelector, nextIdsSelector, (next, ids) => {
  return ids.map(id => next[id] as ScriptStoreNext)
})
const stepEntitiesSelector = (state: ScriptState) => state.scriptSteps.entities
export const stepIdsSelector = (state: ScriptState) => state.scriptSteps.ids
export const nodeSingleSelector = (scriptStepTempId: string) =>
  createSelector(nodesSelector, nodes => {
    const node = nodes.find(n => n.scriptStepTempId === scriptStepTempId)
    const formFields = (node as ScriptNodeReport).formFields
    if (formFields && formFields.length) {
      return { ...node, formFields: formFieldMapper(formFields) } as ScriptStoreNode
    } else {
      return node
    }
  })
export const stepsSelectorSimple = createSelector(
  stepEntitiesSelector,
  stepIdsSelector,
  nextSelector,
  (steps, ids, next) => {
    return ids.map(id => steps[id] as ScriptStepDTO).filter((n): n is ScriptStepDTO => Boolean(n))
  }
)
export const stepsSelector = createSelector(stepsSelectorSimple, nextSelector, (steps, next) => {
  return steps.map(s => {
    const nextSteps = next.filter(next => next.scriptStepTempId === s.tempId)
    return { ...s, nextSteps } as ScriptStepWithPosition
  })
})
export const primaryCommandsSelector = (scriptStepTempId?: EntityId) =>
  createSelector(nextSelector, next => {
    return next
      .filter(nxt => nxt.scriptStepTempId === scriptStepTempId)
      .map(nxt => (nxt as NextMultipleChoiceCommand)?.primaryCommand)
      .filter(command => command && command.length > 0)
  })
export const emptySelector = createSelector(stepIdsSelector, ids => ids.length < 1)
export const startStepSelector = createSelector(stepsSelector, steps => {
  if (steps) {
    return steps.find(s => s.first)
  }
})
export const stepSingleSelector = (scriptStepTempId: string) =>
  createSelector(stepEntitiesSelector, nextSelector, (steps, next) => {
    const s = steps[scriptStepTempId]
    if (!s) return
    const nextSteps = next.filter(next => next.scriptStepTempId === s?.tempId)
    return { ...s, nextSteps } as ScriptStepWithPosition
  })
export const childrenSelector = (stepId: string) =>
  createSelector(nextSelector, (scriptNext: ScriptStoreNext[]) => {
    let stepTempIds: string[] = []
    const next: ScriptStoreNext[] = JSON.parse(JSON.stringify(scriptNext))
    const recursive = (stepTempId: string) => {
      const matchingNext = next.filter(
        nxt => nxt.scriptStepTempId === stepTempId && (nxt as NextGoTo).maxReached !== false
      )
      matchingNext.forEach(nxt => {
        if (nxt.nextStepTempId) {
          stepTempIds = [...stepTempIds, ...[nxt.nextStepTempId]]
          recursive(nxt.nextStepTempId)
        }
      })
    }
    recursive(stepId)
    return stepTempIds
  })

export const getChildrenNext = (stepId: string) =>
  createSelector(nextSelector, childrenSelector(stepId), (next, childrenStepIds) => {
    return childrenStepIds.reduce((accum, childStepTempId) => {
      const matchingNext = next.filter(({ scriptStepTempId }) => scriptStepTempId === childStepTempId)
      return [...accum, ...matchingNext]
    }, [] as ScriptStoreNext[])
  })

export const allDescendantsValidSelector = (stepId: string, capabilities: string[]) =>
  createSelector(
    childrenSelector(stepId),
    nodesSelector,
    getChildrenNext(stepId),
    (children: string[], nodes: ScriptStoreNode[], childrenNext: ScriptStoreNext[]) => {
      const childrenNodes = children
        .map(tempId => nodes.find(n => n.scriptStepTempId === tempId))
        .filter((n): n is ScriptStoreNode => Boolean(n))
      try {
        validateScriptNodes(childrenNodes, capabilities)
        validateScriptNext(childrenNext)
        return true
      } catch (e) {
        return false
      }
    }
  )
const nextGoToValid = (next: ScriptStoreNext[], stepTempId: string) => {
  const invalidNext = next.find(
    nxt => (nxt as NextGoTo).maxReached === false && nxt.scriptStepTempId === stepTempId && !nxt.nextStepTempId
  )
  return !invalidNext
}
export const validSelector = (capabilities: string[]) => createSelector(stepsSelector, nodesSelector, nextSelector, (steps, nodes, next) => {
  const nodesSorted: ScriptStoreNode[] = steps
    .sort((a, b) => {
      if (!a || !b || !a.treePosition || !b.treePosition) {
        return 0
      } else if (a.treePosition > b.treePosition) {
        return 1
      } else {
        return -1
      }
    })
    .map(s => {
      return nodes.find(n => n.scriptStepTempId === s.tempId)
    })
    .filter((n): n is ScriptStoreNode => Boolean(n))
  if (!nodesSorted || !nodesSorted.length) {
    return { valid: false }
  }
  const invalidNext = next.find(nxt => {
    try {
      validateScriptNextSingle(nxt)
      return false
    } catch (error) {
      return true
    }
  })
  if (invalidNext) {
    return { valid: false, tempId: invalidNext.scriptStepTempId }
  }
  const nodesValid = nodesSorted.reduce(
    (accum, node) => {
      if (accum.valid === false) {
        return accum
      }
      try {
        validateScriptNode(node, capabilities)
        if (node.nodeType === 'goto') {
          // all goto nexts (maxReached === false) should have a next id
          if (!nextGoToValid(next, node.scriptStepTempId!)) {
            return { valid: false, tempId: node.scriptStepTempId }
          }
        }
        return accum
      } catch (e) {
        return { valid: false, tempId: node.scriptStepTempId }
      }
    },
    { valid: true }
  )
  return nodesValid
})
export const isFirstInvalidSelector = (stepTempId: string, capabilities: string[]) =>
  createSelector(validSelector(capabilities), valid => valid && !valid.valid && (valid as any).tempId === stepTempId)
export const loadedFeedbackSelector = (state: ScriptState) => state.feedback
export const feedbackSelector = createSelector(
  nodesSelector,
  startStepSelector,
  loadedFeedbackSelector,
  (nodes, startStep, feedback): ScriptFeedback => {
    if (feedback) return feedback
    if (!startStep) {
      return { scriptFeedbackArray: [] }
    }
    const firstNode = nodes.find(n => n.scriptStepId === startStep?.id)
    const type =
      startStep?.stepType === 'closedQuestion' || startStep?.stepType === 'multipleChoice' ? 'ask' : startStep?.stepType
    const { text, seconds, notify, greet, sayTime } = firstNode as any
    return {
      scriptFeedbackArray: [
        {
          type,
          text,
          seconds,
          notify,
          greet,
          sayTime
        } as any
      ]
    } as ScriptFeedback
  }
)

export const getFeedbackSteps = (exSteps: ExecutionStep[]) =>
  createSelector(nodesSelector, nextSelector, nodes => {
    const feedbackSteps: (AskFeedbackStep | SayFeedbackStep | WaitFeedbackStep)[] = exSteps
      .map(exStep => {
        const n = nodes.find(n => n.scriptStepId === exStep.scriptStepId) as any
        const type = exStep.stepType === 'closedQuestion' ? 'ask' : exStep.stepType
        const intentionType = exStep.data && (exStep.data as MultipleChoiceExecutionData).intentionType
        const answer = exStep.data && (exStep.data as any).answer
        const sent = exStep.data && (exStep.data as any).sent
        if (n) {
          const { text, seconds, notify, greet, sayTime } = n
          return {
            intentionType,
            answer,
            type,
            text,
            seconds,
            notify,
            greet,
            sayTime,
            executionTime: exStep.executedAt,
            sent
          } as any
        }
        return undefined
      })
      .filter(s => Boolean(s))
      .sort((a, b) => new Date(a.executionTime).getTime() - new Date(b.executionTime).getTime())
    return feedbackSteps
  })
const versionEntitiesSelector = (state: ScriptState) => state.scriptVersions.entities
const versionIdsSelector = (state: ScriptState) => state.scriptVersions.ids
const versionsSelector = createSelector(versionIdsSelector, versionEntitiesSelector, (ids, versions) => {
  return ids.map(id => versions[id]).filter((v): v is ScriptVersion & { id: number } => Boolean(v))
})
export const namesSelector = createSelector(versionsSelector, versions => {
  return versions.map(v => v.scriptName)
})

import {
  SET_USER,
  ENABLE_MFA,
  ENABLE_MFA_ERROR,
  ENABLE_MFA_SUCCESS,
  DISABLE_MFA,
  DISABLE_MFA_SUCCESS,
  DISABLE_MFA_ERROR,
  USER_CLEAR_ERROR,
  RESET_ROBOT,
  RESET_ROBOT_SUCCESS,
  RESET_ROBOT_ERROR,
  RESET_ROBOT_STATUS,
  RESET_ROBOT_SUCCESS_STATUS,
  RESET_ROBOT_ERROR_STATUS
} from '../constants/actionTypes'
import { enableMFA, disableMFA, resetRobot, changeEmail } from '../../api'
import { getResetStatus } from '../../api/index'
import { UserStateUser } from '../reducers/types'
import { AnyAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'

export const setUser = (user: UserStateUser) => ({ type: SET_USER, user })
export const startEnableMfa = () => ({ type: ENABLE_MFA })
export const enableMfaSuccess = () => ({ type: ENABLE_MFA_SUCCESS })
export const enableMfaError = (error: any) => ({ type: ENABLE_MFA_ERROR, error })
export const startResetRobot = () => ({ type: RESET_ROBOT })
export const resetRobotSucces = () => ({ type: RESET_ROBOT_SUCCESS })
export const resetRobotError = (error: any) => ({ type: RESET_ROBOT_ERROR, error })
export const startResetRobotStatus = () => ({ type: RESET_ROBOT_STATUS })
export const resetRobotStatusSucces = (payload: { status: string }) => ({
  type: RESET_ROBOT_SUCCESS_STATUS,
  payload
})
export const resetRobotStatusError = (error: any) => ({
  type: RESET_ROBOT_ERROR_STATUS,
  error
})
export const startDisableMFA = () => ({ type: DISABLE_MFA })
export const disableMfaSuccess = () => ({ type: DISABLE_MFA_SUCCESS })
export const disableMfaError = (error: any) => ({ type: DISABLE_MFA_ERROR, error })
export const clearUserError = () => ({ type: USER_CLEAR_ERROR })

export const enableMfaUser =
  ({ password, otpCode }: { password: string, otpCode: string }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      dispatch(startEnableMfa())
      try {
        await enableMFA({ password, otpCode })
        dispatch(enableMfaSuccess())
      } catch (e) {
        dispatch(
          enableMfaError({ ...e?.response?.data, status: e?.response?.status })
        )
      }
    }

export const disableMfaUser =
  ({ password, otpCode }: { password: string, otpCode: string }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      dispatch(startDisableMFA())
      try {
        await disableMFA({ password, otpCode })
        dispatch(disableMfaSuccess())
      } catch (e) {
        dispatch(
          disableMfaError({ ...e?.response?.data, status: e?.response?.status })
        )
      }
    }

export const resetRobotUser =
  ({ password, otpCode, robotId }: { password: string, otpCode: string, robotId: number }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      dispatch(startResetRobot())
      try {
        await resetRobot({ password, otpCode, robotId })
        dispatch(resetRobotSucces())
      } catch (e) {
        dispatch(
          resetRobotError({ ...e?.response?.data, status: e?.response?.status })
        )
      }
    }

export const getResetRobotStatusUser =
  ({ robotId }: { robotId: number }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      dispatch(startResetRobotStatus())
      try {
        const res = await getResetStatus(robotId)
        if (res?.data.status === 'failed' || res?.data.status === 'pending') {
          dispatch(resetRobotStatusSucces(res?.data))
        } else {
          throw new Error('Ignore invalid status')
        }
      } catch (e) {
        dispatch(
          resetRobotStatusError({
            ...e?.response?.data,
            status: e?.response?.status
          })
        )
      }
    }

export const requestEmailChange = createAsyncThunk(
  'user/requestEmailChange',
  async (body: {
    password: string,
    otpCode?: string,
    newEmail: string
  }, { rejectWithValue }) => {
    try {
      const res = await changeEmail(body)
      return res.data
    } catch (err) {
      return rejectWithValue({...(err as any).response.data, status: (err as any).response.status})
    }
  }
)

export const verifyEmailChange = createAsyncThunk(
  'user/verifyEmailChange',
  async (body: {
    password: string,
    otpCode?: string,
    newEmail: string,
    verificationKey: string
  }, { rejectWithValue }) => {
    try {
      const res = await changeEmail(body)
      return body.newEmail
    } catch (err) {
      return rejectWithValue({...(err as any).response.data, status: (err as any).response.status})
    }
  }
)

/**
 * @file Functions to remove script steps from state
 */

import { EntityAdapter } from '@reduxjs/toolkit'
import { ScriptState } from '../redux/ScriptState'
import { nodesSelector, nextSelector } from '../redux/selectors'
import { NextGoTo, ScriptStoreNext } from '../types/Next'
import { ScriptStoreNode } from '../types/ScriptNode'
import { ScriptStoreStep } from '../types/ScriptStep'
import { assignTreePositions } from './assignTreePositions'
import { getStepsToRemove } from './helpers'

export const removeScriptStep = ({
  scriptStepsAdapter,
  scriptNodesAdapter,
  scriptNextAdapter,
  state,
  payload,
  removeBranch
}: {
  scriptStepsAdapter: EntityAdapter<ScriptStoreStep>
  scriptNodesAdapter: EntityAdapter<ScriptStoreNode>
  scriptNextAdapter: EntityAdapter<ScriptStoreNext>
  payload: string
  state: ScriptState
  removeBranch?: boolean
}) => {
  const tempId = payload
  const step = state.scriptSteps.entities[payload]
  const scriptNodes = nodesSelector(state)
  const scriptNext = nextSelector(state)
  const matchingNodes = scriptNodes
    .filter(n => n.scriptStepTempId === tempId)
    .map(node => node.tempId!)
  const matchingNext = scriptNext.filter(
    nxt =>
      nxt.scriptStepTempId === tempId && (nxt as NextGoTo).maxReached !== false
  )
  const parentNextId = scriptNext.find(
    nxt =>
      nxt.nextStepTempId === tempId && (nxt as NextGoTo).maxReached !== false
  )?.tempId!
  // no children, remove step and node
  if (!matchingNext.length) {
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: parentNextId,
      changes: { nextStepTempId: undefined }
    })
    scriptStepsAdapter.removeOne(state.scriptSteps, tempId)
    scriptNodesAdapter.removeMany(state.scriptNodes, matchingNodes)
  }
  // multiple children, remove step, node and all descendants
  if (matchingNext.length > 1 || removeBranch) {
    const { stepTempIds, nodeTempIds, nextTempIds } = getStepsToRemove(
      scriptNodes,
      scriptNext,
      tempId
    )
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: parentNextId,
      changes: { nextStepTempId: undefined }
    })
    scriptStepsAdapter.removeMany(state.scriptSteps, stepTempIds)
    scriptNodesAdapter.removeMany(state.scriptNodes, nodeTempIds)
    scriptNextAdapter.removeMany(state.scriptNext, nextTempIds)
  }
  // first step, one child, child becomes first node
  if (matchingNext.length === 1 && step?.first) {
    const childId = matchingNext[0].nextStepTempId!
    scriptStepsAdapter.updateOne(state.scriptSteps, {
      id: childId,
      changes: { first: true }
    })
    scriptStepsAdapter.removeOne(state.scriptSteps, tempId)
    scriptNodesAdapter.removeMany(state.scriptNodes, matchingNodes)
    scriptNextAdapter.removeMany(
      state.scriptNext,
      matchingNext.map(nxt => nxt.tempId)
    )
  }
  // one child, child becomes child of parent of removed node
  if (matchingNext.length === 1 && !step?.first) {
    const childId = matchingNext[0].nextStepTempId!
    scriptNextAdapter.updateOne(state.scriptNext, {
      id: parentNextId,
      changes: { nextStepTempId: childId }
    })
    scriptStepsAdapter.removeOne(state.scriptSteps, tempId)
    scriptNodesAdapter.removeMany(state.scriptNodes, matchingNodes)
    scriptNextAdapter.removeMany(
      state.scriptNext,
      matchingNext.map(nxt => nxt.tempId)
    )
  }
  // always remove dangling goto references
  const danglingGoTos = scriptNext.filter(nxt => {
    const isGoTo = (nxt as NextGoTo).maxReached === false
    if (!isGoTo) {
      return false
    } else {
      const nextStep =
        nxt.nextStepTempId &&
        scriptStepsAdapter
          .getSelectors()
          .selectById(state.scriptSteps, nxt.nextStepTempId)
      const stepRemoved = nextStep === undefined
      return stepRemoved
    }
  })
  if (danglingGoTos.length) {
    scriptNextAdapter.updateMany(
      state.scriptNext,
      danglingGoTos.map(nxt => ({
        id: nxt.tempId,
        changes: {
          nextStepTempId: undefined
        }
      }))
    )
  }
  const treePositions = assignTreePositions(state)
  scriptStepsAdapter.updateMany(state.scriptSteps, treePositions)
  state.stateHasChanged = true
}

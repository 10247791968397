/**
 * @file Helper for checking if there are interfering similar commands
 * @author Max van Loosbroek
 */

import { CommandEntity } from './reducer'

export default ({
  interactionId,
  currentCommand: { index },
  interactions
}: {
  interactionId?: number
  currentCommand: CommandEntity
  interactions: {
    id: number
    title: string
    commands: string[]
  }[]
}) => {
  // Ignore matches in current interaction
  const filteredInteractions = interactions.filter(i => i.id !== interactionId)
  // No matches
  if (!filteredInteractions.length) {
    return {
      similarCommands: undefined,
      index
    }
  } else {
    return {
      similarCommands: filteredInteractions,
      index
    }
  }
}

import { newTask, deleteTask } from '../../api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { convertScripts, fetchScriptsDeprecated, addScriptDeprecated } from '../../../mScripts/api'
import { crudPlaylist } from '../../../music/api'
import { DEFAULT_TIMEZONE } from '../../../../common/constants'
import { History } from 'history'
import { setUniqueScriptName, convertReminderOrQuestion } from '../../../mScripts/utils'
import { TessaScriptDenormalized } from '../../../mScripts/tessaScript/types/DeprecatedScript'
import { sendHeartbeat } from '../../../../common/redux/actions/heartbeat.actions'
import { editScheduleDate } from '../../utils'
import { Schedule } from '../types/Schedule'
import { EditTask } from '../../TaskRecurring/types'
import moment from 'moment'
import { taskSetScriptDeprecated, taskEdit, taskSetSchedule } from '../actions/actions'
import { fetchOverview } from '../../../overview/api'

export const taskDelete = createAsyncThunk(
  'task/taskDelete',
  async ({ robotId, data }: { robotId: number; data: any }) => {
    await deleteTask(data, robotId)
  }
)

export const convertTaskScriptV2 = createAsyncThunk(
  'task/convertTaskScriptV2',
  async ({
    taskId,
    robotId,
    history,
    time,
    capabilities
  }: {
    taskId: number | string
    robotId: number | string
    history: History
    time: string
    capabilities: string[]
  }) => {
    const res = await convertScripts(robotId, capabilities)
    if (res === undefined) {
      throw Error('Not converting scripts for outdated robot')
    }
    const converted = res?.data
    let newTaskId: number | undefined
    let scriptReferenceId: number | undefined
    converted.some(c => {
      return c.tasks?.some(t => {
        if (t.oldTaskId?.toString() === taskId.toString()) {
          scriptReferenceId = c?.scriptReferenceId
          newTaskId = t?.newTaskId
          return true
        }
      })
    })
    if (newTaskId) {
      const onSuccess = () => history.push(`/task/script/v2/${scriptReferenceId}/${newTaskId}/${time}`)
      return { taskId: newTaskId, onSuccess }
    } else {
      throw Error('no script found')
    }
  }
)

export const getPlaylist = createAsyncThunk(
  'task/getPlaylist',
  async ({
    history,
    robotId,
    playlistId,
    title
  }: {
    history: History
    robotId: number
    playlistId: string
    title: string
  }): Promise<{ id: number; name: string; length: number } | void> => {
    if (playlistId && playlistId !== '0') {
      try {
        const response = await crudPlaylist(robotId, playlistId)
        if (response.data) {
          const { id, name, totalDuration } = response.data
          return { id, name, length: Math.floor(totalDuration / 60000) || 0 }
        } else {
          history.replace('/music/selectPlaylist')
        }
      } catch (e) {
        history.replace('/music/selectPlaylist')
      }
    } else {
      return { id: 0, name: title, length: 0 }
    }
  }
)

export const taskSave = createAsyncThunk(
  'task/taskSave',
  async ({
    robotId,
    type,
    schedule,
    title,
    taskCategory,
    taskId,
    time,
    priority,
    timeZone,
    recurringV2
  }: {
    robotId: number
    type: 'scriptV2' | string
    schedule: Schedule
    title: string
    taskCategory: { category: string }
    taskId: number | string
    time?: string | null
    priority?: number
    timeZone?: string
    recurringV2: boolean
  }): Promise<string> => {
    const recurrenceType = recurringV2 && schedule.recurrenceType ? schedule.recurrenceType : 'CRON'
    const data = {
      schedule: {
        ...schedule,
        timeZone,
        recurrenceType,
        recurrenceInterval: recurringV2 && schedule.recurrenceInterval && recurrenceType !== 'CRON' ? schedule.recurrenceInterval : undefined,
        dayOfWeekIndex: schedule.dayOfWeekIndex ?? ['*']
      },
      title,
      type,
      id: taskId,
      [type]: taskCategory,
      time
    }
    if (type !== 'music') {
      data.priority = priority
    }
    try {
      await newTask(data, robotId, timeZone ?? DEFAULT_TIMEZONE)
    } catch (error) {
      const permissionError =
        (error as any).response.status === 403 && (error as any).response.data.message.includes('permissions')
      if (permissionError) {
        throw new Error('Missing permissions')
      } else if ((error as any).response.status === 400) {
        throw new Error('Invalid schedule')
      } else {
        throw new Error('Unknown error')
      }
    }
    return schedule.startTime
  }
)

export const convertLegacyToScript = createAsyncThunk(
  'task/convertLegacyToScript',
  async (
    {
      robotId,
      title,
      legacyTaskType,
      legacyTaskData
    }: {
      robotId: number
      title: string
      legacyTaskType: string
      legacyTaskData: any
    },
    { dispatch }
  ) => {
    try {
      const scriptsListResponse = await fetchScriptsDeprecated(robotId)
      const name = setUniqueScriptName(
        scriptsListResponse.map(s => s.name),
        title
      )
      const data: TessaScriptDenormalized = convertReminderOrQuestion(legacyTaskType)(legacyTaskData, name)
      const setScriptResponse = await addScriptDeprecated(robotId, data)
      dispatch(taskSetScriptDeprecated(setScriptResponse.data.script))
      return Promise.resolve(setScriptResponse.data.script.id)
    } catch (e) {}
  }
)

export const checkRobotStatus = createAsyncThunk('task/checkRobotStatus', async (_, { dispatch }) => {
  const isRobotOffline = await dispatch(sendHeartbeat())
  return isRobotOffline
})

export const fetchTask = createAsyncThunk('task/fetchTask', async ({ timeZone, robotId, time, taskId, editSequence }: { timeZone?: string, robotId: number, time: Date | string, taskId: number | string, editSequence: boolean }, { dispatch }) => {
  const taskDate = moment(time)
  const date1 = taskDate.subtract(1, 'second')
  const date2 = taskDate.add(1, 'second')
  const response = await fetchOverview(date1, date2, robotId, timeZone)
  const editTask = response.data.find(({ id }: { id: number }) => {
    return id.toString() === taskId
  })
  dispatch(setEditTask({
    editTask,
    editSequence,
    shouldUpdateDeletedPlaylist: false
  }))
})

export const setEditTask = createAsyncThunk(
  'task/setEditTask',
  (
    {
      editTask,
      editSequence,
      shouldUpdateDeletedPlaylist
    }: {
      editTask: EditTask
      editSequence?: boolean
      shouldUpdateDeletedPlaylist?: boolean
    },
    { dispatch }
  ) => {
    const {
      reminder,
      question,
      id,
      title,
      time,
      priority,
      schedule: { recurring, startTime, endTime, dayOfWeek, ...rest }
    } = editTask

    dispatch(
      taskEdit({
        taskId: id,
        time: moment(time).format(),
        editSequence,
        title,
        shouldUpdateDeletedPlaylist,
        reminder,
        question,
        priority
      })
    )
    
    dispatch(
      taskSetSchedule({
        pickedDate: moment(time).format(),
        schedule: {
          ...rest,
          recurrenceType: editSequence ? rest.recurrenceType : null,
          recurrenceInterval: editSequence ? rest.recurrenceInterval : null,
          dayOfMonth: editSequence ? rest.dayOfMonth : [moment(time).date().toString()],
          startTime: moment(time).startOf('day').format(),
          endTime: editSequence ? (endTime ? moment(endTime).format() : null) : moment(time).endOf('day').format(),
          dayOfWeek: editSequence ? dayOfWeek : ['*']
        }
      })
    )
  }
)

export const editPickedDate = createAsyncThunk(
  'task/editPickedDate',
  async (
    {
      pickedDate,
      schedule
    }: {
      pickedDate: string | null
      schedule: Schedule | null
    },
    { dispatch }
  ) => {
    const newSchedule = editScheduleDate({ pickedDate, schedule }) as {
      pickedDate: string
      schedule: Schedule
    }
    dispatch(taskSetSchedule({ pickedDate: newSchedule.pickedDate, schedule: newSchedule.schedule }))
  }
)

import {
  SESSION_CREATE,
  SESSION_LOGOUT,
  SESSION_GET_DATA_START,
  SESSION_GET_DATA_FINISH,
  SESSION_ACTIVATE_ACCOUNT,
  SESSION_ENABLE_ACCOUNT_ACTIVATION,
  SESSION_CLEAR_ERROR,
  SET_EULA_EXPIRED
} from '../constants/actionTypes';
import { refreshToken, getUserSelf, getBots, getConfiguration, activate } from '../../api';
import { setUser } from './user.actions';
import { setRobots, setRobotConfig, setActiveRobot } from './robots.actions';
import { fetchSettings } from '../../../robotSettings/redux/reducer';
import moment from 'moment';
import { setProfile } from '../../../generalSettings/UserProfile/redux/reducer';

export const createSession = session => ({ type: SESSION_CREATE, session });

export const logout = (payload) => {
  localStorage.removeItem('Tinybots.session');
  const error = !!payload && !!payload.error
  const eulaExpired = payload?.eulaExpired
  return { type: SESSION_LOGOUT, payload: { error, eulaExpired } };
};

export const clearSessionError = () => ({ type: SESSION_CLEAR_ERROR });

export const startGetSessionData = () => ({ type: SESSION_GET_DATA_START });

export const finishGetSessionData = () => ({ type: SESSION_GET_DATA_FINISH });

export const activateAccount = () => ({ type: SESSION_ACTIVATE_ACCOUNT });

export const enableAccountActivation = () => ({ type: SESSION_ENABLE_ACCOUNT_ACTIVATION });

export const setEulaExpired = (payload) => ({ type: SET_EULA_EXPIRED, payload });

export const activateEmail = data => async dispatch => {
  dispatch(activateAccount());
  await activate(data);
  setTimeout(() => dispatch(enableAccountActivation()), 30000);
};

export const getSessionData = () => async dispatch => {
  dispatch(startGetSessionData());

  try {
    const user = await getUserSelf();
    // TODO remove get robots retry, temporary bugfix
    const timeoutPromise = new Promise(resolve => setTimeout(resolve, 3000))
    const robots = await getBots().catch(() => timeoutPromise.then(() => getBots()))
      .catch(() => timeoutPromise.then(() => getBots()))
      .catch(() => getBots())
    if (robots.data.length) {
      const savedRobotId = localStorage.getItem('activeRobot');
      const activeRobot = robots.data.find(r => r.id === parseInt(savedRobotId))?.id
      const robotConfig = await getConfiguration(activeRobot ?? robots.data[0].id);
		  dispatch(fetchSettings(activeRobot ?? robots.data[0].id))
      if(!activeRobot) {
        dispatch(setActiveRobot(robots.data[0].id))
      }
      dispatch(setRobotConfig(robotConfig.data));
    }

    const { status, id, username, mfaEnabledAt, firstName, lastName, phoneNumber, eulaVersionId, eulaUpToDate } = user.data;
    dispatch(setUser({ status, userId: id, email: username, mfaEnabledAt }));
    dispatch(setProfile({ firstName, lastName, phoneNumber: `+${phoneNumber}`, eulaVersionId, eulaUpToDate }))
    dispatch(setRobots(robots.data));
    dispatch(finishGetSessionData());
  } catch (e) {
    console.error('Issue getting session data: ', e);
    dispatch(logout({ error: true }));
  }
};

export const getNewTokenAsync = () => async (dispatch, getState) => {
  try {
    const { refresh_token } = getState().session;
    const refreshTokenResponse = await refreshToken({ refreshToken: refresh_token });
    const session = {
      ...refreshTokenResponse.data,
      token_creation_time: moment().format(),
      internal_date: moment().format()
    };
    localStorage.setItem('Tinybots.session', JSON.stringify(session));
    dispatch(createSession(session));

    const user = await getUserSelf();
    const { status, id, username, firstName, lastName, phoneNumber, eulaVersionId, eulaUpToDate } = user.data;
    dispatch(setUser({ status, userId: id, email: username }));
    dispatch(setProfile({ firstName, lastName, phoneNumber: `+${phoneNumber}`, eulaVersionId, eulaUpToDate }))

    const bots = await getBots();
    dispatch(setRobots(bots.data));

    return Promise.resolve(session.access_token);
  }
  catch (e) {
    if(e.response && e.response.data  && e.response.data.eulaVersionUrl) {
      console.error('Eula expired');
      dispatch(logout({ error: false, eulaExpired: e.response.data.eulaVersionUrl }));
    }
    return Promise.reject(e);
  }
};

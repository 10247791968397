import PropTypes from 'prop-types';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.type === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'transparent'};
  z-index: 2;
`;

Backdrop.propTypes = {
  type: PropTypes.string,
};

export default Backdrop;

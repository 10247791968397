import {
  PLAYLIST_SONGS_CLEAR,
  PLAYLIST_SONGS_SET,
  PLAYLIST_SONGS_SELECT,
  PLAYLIST_SONGS_SEARCH,
  PLAYLIST_SONGS_GET_ERROR,
  PLAYLIST_SONGS_EDIT_PLAYLIST_SET
} from '../constants/actionTypes';
import { checkEnabled, allItemsSelected, setUniqueName, selectSongs} from '../../../utils';

export const initialState = {
  songs: [],
  done: false,
  error: false,
  searchedSong: '',
  minutes: 0,
  songsSelected: 0,
  allSongsSelected: false,
  editPlaylist: null
};

const playlistSongsSelect = (state, action) => {
  const selectedSongs = selectSongs(state, action, true);
  const { songsSelected, minutes } = checkEnabled(selectedSongs);
  const allSongsSelected = action.payload
    ? allItemsSelected(selectedSongs, true)
    : !state.allSongsSelected;
  return {...state, songs: selectedSongs, songsSelected, minutes, allSongsSelected};
};

const playlistSongsSearch = (state, action) => {
  const foundSongs = state.songs.map(song => (
    {
      ...song,
      hide: action.payload ? !song.title.toLowerCase().includes(action.payload.toLowerCase()) : false
    }
  ));

  return {
    ...state,
    songs: foundSongs,
    allSongsSelected: allItemsSelected(foundSongs, true),
    searchedSong: action.payload
  };
};

const playlistSongsGetError = state => ({...state, error: true, done: true});

const playlistSongsClear = () => ({...initialState});

const playlistSongsSet = (state, action) => ({
  ...initialState,
  songs: setUniqueName(action.payload),
  done: true,
  error: false
});

const playlistSongsEditPlaylistSet = (state, action) => ({...state, editPlaylist: action.payload});

export const playlistSelectMusic = (state = initialState, action) => {
  switch (action.type) {
    case PLAYLIST_SONGS_GET_ERROR: return playlistSongsGetError(state);
    case PLAYLIST_SONGS_CLEAR: return playlistSongsClear();
    case PLAYLIST_SONGS_SET: return playlistSongsSet(state, action);
    case PLAYLIST_SONGS_SELECT: return playlistSongsSelect(state, action);
    case PLAYLIST_SONGS_SEARCH: return playlistSongsSearch(state, action);
    case PLAYLIST_SONGS_EDIT_PLAYLIST_SET: return playlistSongsEditPlaylistSet(state, action);
    default: return state;
  }
};

/**
 * @file Special popup for app notification feature, able to display multiple notifications
 * @author Max van Loosbroek
 */

import React, { useState } from 'react';
import { AppNotificationsState, AppNotification } from './types';
import { hideNotification, appNotificationsSelector } from './redux/reducer';
import PopUp from '../../common/components/PopUp/PopUp';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ButtonBase } from '@material-ui/core';
import { markSeen, markAllSeen } from './redux/reducer';
import { RootState } from '../../root.reducer';
import { UserState } from '../auth/redux/reducers/types';
import AppNotificationSlide from './AppNotificationSlide';
import { useEffect } from 'react';
import { PopUpLoading } from '../../common/components/PopUpLoading/PopUpLoading';

const usePopUpStyles = makeStyles((_theme: Theme) =>
    createStyles({
        slides: {
            height: '330px',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex'
        },
        arrowButtons: {
            position: 'absolute',
            top: 0,
            bottom: 'auto',
        },
        left: {
            left: -4
        },
        right: {
            right: -4
        },
        disabled: {
            opacity: 0.3,
            pointerEvents: 'none'
        }
    })
);

export interface AppNotificationPopUpProps {
    appNotifications: AppNotificationsState & { notifications: AppNotification[] },
    hideNotification: () => any,
    markSeen: (props: { userId: number; notificationUuid: string; }) => any,
    markAllSeen: (params: { userId: number }) => any,
    user: UserState
}

export const AppNotificationPopUpComponent = ({
    appNotifications,
    hideNotification,
    markSeen,
    markAllSeen,
    user
}: AppNotificationPopUpProps) => {
    const userId = user.userId
    const classes = usePopUpStyles()
    const { t } = useTranslation()
    const [activeIndex, setActiveIndex] = useState(0)
    const current = appNotifications?.notifications?.[activeIndex]
    const single = appNotifications?.notifications?.length === 1
    const slides = (appNotifications?.notifications).map((notification, i) => {
        return <AppNotificationSlide key={notification.uuid} single={single} notification={notification} active={activeIndex === i} />
    })
    const first = activeIndex === 0
    const last = activeIndex > slides.length - 2
    const previousClasses = [classes.arrowButtons, classes.left, first ? classes.disabled : ''].join(' ')
    const nextClasses = [classes.arrowButtons, classes.right, last ? classes.disabled : ''].join(' ')
    const loading = appNotifications?.patching;

    const onNext = () => {
        if (!last) {
            setActiveIndex(activeIndex + 1)
        }
    }

    const onPrevious = () => {
        if (!first) {
            setActiveIndex(activeIndex - 1)
        }
    }

    const onSubmit = () => {
        if (loading || !userId) return
        markSeen({ notificationUuid: current.uuid, userId })
    }

    const onCancel = () => {
        if (!userId) return
        markAllSeen({ userId })
    }

    const onClickAway = () => {
        hideNotification()
    }

    useEffect(() => {
        if (activeIndex > slides.length - 1) {
            setActiveIndex(slides.length - 1)
        }
    }, [activeIndex, slides])

    return (
        <PopUp
            type="notification"
            onCancel={onCancel}
            cancelButton={t('APP_NOTIFICATIONS.POPUP.CANCEL_BUTTON')}
            onSubmit={onSubmit}
            submitButton={t('APP_NOTIFICATIONS.POPUP.SUBMIT_BUTTON')}
            onClickAway={onClickAway}
        >
            <div className={classes.slides}>
                {!single &&
                    <>
                        <ButtonBase aria-label="previous button" onClick={onPrevious} className={previousClasses}>
                            <ArrowBackIcon style={{ fontSize: '24px' }} />
                        </ButtonBase>
                        <ButtonBase aria-label="next button" onClick={onNext} className={nextClasses}>
                            <ArrowForwardIcon style={{ fontSize: '24px' }} />
                        </ButtonBase>
                    </>
                }
                {slides}
            </div>
            <PopUpLoading loading={loading}></PopUpLoading>
        </PopUp>
    );
};

const mapDispatchToProps = {
    hideNotification,
    markSeen,
    markAllSeen
};

const mapStateToProps = ({ appNotifications, user }: RootState) => ({
    user,
    appNotifications: {
        ...appNotifications,
        notifications: appNotificationsSelector(appNotifications)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AppNotificationPopUpComponent);

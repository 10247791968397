import axios from "axios";
import { getUrls } from "../../../../common/constants";

import { RobotUser } from "../redux/types";

export const getRobotUsers = ({ robotId }: { robotId: number }) => {
    const url = getUrls('prowl', 'robot-users').replace('{robotId}', robotId);
    return axios.get<Omit<RobotUser, 'uuid'>[]>(url);
};

export const getInvites = ({ robotId }: { robotId: number }) => {
    const url = getUrls('checkpoint', 'invites').replace('{robotId}', robotId);
    return axios.get<Omit<RobotUser, 'uuid'>[]>(url);
};

// ONLY PRIMARY_CARER CAN POST THIS REQUEST
export const putInvite = ({ robotId, email, clientRelationRole }: { robotId: number, email: string, clientRelationRole: string }) => {
    const url = getUrls('checkpoint', 'invites').replace('{robotId}', robotId);
    return axios.put<Omit<RobotUser, 'uuid'>[]>(url, { email, clientRelationRole });
};

// ONLY PRIMARY_CARER CAN POST THIS REQUEST
export const deleteInvite = ({ robotId, inviteId }: { robotId: number, inviteId: number }) => {
    const url = getUrls('checkpoint', 'invite-single').replace('{robotId}', robotId).replace('{inviteId}', inviteId);
    return axios.delete<Omit<RobotUser, 'uuid'>[]>(url);
};

// ONLY PRIMARY_CARER CAN POST THIS REQUEST
export const deleteUserRobot = ({ robotId, userId }: { robotId: number, userId: number }) => {
    const url = getUrls('checkpoint', 'robot-user-single').replace('{robotId}', robotId).replace('{userId}', userId);
    return axios.delete(url);
};

// ONLY A REGULAR USER CAN POST THIS REQUEST
export const deleteUserRobotSelf = async ({ robotId }: { robotId: number }) => {
    const url = getUrls('checkpoint', 'robot-user-self').replace('{robotId}', robotId);
    const res = await axios.delete(url);
    return res;
};

// ONLY PRIMARY_CARER CAN POST THIS REQUEST
export const patchUser = ({ robotId, userId, password, otpCode, userRobot }: { robotId: number, userId: number, password: string, otpCode?: string, userRobot: { role: "PRIMARY_CARER" } }) => {
    const url = getUrls('checkpoint', 'robot-user-single').replace('{robotId}', robotId).replace('{userId}', userId);
    return axios.patch<Omit<RobotUser, 'uuid'>[]>(url, {
      auth: {
        password,
        otpCode
      },
      userRobot
    });
};

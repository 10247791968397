import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavArrow from '../NavArrow/NavArrow';

const Wrapper = styled.main`
	display: flex;
	justify-content: center;
	align-items: center;
	position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  @media only screen
  and (min-device-width: 340px)
  and (max-device-width: ${props => props.theme.variables.smallerWidthBreakpoint1})
  and (orientation: landscape) {
    align-items: initial;
    overflow-y: scroll;
  }

  @media only screen
  and (max-device-width: ${props => props.theme.variables.smallerWidthBreakpoint1})
  and (orientation: portrait){ overflow-y: hidden; }

  @media only screen and (max-device-width: 450px){ position: static; }
`;

const Inner = styled.div`
	position: relative;
  background-color: ${props => props.theme.colors.cremewhite};
  padding: 4rem;
  max-width: 45rem;
  width: 100%;
  margin: 0;
  min-height: 600px;
  max-height: 600px;
  border-radius: 5px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  
  @media (min-width: 0px) and (max-width: 450px) {
    min-height: 100vh;
    max-height: unset;
    height:auto;
    border-radius: 0;
    padding: 20px 40px;
  }

  &>div,h1,h3,p{
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Nav = styled.div`
	display: flex;
  align-items: center;
  height: 20px;
`;

const Content = ({children, navBack, hideNav, innerProps}) => (
	<Wrapper>
		<Inner {...innerProps}>
			{!hideNav && <Nav>
				{ navBack && <NavArrow onClick={navBack} /> }
			</Nav>}
			{ children }
		</Inner>
	</Wrapper>
);

Content.propTypes = {
  hideNav: PropTypes.bool,
	navBack: PropTypes.func,
  innerProps: PropTypes.object
};

Content.defaultProps = {
  hideNav: false,
  innerProps: {}
};

export default Content;

export const PLAYLISTS_SET = 'PLAYLISTS_SET';
export const PLAYLISTS_SELECT = 'PLAYLISTS_SELECT';
export const PLAYLISTS_SHOW_MENU = 'PLAYLIST_SHOW_MENU';
export const PLAYLISTS_HIDE_MENU = 'PLAYLIST_HIDE_MENU';
export const PLAYLISTS_SEARCH = 'PLAYLISTS_SEARCH';
export const PLAYLISTS_DELETE_ASK = 'PLAYLISTS_DELETE_ASK';
export const PLAYLISTS_DELETE_REJECT = 'PLAYLISTS_DELETE_REJECT';
export const PLAYLISTS_DELETE_CONFIRM = 'PLAYLISTS_DELETE_CONFIRM';
export const PLAYLISTS_DELETE_SUCCESS = 'PLAYLISTS_DELETE_SUCCESS';
export const PLAYLISTS_DELETE_ERROR = 'PLAYLISTS_DELETE_ERROR';
export const PLAYLISTS_GET_ERROR = 'PLAYLISTS_GET_ERROR';
export const PLAYLISTS_HIDE_ERROR = 'PLAYLISTS_HIDE_ERROR';
export const PLAYLISTS_CLEAR = 'PLAYLISTS_CLEAR';
export const PLAYLISTS_UPDATE_PLANNED_PLAYLIST = 'PLAYLISTS_UPDATE_PLANNED_PLAYLIST';
export const PLAYLISTS_UPDATE_PLANNED_PLAYLIST_FINISH = 'PLAYLISTS_UPDATE_PLANNED_PLAYLIST_FINISH';

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	logout,
	getSessionData,
	activateEmail,
	hideUpdateBanner,
	updateTessa,
	downloadTessaUpdate,
	installTessaUpdate,
	setShowUpdatePopUp
} from '../../../features/auth/redux/actions';
import { sendHeartbeat } from '../../redux/actions/heartbeat.actions';
import { getEnvConfig } from '../../constants';
import Spinner from '../../components/Spinner/Spinner';
import PrivateRouteWrapper from './PrivateRouteWrapper/PrivateRouteWrapper';
import Navbar from '../../components/Navbar/Navbar';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import NavbarStatus from '../../components/NavbarStatus/NavbarStatus';
import ActivationWarning from '../../components/ActivationWarning/ActivationWarning';
import UpdateTessaBanner from '../../components/UpdateTessaBanner/UpdateTessaBanner';
import PopUp from '../../components/PopUp/PopUp';
import { unreadSelector } from '../../../features/appNotifications/redux/reducer';
import { usePrevious } from '../../hooks/usePreviousValue';

let disableInstallTimeout;

const PrivateRoute = ({
	component: Component,
	requiresRobot,
	session,
	user,
	robots,
	robotConfig,
	pageTitle,
	heartbeat,
	logout,
	getSessionData,
	sendHeartbeat,
	activateEmail,
	hideUpdateBanner,
	updateTessa,
	downloadTessaUpdate,
	installTessaUpdate,
	setShowUpdatePopUp,
	unreadNotifications,
	...routeParams
}) => {
	const startHeartBeat = session.done && robots.length && !heartbeat.timerStarted
	const startHeartBeatPrevious = usePrevious(startHeartBeat)
	const inviteCode = routeParams?.computedMatch?.params?.inviteCode;
	const email = routeParams?.computedMatch?.params?.email;
	const showUpdatePopUp = robotConfig.showUpdatePopUp;
	const { t } = useTranslation();

	const onProduction = getEnvConfig().environmentType === 'production';

	const [showMenu, setShowMenu] = useState(false);

	const [showUpdateBanner, setShowUpdateBanner] = useState(false);

	const [disableInstall, setDisableInstall] = useState(false);

	const installUpdate = () => {
		installTessaUpdate(robots[0].id);
		setDisableInstall(true);
		disableInstallTimeout = setTimeout(() => setDisableInstall(false), 60000);
		setShowMenu(false);
	};

	useEffect(() => {
		if (!session.done && session.isAuthenticated) {
			getSessionData();
		}
		return () => {
			if (disableInstallTimeout) {
				clearTimeout(disableInstallTimeout);
			}
		}
	}, []);

	useEffect(() => {
		if (startHeartBeat && !startHeartBeatPrevious) {
      sendHeartbeat();
		}
	}, [startHeartBeat]);

	useEffect(() => {
		const newUpdate = robotConfig.capabilities.includes('remoteUpdate-v2');
		const showBanner = !robotConfig.hideUpdateBanner
			&& (newUpdate
				? ['outdated', 'readyToInstall'].includes(robotConfig.software.updateStatus)
				: robotConfig.software.updateStatus !== 'upToDate'
			)
			&& user.status === 'ACTIVE'
			&& (!heartbeat.isRobotOffline || !newUpdate);
		setShowUpdateBanner(showBanner);

	}, [robotConfig.hideUpdateBanner, robotConfig.software.updateStatus, user.status, heartbeat.isRobotOffline]);
	return (
		<Route
			{...routeParams}
			render={props => {
				if (!session.isAuthenticated && session.eulaExpired) {
					return <Redirect to={'/login'} />
				}
				if (!session.isAuthenticated) {
					return <Redirect to={session.error ? '/error' : '/'} />
				}
				if (!session.done) {
					return <Spinner />
				}
				if (session.done && requiresRobot && !robots.length && !inviteCode) {
					return <Redirect to="/pairing" />
				}
				if (session.done && requiresRobot && !robots.length && inviteCode) {
					return <Redirect to={"/invite/" + inviteCode + '/' + email} />
				}
				return (
					<>
						{ requiresRobot
							? (
								<PrivateRouteWrapper common={
									<>
										{ showUpdatePopUp && (
											<PopUp
												type="info"
												title={t('POPUP.UPDATE_TESSA.TITLE')}
												text={
													<>
														<span>{ t('POPUP.UPDATE_TESSA.MESSAGE1') }</span>
														<strong> { t('POPUP.UPDATE_TESSA.MESSAGE2') }</strong>
														<span> { t('POPUP.UPDATE_TESSA.MESSAGE3') }</span>
													</>
												}
												submitButton={t('POPUP.UPDATE_TESSA.BUTTON_SUBMIT')}
												cancelButton={t('POPUP.UPDATE_TESSA.BUTTON_CANCEL')}
												onSubmit={() => {
													robotConfig.capabilities.includes('remoteUpdate-v2')
														? installUpdate()
														: updateTessa(robots[0].id);
													setShowUpdatePopUp(false);
												}}
												onCancel={() => setShowUpdatePopUp(false)}
											/>
										) }
										{ user.status === 'UNCONFIRMED' && (
											<ActivationWarning
												email={user.email}
												linkSent={session.activationLinkSent}
												onActivateEmail={() => activateEmail({access_token: session.access_token})}
												onChangeEmail={() => props.history.push('/email/change')}
											/>
										) }
										{ showUpdateBanner && (
											<UpdateTessaBanner
												isRobotOffline={heartbeat.isRobotOffline}
												onTheFly={robotConfig.capabilities.some(e => /^remoteupdate-v[\d]/i.test(e))}
												onSubmit={() => {
													robotConfig.capabilities.includes('remoteUpdate-v2')
														? setShowMenu(true)
														: setShowUpdatePopUp(true);
													setShowUpdateBanner(false);
												}}
												onCancel={hideUpdateBanner}
											/>
										) }
										<Navbar
											showDesktopTitle={routeParams.path === '/overview'}
											unconfirmed={user.status === 'UNCONFIRMED'}
											title={pageTitle}
											showMenu={showMenu}
											heartbeat={heartbeat}
											onToggleMenu={() => setShowMenu(prev => !prev)}
											onGoOverview={() => props.history.push('/overview')}>
											<NavbarMenu
												history={props.history}
												location={props.location}
												onProduction={onProduction}
												onGoTo={path => props.history.push({pathname: path, state: { originPath: window.location.pathname }})}
												onCloseMenu={() => setShowMenu(false)}
												onLogout={() => logout()}
												unreadNotifications={unreadNotifications}
												>
												<NavbarStatus
													robotName={robots[0].robot}
													email={user.email}
													updateStatus={
														robotConfig.capabilities.includes('remoteUpdate-v2')
															? robotConfig.software.updateStatus
															: ''
													}
													heartbeat={heartbeat}
													onUpdateDownload={() => downloadTessaUpdate(robots[0].id)}
													onUpdateInstall={() => {
														if (!disableInstall) {
															setShowUpdatePopUp(true);
														}
													}}
												/>
											</NavbarMenu>
										</Navbar>
									</>
								}>
									<Component {...props} />
								</PrivateRouteWrapper>
							)
							: <Component {...props} />
						}
					</>
				)
			}}
		/>
	)
};

const mapStateToProps = ({session, user, robots, robotConfig, ui, heartbeat, appNotifications}) => ({
	session,
	user,
	robots,
	robotConfig,
	heartbeat,
	pageTitle: ui.pageTitle,
	unreadNotifications: unreadSelector(appNotifications)
});

const mapDispatchToProps = {
	logout,
	getSessionData,
	sendHeartbeat,
	activateEmail,
	hideUpdateBanner,
	updateTessa,
	downloadTessaUpdate,
	installTessaUpdate,
	setShowUpdatePopUp
};

PrivateRoute.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import { useTranslation } from 'react-i18next';

const Warning = styled.div`
	height: 70px;
	background-color: ${props => props.theme.colors.warning};
	@media(max-width: 575px){
    height: 120px;
  }
`;

const Inner = styled.div`
	display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
  @media (min-width: 1025px) {
    width:65%;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    padding: 10px 0;
    width: 94%;
  }
`;

const Item = styled.div`
	display: flex;
  justify-content: space-between;
  align-items:center;
  @media (min-width: 1025px) {
    justify-content: space-around;
  }
  &>p,span,a{
    font-size:12px!important;
    font-family: Montserrat-Light;
    color:#e7e3d9!important;
  }
  a {
    text-decoration: underline;
  }
  
  ${props => props.left && css`
		width: 50%;
    @media(max-width:768px){
      width: 53%;  
    }
    @media (max-width: 575px) {
      width: 100%;      
    }
	`}
  ${props => props.right && css`
		width: 45%;
      @media (max-width: 575px) {
        width: 100%;
      }
	`}
`;
Item.propsTypes = {
	left: PropTypes.bool,
	right: PropTypes.bool
};
Item.defaultProps = {
	left: false,
	right: false
};

const Text = styled.p`
  margin: 0;
  max-width: 270px;
	font-family: Montserrat-Light;
  font-weight: lighter;
  @media(max-width:768px) {
    max-width: 230px;
  }
  @media (max-width: 575px) {
    max-width: 50%;
  }
`;

const TextLink = styled(Text).attrs(props => ({as: 'a'}))`
	@media (max-width:320px) {
    white-space: nowrap;
  }
`;

const Bold = styled.strong`
	font-family: Montserrat-Bold;
  font-weight: bold;
`;

const Button = styled.button`
	padding: 0 10px;
  line-height: 1.2;
  width: 145px;
  min-width: 145px;
  height: 36px;
  min-height: 36px;
  border-radius: 4px;
  border: none;
  background-color: #e7e3d9;
  font-family: Montserrat-Bold;
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkgrey};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:active{
    border: none;
  }
  &[disabled]{
    color: rgba(51, 51, 51, 0.5);
    cursor: not-allowed;
  }
`;

const ActivationWarning = ({ linkSent, email, onActivateEmail, onChangeEmail }) => {
	const { t } = useTranslation();

	return (
		<Warning>
			<Inner>
				<Item left>
					{ !linkSent
						? (
							<Text>
								<span>{ t('ACTIVATION_WARNING.MESSAGE') }</span>
								<br/>
								<Bold>{ email }</Bold>
							</Text>
						)
						: (
							<Text>
								<span>{ t('ACTIVATION_WARNING.MESSAGE_LINK_SENT1') }</span>
								<br/>
								<span>{ t('ACTIVATION_WARNING.MESSAGE_LINK_SENT2') }</span>
								<Bold> { email }</Bold>
							</Text>
						)
					}
					<Button disabled={linkSent} onClick={onActivateEmail}>
						{ linkSent
							? t('ACTIVATION_WARNING.BUTTON_SENT')
							: t('ACTIVATION_WARNING.BUTTON_SEND_AGAIN')
						}
					</Button>
				</Item>
				<Item right>
					<TextLink onClick={onChangeEmail}>
						<Bold>{ t('ACTIVATION_WARNING.LINK_CHANGE_EMAIL') }</Bold>
					</TextLink>
					<a
						href="https://help.tinybots.nl/portal/nl/kb/articles/hoe-krijg-ik-de-oranje-balk-weg-uit-de-app"
						target="_blank"
						rel="noopener noreferrer">
						{ t('ACTIVATION_WARNING.LINK_INFO') }
					</a>
				</Item>
			</Inner>
		</Warning>
	)
};

ActivationWarning.propTypes = {
	linkSent: PropTypes.bool.isRequired,
	email: PropTypes.string.isRequired,
	onActivateEmail: PropTypes.func.isRequired,
	onChangeEmail: PropTypes.func.isRequired
};

export default ActivationWarning;

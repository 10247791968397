
import { createSlice, createAsyncThunk, createEntityAdapter, createAction, createSelector, EntityId, Dictionary } from '@reduxjs/toolkit'
import { RobotUser, RobotUserState } from './types'
import { deleteInvite, deleteUserRobot, deleteUserRobotSelf, getInvites, getRobotUsers, patchUser, putInvite } from '../api'
import { v4 as uuidv4 } from 'uuid'
import { getSessionData } from '../../../auth/redux/actions'

const robotUsersAdapter = createEntityAdapter<RobotUser>({
  selectId: (entity) => entity.uuid,
  sortComparer: (a, b) => {
    if (a.role !== 'PRIMARY_CARER' && b.role === 'PRIMARY_CARER') {
      return 1
    }
    if (a.role !== undefined && b.role === undefined) {
      return -1
    }
    return 0
  }
})

export const initialState: RobotUserState = {
  getting: true,
  getError: false,
  patching: false,
  patchError: false,
  robotUsers: robotUsersAdapter.getInitialState()
}

export const clearState = createAction('robotUsers/clearState')
export const clearError = createAction('robotUsers/clearError')

export const invite = createAsyncThunk(
  'robotUsers/invite',
  async ({ robotId, email, clientRelationRole }: { robotId: number, email: string, clientRelationRole: string }, api) => {
    const { data: invites } = await putInvite({ robotId, email, clientRelationRole })
    return invites.find(i => i.email === email)
  }
)

export const uninvite = createAsyncThunk(
  'robotUsers/uninvite',
  async ({ robotId, inviteId, uuid }: { robotId: number, inviteId: number, uuid: string }) => {
    await deleteInvite({ robotId, inviteId })
    return uuid
  }
)

export const unpair = createAsyncThunk(
  'robotUsers/unpair',
  async ({ robotId, userId, uuid, self }: { robotId: number, userId: number, uuid: string, self: boolean }, { dispatch }) => {
    if (self) {
      await deleteUserRobotSelf({ robotId })
      await dispatch(getSessionData())
    } else {
      await deleteUserRobot({ robotId, userId })
    }
    return { uuid, self }
  }
)

export const fetchRobotUsers = createAsyncThunk(
  'robotUsers/fetchRobotUsers',
  async (robotId: number) => {
    const { data: users } = await getRobotUsers({ robotId })
    const { data: invites } = await getInvites({ robotId })
    return [
      ...users.map(u => ({ ...u, uuid: uuidv4() })),
      ...invites.map(i => ({ ...i, uuid: uuidv4() }))
    ]
  }
)

export const makePrimary = createAsyncThunk(
  'robotUsers/makePrimary',
  async ({ robotId, userId, password, otpCode }: { robotId: number, userId: number, password: string, otpCode?: string }, { rejectWithValue, dispatch }) => {
    try {
      const res = await patchUser({
        robotId,
        userId,
        password,
        otpCode,
        userRobot: {
          role: 'PRIMARY_CARER'
        }
      })
      await dispatch(fetchRobotUsers(robotId))
      await dispatch(getSessionData())
      return res.data
    } catch (err) {
      return rejectWithValue({ status: err.response.status, ...err.response.data })
    }
  }
)

export const robotUserSlice = createSlice({
  name: 'robotUser',
  initialState: initialState,
  reducers: {
    clearError: state => ({ ...state, getError: false, patchError: false })
  },
  extraReducers: builder => {
    builder.addCase(clearState, () =>
      initialState
    )
    builder.addCase(clearError, state => {
      state.getError = false
      state.patchError = false
    })
    builder.addCase(fetchRobotUsers.pending, (state) => {
      state.getting = true
      state.getError = false
    })
    builder.addCase(fetchRobotUsers.fulfilled, (state, action) => {
      state.getting = false
      state.getError = false
      robotUsersAdapter.setAll(state.robotUsers, action.payload)
    })
    builder.addCase(fetchRobotUsers.rejected, (state) => {
      state.getting = false
      state.getError = true
    })
    builder.addCase(uninvite.pending, (state) => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(uninvite.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = false
      robotUsersAdapter.removeOne(state.robotUsers, action.payload)
    })
    builder.addCase(uninvite.rejected, (state) => {
      state.patching = false
      state.patchError = true
    })
    builder.addCase(unpair.pending, (state) => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(unpair.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = false
      robotUsersAdapter.removeOne(state.robotUsers, action.payload.uuid)
    })
    builder.addCase(unpair.rejected, (state) => {
      state.patching = false
      state.patchError = true
    })
    builder.addCase(invite.pending, (state) => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(invite.fulfilled, (state, action) => {
      state.patching = false
      state.patchError = false
      const invite = action.payload
      if (invite === undefined) {
        return state
      }
      const robotUsers = robotUsersAdapter.getSelectors().selectAll(state.robotUsers)
      const existing = robotUsers.find(u => u.email === invite.email)
      if (existing != null) {
        robotUsersAdapter.updateOne(state.robotUsers, { id: existing.uuid, changes: invite })
      } else {
        robotUsersAdapter.addOne(state.robotUsers, { ...invite, uuid: uuidv4() })
      }
    })
    builder.addCase(invite.rejected, (state) => {
      state.patching = false
      state.patchError = true
    })
    builder.addCase(makePrimary.pending, (state) => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(makePrimary.fulfilled, (state) => {
      state.patching = false
      state.patchError = false
    })
    builder.addCase(makePrimary.rejected, (state, action) => {
      const err = action.payload as any
      state.patching = false
      state.patchError = err !== undefined ? err : true
    })
  }

})

const idsSelector = (state: RobotUserState): EntityId[] => state.robotUsers.ids
const entitiesSelector = (state: RobotUserState): Dictionary<RobotUser> => state.robotUsers.entities
export const robotUsersSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ids.map(id => entities[id]).filter((robotUser): robotUser is RobotUser => Boolean(robotUser))
)

// Extract the action creators object and the reducer
const { reducer } = robotUserSlice

export const robotUser = reducer

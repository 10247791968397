import {
  SONGS_OVERVIEW_SET,
  SONGS_OVERVIEW_GET,
  SONGS_OVERVIEW_GET_ERROR,
  SONGS_OVERVIEW_CLEAR,
  SONGS_OVERVIEW_SET_FREE_SPACE,
  SONGS_OVERVIEW_SEARCH,
  SONGS_OVERVIEW_SELECT,
  SONGS_OVERVIEW_GET_PLAYLISTS,
  SONGS_OVERVIEW_GET_PLAYLISTS_ERROR,
  SONGS_OVERVIEW_SET_PLAYLISTS,
  SONGS_OVERVIEW_HIDE_POP_UP,
  SONGS_OVERVIEW_DELETE_SONG,
  SONGS_OVERVIEW_DELETE_SONG_ERROR,
  SONGS_OVERVIEW_DELETE_SONG_SUCCESS
} from '../constants/actionTypes';

import { allItemsSelected, setUniqueName, selectSongs } from '../../../utils';

export const initialState = {
  done: false,
  songs: [],
  getSongsError: false,
  deleteSongsError: false,
  playlists: [],
  searchedSong: '',
  allSongsSelected: false,
  freeSpacePct: 0,
  playlistsPopUpVisible: false
};

export const musicOverview = function(state = initialState, action) {
  switch (action.type) {
    case SONGS_OVERVIEW_CLEAR:
      return {...initialState};

    case SONGS_OVERVIEW_GET:
      return {...state, done: false};

    case SONGS_OVERVIEW_SET:
      return {...state, songs: action.payload.length ? setUniqueName(action.payload) : [], done: true};

    case SONGS_OVERVIEW_GET_ERROR:
      return {...state, done: true, getSongsError: true};

    case SONGS_OVERVIEW_SET_FREE_SPACE:
      return { ...state, freeSpacePct: action.payload };

    case SONGS_OVERVIEW_SELECT:
      const selectedSongs = selectSongs(state, action);
      const allSongsSelected = allItemsSelected(selectedSongs);
      return {...state, songs: selectedSongs, allSongsSelected};

    case SONGS_OVERVIEW_SEARCH:
      const foundSongs = state.songs.map(song => (
        {...song, hide: action.payload ? !song.title.toLowerCase().includes(action.payload.toLowerCase()) : false}
      ));
      return {
        ...state,
        songs: foundSongs,
        allSongsSelected: allItemsSelected(foundSongs),
        searchedSong: action.payload
      };

    case SONGS_OVERVIEW_GET_PLAYLISTS:
      return {...state, done: false};

    case SONGS_OVERVIEW_GET_PLAYLISTS_ERROR:
      return {...state, deleteSongsError: true, done: true, playlists: []};

    case SONGS_OVERVIEW_SET_PLAYLISTS:
      return {...state, done: true, playlists: action.payload, playlistsPopUpVisible: true};

    case SONGS_OVERVIEW_HIDE_POP_UP:
      return {...state, deleteSongsError: false, getSongsError: false, playlistsPopUpVisible: false};

    case SONGS_OVERVIEW_DELETE_SONG:
      return {...state, done: false, playlistsPopUpVisible: false};

    case SONGS_OVERVIEW_DELETE_SONG_ERROR:
      return {...state, done: true,  deleteSongsError: true};

    case SONGS_OVERVIEW_DELETE_SONG_SUCCESS:
      return {...state, done: true, songs: action.payload};

    default: return state
  }
};

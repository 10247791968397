import React from "react"
import PopUpTitle from '../../common/components/PopUp/PopUpTitle/PopUpTitle'
import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { AppNotification } from "./types"
import { useTranslation } from 'react-i18next'
import styledComponentsTheme from "../../common/constants/styledComponentsTheme/styledComponentsTheme"
import { LinkRenderer } from "./LinkRenderer"

interface StyleProps { active?: boolean; single?: boolean }

export const getPointerEventsCSS = ({ active }: StyleProps) => active ? 'all' : 'none'
export const getOpacityCSS = ({ active }: StyleProps) => active ? 1 : 0
export const getTransitionCSS = ({ active }: StyleProps) => active ? 'opacity .1s .1s ease' : 'opacity .1s ease'
export const getTopCSS = ({ single }: StyleProps) => single ? 0 : 40

const useSlideStyles = makeStyles((_theme: Theme) =>
    createStyles({
        root: {
            marginTop: getTopCSS,
            flexBasis: '100%',
            flexShrink: 0,
            overflowY: 'auto',
            marginRight: '-100%',
            pointerEvents: getPointerEventsCSS,
            opacity: getOpacityCSS,
            transition: getTransitionCSS
        },
        markDown: {
            '& img': {
                maxWidth: '100%',
                display: 'block'
            },
            '& a': {
                textDecoration: 'underline',
                '& em': {
                    color: 'black',
                    fontFamily: 'Montserrat-Bold',
                    fontStyle: 'normal',
                    textDecoration: 'underline',
                },
                '& strong': {
                    color: '#3f7a19',
                    fontFamily: 'Montserrat-Bold',
                    textDecoration: 'underline',
                    '& em': {
                        color: '#fa3505',
                        fontFamily: 'Montserrat-Bold',
                        fontStyle: 'normal',
                        textDecoration: 'underline'
                    },
                },
            },
            '& strong': {
                '& a': {
                    color: '#3f7a19',
                    fontFamily: 'Montserrat-Bold',
                    fontStyle: 'normal',
                    textDecoration: 'underline'
                },
                '& em': {
                    '& a': {
                        color: '#fa3505',
                        fontFamily: 'Montserrat-Bold',
                        textDecoration: 'underline',
                    }
                },
            },
            '& em': {
                '& a': {
                    color: 'black',
                    fontFamily: 'Montserrat-Bold',
                    fontStyle: 'normal',
                    textDecoration: 'underline'
                }
            },
            '& p': {
                fontSize: 14,
            },
            '& h1': {
                fontSize: 18,
                marginTop: 10,
                marginBottom: 5
            },
            '& h2': {
                fontSize: 15,
                marginTop: 6,
                marginBottom: 3
            },
            '& h3': {
                fontSize: 15,
                marginTop: 6,
                marginBottom: 3
            },
            '& h4': {
                fontSize: 15,
                marginTop: 6,
                marginBottom: 3
            },
        },
        title: {
            marginBottom: 20
        },
        icon: {
            fontSize: 24
        },
        notificationType: {
            color: styledComponentsTheme.colors.lightgrey60,
            marginBottom: 7,
            fontFamily: 'Montserrat-Light'
        }
    })
)

const AppNotificationSlide = ({ notification, active, single }: { notification: AppNotification, active: boolean, single: boolean }) => {
    const classes = useSlideStyles({ active, single })
    const { t } = useTranslation()

    return <div className={classes.root} data-testid={`notification-slide-${notification.uuid}`}>
        <p className={classes.notificationType}>{t(`APP_NOTIFICATIONS.TYPES.${notification.notificationType.toUpperCase()}`)}</p>
        <PopUpTitle type="info" className={classes.title}>{notification.title}</PopUpTitle>
        <ReactMarkdown className={classes.markDown} source={notification.content} renderers={{ link: LinkRenderer }} />
    </div>
}

export default AppNotificationSlide
import axios, { AxiosResponse } from 'axios'
import { getUrls } from '../../../common/constants'
import { RobotSettings } from '../redux/types'
import { ProfileRobotUser } from '../../generalSettings/UserProfile/redux/types'

export const getRobotSettings = async (
  robotId: string
): Promise<AxiosResponse> => {
  const url = getUrls('commander_data', 'settings_robot').replace(
    '{robotId}',
    robotId
  )
  const response = await axios.get(url)
  return response
}

export const patchRobotSettings = async (
  robotId: string,
  settings: Partial<RobotSettings>
): Promise<AxiosResponse> => {
  const url = getUrls('commander_data', 'settings_robot').replace(
    '{robotId}',
    robotId
  )
  return await axios.patch(url, settings)
}

export const patchEndUserName = async ({
  robotId,
  endUserName
}: {
  robotId: string
  endUserName: string
}): Promise<AxiosResponse<{
  id: number
  robot: string
  endUserName: string
}>> => {
  const url = getUrls('checkpoint', 'robot-profile').replace(
    '{robotId}',
    robotId
  )
  return await axios.patch<{
    id: number
    robot: string
    endUserName: string
  }>(url, {
    endUserName
  })
}

export const patchAlias = async ({
  robotId,
  alias
}: {
  robotId: string
  alias: string | null
}): Promise<AxiosResponse<ProfileRobotUser>> => {
  const url = getUrls('checkpoint', 'robot-user-self-v3').replace(
    '{robotId}',
    robotId
  )
  return await axios.patch<ProfileRobotUser>(url, {
    alias
  })
}

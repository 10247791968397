import {
	SIGN_UP_CLEAR,
	SIGN_UP_SAVE_STEP,
	SIGN_UP_SET_PASSWORD_STRENGTH,
	SIGN_UP_SHOW_WEAK_PASSWORD_POP_UP,
	SIGN_UP_HIDE_WEAK_PASSWORD_POP_UP,
	SIGN_UP_HIDE_EMAIL_EXISTS_POP_UP,
	SIGN_UP_START,
	SIGN_UP_FINISH
} from '../constants/actionTypes';
import { passwordAnalysis, userSignUp } from '../../../api';

export const clearSignUp = () => ({type: SIGN_UP_CLEAR});

export const setSignUpPasswordStrength = cost => ({
	type: SIGN_UP_SET_PASSWORD_STRENGTH,
	payload: cost
});

export const saveStep = (stepName, fields) => ({
	type: SIGN_UP_SAVE_STEP, payload: {stepName, fields}
});

export const showWeakPasswordPopUp = password => ({
	type: SIGN_UP_SHOW_WEAK_PASSWORD_POP_UP,
	payload: password
});

export const hideWeakPasswordPopUp = () => ({type: SIGN_UP_HIDE_WEAK_PASSWORD_POP_UP});

export const hideEmailExistsPopUp = () => ({type: SIGN_UP_HIDE_EMAIL_EXISTS_POP_UP});

export const startSignUp = () => ({type: SIGN_UP_START});

export const finishSignUp = payload => ({type: SIGN_UP_FINISH, payload});

export const signUpUser = data => async dispatch => {
	dispatch(startSignUp());

	try {
		await userSignUp(data);
		dispatch(finishSignUp({passwordStepDone: true}));
	} catch (e) {
		switch (e.response.status) {
			case 409: dispatch(finishSignUp({showEmailExistsPopUp: true}));
				break;
			case 400:
			case 404: dispatch(finishSignUp({error: true}));
				break;
			default: dispatch(finishSignUp({error: true}));
		}
	}
};

export const getPasswordStrength = password => async dispatch => {
	if (password.length < 8) {
		dispatch(setSignUpPasswordStrength(null));
	} else {
		try {
			const response = await passwordAnalysis(password);
			dispatch(setSignUpPasswordStrength(response.data.cost));
		} catch (e) {
			dispatch(setSignUpPasswordStrength(null));
		}
	}
};

import React, { useState } from 'react'
import { ProfileRobotUser } from '../generalSettings/UserProfile/redux/types'
import { UserState } from '../auth/redux/reducers/types'
import { useTranslation } from 'react-i18next'
import { useUnpair } from '../generalSettings/RobotUsers/hooks/useUnpair'
import { ButtonBase, SvgIcon, MuiThemeProvider, createStyles, makeStyles, createTheme } from '@material-ui/core'
import { ReactComponent as RobotIcon } from '../../assets/images/logo/tessa_creme.svg'
import { MoreMenu } from '../mScripts/tessaScript/MoreMenu'
import styled from 'styled-components'
import { useGetDisplayName } from './helpers'
import PopUp from '../../common/components/PopUp/PopUp'
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(() =>
  createStyles({
    svgIcon: {
      width: 16
    },
    menuItem: {
      justifyContent: 'flex-start',
      minWidth: 0,
      fontSize: 14,
      fontFamily: 'Montserrat-Light',
      whiteSpace: 'normal',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1
    },
    middleColumn: {
      padding: '10px 0'
    }
  })
)

const materialTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      500: '#00000'
    }
  }
})

const LeftColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 70px;
`

const RightColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 70px;
  margin: 7px 0;
`

const Name = styled.div`
  line-height: 1;
  font-family: 'Montserrat';
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
`

const Serial = styled.div`
  font-family: 'Roboto-Mono';
  font-weight: 200;
  line-height: 1;
  font-size: 12px;
  line-height: 1.2;
`

const RobotItem = styled.li`
  width: 100%;
  line-height: 0;
  border-bottom: 1px solid rgba(232, 228, 218, 0.2);
  height: auto;
`

const RobotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
`

const MiddleColumn = styled.div`
  flex-basis: 100%;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  min-width: 0;
`

const NoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;  
`

export interface PrimaryWarningPopUpProps { onSubmit: (...args: any) => any }

const PrimaryWarningPopUp: React.FunctionComponent<PrimaryWarningPopUpProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  return (
    <PopUp
      type='warning'
      title={t('POPUP.REMOVE_PRIMARY_SELF.TITLE')}
      text={<ReactMarkdown source={t('POPUP.REMOVE_PRIMARY_SELF.MESSAGE')} />}
      onSubmit={onSubmit}
      submitButton={t('POPUP.REMOVE_PRIMARY_SELF.CANCEL')}
    />
  )
}

export interface RobotComponentProps { robotUser: ProfileRobotUser, switchRobot: (id: number) => Promise<void>, user: UserState, edit: () => any }

const RobotSwitcherItem: React.FunctionComponent<RobotComponentProps> = ({ robotUser, switchRobot, user, edit }: RobotComponentProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { unPairPopup, startUnpair } = useUnpair(robotUser.id, true, true)
  const [showPrimaryWarn, setShowPrimaryWarn] = useState(false)
  const moreMenuItems = [
    {
      type: 'edit',
      text: t('GENERAL_SETTINGS.PAIRED_USERS.ACTIONS.EDIT'),
      fn: () => {
        edit()
      }
    },
    {
      type: 'unpair',
      text: t('GENERAL_SETTINGS.PAIRED_USERS.ACTIONS.UNPAIR'),
      fn: (item: any) => {
        if (robotUser.role === 'PRIMARY_CARER') {
          setShowPrimaryWarn(true)
        } else {
          startUnpair(user.userId as number)
        }
      }
    }
  ]

  return (
    <>
      <RobotItem key={robotUser.id}>
        <RobotContainer>
          <ButtonBase value={robotUser.id} classes={{ root: classes.menuItem }} onClick={async () => await switchRobot(robotUser.id)}>
            <LeftColumn>
              <SvgIcon component={RobotIcon} classes={{ root: classes.svgIcon }} viewBox='0 0 20 20' />
            </LeftColumn>
            <MiddleColumn className={classes.middleColumn}>
              <Name>
                <NoWrap>{useGetDisplayName(robotUser)}&nbsp;</NoWrap>
                {
                  Boolean(robotUser?.alias) && Boolean(robotUser?.endUserName)
                    ? (
                      <>
                        <wbr />
                        <NoWrap>{`(${robotUser.endUserName as string})`}</NoWrap>
                      </>
                    ) : null
                }
              </Name>
              <Serial>
                Serial: {robotUser.robot}
              </Serial>
            </MiddleColumn>
          </ButtonBase>
          <RightColumn>
            <MuiThemeProvider theme={materialTheme}>
              <MoreMenu uuid={robotUser.id.toString()} moreMenuItems={moreMenuItems as any} />
            </MuiThemeProvider>
          </RightColumn>
        </RobotContainer>
      </RobotItem>
      {unPairPopup}
      {showPrimaryWarn ? <PrimaryWarningPopUp onSubmit={() => setShowPrimaryWarn(false)} /> : null}
    </>
  )
}

export default RobotSwitcherItem


import { IsBoolean, IsIn, IsInt, IsOptional, IsString } from 'class-validator'
import { ScriptStoreNext } from './Next'

export type StepType = 'say' | 'wait' | 'closedQuestion' | 'goto' | 'multipleChoice' | 'report'


export const stepTypes: StepType[] = ['say', 'wait', 'closedQuestion', 'goto', 'multipleChoice', 'report']

/*
  Used for creating script steps
*/
export class ScriptStoreStep {
  @IsOptional()
  @IsInt()
  public id?: number

  @IsString()
  public tempId: string

  @IsOptional()
  @IsString()
  public treePosition?: string

  @IsOptional()
  @IsInt()
  public createdAt?: number

  @IsIn(stepTypes)
  public stepType: StepType

  @IsOptional()
  @IsInt()
  public scriptVersionId?: number

  @IsBoolean()
  public first: boolean

  @IsOptional()
  @IsBoolean()
  public showInvalid?: boolean;
}

/*
  Used for creating script steps
*/
export class ScriptStepDTO {
  @IsOptional()
  @IsInt()
  public id?: number

  @IsOptional()
  @IsString()
  public tempId?: string

  @IsIn(stepTypes)
  public stepType: StepType

  @IsOptional()
  @IsInt()
  public scriptVersionId?: number

  @IsBoolean()
  public first: boolean
}

export type ScriptStepWithPosition = ScriptStoreStep & { nextSteps: ScriptStoreNext[] }

import styled from 'styled-components';
import arrowUrl from '../../../assets/images/icon/back-arrow.svg'

const NavArrow = styled.div`
	display: inline-block;
	background: url(${arrowUrl}) no-repeat 50%;
  height:20px;
  width:20px;
  &:hover{
    cursor:pointer;
  }
`;

export default NavArrow;

import {
	SETTINGS_CLEAR,
	SETTINGS_TOGGLE_DROPDOWN,
	SETTINGS_SELECT_LANG,
	SETTINGS_HIDE_POP_UP,
	SETTINGS_SHOW_POP_UP,
	SETTINGS_SET_LOCALIZATION_MAINTENANCE
} from '../constants/actionTypes';
import { prepareLanguagesArray } from '../../utils';
import { getLanguages } from '../../../../common/constants/lang/lang';
import { getEnvConfig } from '../../../../common/constants';

const onProduction = getEnvConfig().environmentType === 'production';
const initialLang = getLanguages().find(lang => lang.label === 'nl');
const debugLang = JSON.parse(localStorage.getItem('debugLang'));

export const initialState = {
	onProduction,
	debugLang,
	done: true,
	showConfirmDebugPopup: false,
	showDropdown: false,
	selectedLanguage: initialLang,
	languages: prepareLanguagesArray(initialLang)
};

const settingsToggleDropdown = state => ({...state, showDropdown: !state.showDropdown});

const settingsSelectLang = (state, action) => ({
	...state,
	selectedLanguage: getLanguages().find(lang => lang.label === action.payload),
	languages: prepareLanguagesArray(getLanguages().find(lang => lang.label === action.payload)),
	showDropdown: false
});

const settingsClear = initialState => ({...initialState});

const settingsHidePopUp = state => ({...state, showConfirmDebugPopup: false});

const settingsShowPopUp = state => ({...state, showConfirmDebugPopup: true});

const settingsSetLocalizationMaintenance = state => ({
	...state,
	done: false,
	showConfirmDebugPopup: false
});

export const settings = (state = initialState, action) => {
	switch (action.type) {
		case SETTINGS_CLEAR: return settingsClear(initialState);
		case SETTINGS_TOGGLE_DROPDOWN: return settingsToggleDropdown(state);
		case SETTINGS_SELECT_LANG: return settingsSelectLang(state, action);
		case SETTINGS_HIDE_POP_UP: return settingsHidePopUp(state);
		case SETTINGS_SHOW_POP_UP: return settingsShowPopUp(state);
		case SETTINGS_SET_LOCALIZATION_MAINTENANCE: return settingsSetLocalizationMaintenance(state);
		default: return state;
	}
};

import { fetchTessaScript, putTessaScript, getScriptNames, getScriptExecution, checkMultipleChoiceCommand, getReports } from './thunks'
import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  Update
} from '@reduxjs/toolkit'
import {
  ScriptStoreNext
} from '../types/Next'
import { ScriptStoreNode } from '../types/ScriptNode'
import {
  ScriptStoreStep,
  StepType
} from '../types/ScriptStep'
import { ScriptVersion } from '../types/ScriptVersion'
import { addCommandReducer, addIntentionReducer, addScriptStepReducer, changeStepTypeReducer, checkMultipleChoiceCommandReducer, clearScriptsErrorsReducer, editCommandReducer, editNodeReducer, editScriptReducer, editScriptStepReducer, fetchTessaScriptFullfilledReducer, fetchTessaScriptPendingReducer, fetchTessaScriptRejectedReducer, getReportsFinishedReducer, getScriptExecutionFulfilledReducer, getScriptNamesFulfilledReducer, getScriptNamesPendingReducer, getScriptNamesRejectedReducer, loadLocalStateReducer, putTessaScriptFulfilledReducer, putTessaScriptPendingReducer, putTessaScriptRejectedReducer, removeCommandReducer, removeIntentionReducer, removeStepReducer, setGotoReducer, setScrollToTempIdReducer, editFormFieldReducer, editReportTypeReducer } from './actionReducers'
import { ScriptState } from './ScriptState'

export const scriptStepsAdapter = createEntityAdapter<ScriptStoreStep>({
  selectId: entity => entity.tempId
})
export const scriptNodesAdapter = createEntityAdapter<ScriptStoreNode>({
  selectId: entity => entity.tempId
})
export const scriptNextAdapter = createEntityAdapter<ScriptStoreNext>({
  selectId: entity => entity.tempId
})
export const scriptVersionsAdapter = createEntityAdapter<
  ScriptVersion & { id: number }
>()

export const loadLocalState = createAction('loadLocalState')
export const editScript =
  createAction<
    Pick<ScriptVersion, 'scriptName' | 'scriptCategory' | 'scriptLanguage'>
  >('editScript')
export const clearScript = createAction('clearScript')
export const clearScriptsErrors = createAction('clearScriptsErrors')
export const newScript = createAction<string>('newScript')
export const addStep =
  createAction<{ stepType: StepType; parentNextTempId?: string }>('addStep')
export const editNode = createAction<Update<ScriptStoreNode>>('editNode')
export const editScriptStep =
  createAction<Update<Pick<ScriptStoreStep, 'showInvalid'>>>('editStep')
export const removeStep = createAction<string>('removeStep')
export const changeStepType =
  createAction<{ tempId: string; stepType: StepType }>('changeStepType')
export interface EditCommandPayload { commandId: number | 'primary'; value: string; nextTempId: EntityId }
export const editCommand =
  createAction<EditCommandPayload>(
    'editCommand'
  )
export const removeCommand =
  createAction<{ commandId: number; nextTempId: EntityId }>('removeCommand')
export const addCommand = createAction<{ nextTempId: EntityId }>('addCommand')
export const addIntention = createAction<{ stepTempId: EntityId }>('addIntention')
export const editFormField = createAction<{ nodeTempId: EntityId, key: string, value: string | null }>('editFormField')
export const editReportType = createAction<{ nodeTempId: EntityId, reportType: string }>('editReportType')
export const removeIntention = createAction<{ nextTempId: EntityId }>('removeIntention')
export const setGoto =
  createAction<{ tempId: string; positionString?: string }>('setGoto')
export const setScrollToTempId =
  createAction<{ tempId: string }>('scrollToTempId')

export const initialState: ScriptState = {
  getting: true,
  putting: false,
  conversionError: false,
  script: { scriptName: '', scriptLanguage: '' },
  scrollToStep: undefined,
  scriptVersions: scriptVersionsAdapter.getInitialState(),
  scriptSteps: scriptStepsAdapter.getInitialState(),
  scriptNodes: scriptNodesAdapter.getInitialState(),
  scriptNext: scriptNextAdapter.getInitialState(),
  reports: []
}

export const createTessaScriptSlice = (initial: ScriptState) => createSlice({
  name: 'tessaScriptSlice',
  initialState: initial,
  reducers: {
    clearError: state => ({ ...state, getError: false, putError: false })
  },
  extraReducers: builder => {
    builder
      .addCase(clearScript, () => initialState)
      .addCase(clearScriptsErrors, clearScriptsErrorsReducer)
      .addCase(editScript, editScriptReducer)
      .addCase(fetchTessaScript.pending, fetchTessaScriptPendingReducer)
      .addCase(fetchTessaScript.rejected, fetchTessaScriptRejectedReducer)
      .addCase(fetchTessaScript.fulfilled, fetchTessaScriptFullfilledReducer)
      .addCase(putTessaScript.pending, putTessaScriptPendingReducer)
      .addCase(putTessaScript.rejected, putTessaScriptRejectedReducer)
      .addCase(putTessaScript.fulfilled, putTessaScriptFulfilledReducer)
      .addCase(getScriptNames.pending, getScriptNamesPendingReducer)
      .addCase(getScriptNames.rejected, getScriptNamesRejectedReducer)
      .addCase(getScriptNames.fulfilled, getScriptNamesFulfilledReducer)
      .addCase(getScriptExecution.fulfilled, getScriptExecutionFulfilledReducer)
      .addCase(addStep, addScriptStepReducer)
      .addCase(editFormField, editFormFieldReducer)
      .addCase(editReportType, editReportTypeReducer)
      .addCase(removeStep, removeStepReducer)
      .addCase(changeStepType, changeStepTypeReducer)
      .addCase(editNode, editNodeReducer)
      .addCase(editScriptStep, editScriptStepReducer)
      .addCase(addCommand, addCommandReducer)
      .addCase(editCommand, editCommandReducer)
      .addCase(removeCommand, removeCommandReducer)
      .addCase(addIntention, addIntentionReducer)
      .addCase(removeIntention, removeIntentionReducer)
      .addCase(setScrollToTempId, setScrollToTempIdReducer)
      .addCase(setGoto, setGotoReducer)
      .addCase(loadLocalState, loadLocalStateReducer)
      .addCase(checkMultipleChoiceCommand.fulfilled, checkMultipleChoiceCommandReducer)
      .addCase(getReports.fulfilled, getReportsFinishedReducer)
  }
})

export const tessaScriptSlice = createTessaScriptSlice(initialState)

export const entityArray = (state: EntityState<any>) =>
  state.ids.map(id => state.entities[id]).filter(e => e)

// Extract the action creators object and the reducer
const { reducer } = tessaScriptSlice

export const tessaScript = reducer

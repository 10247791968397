import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import deleteIcon from '../../../../assets/images/icon/tb_app_icon_delete.svg'
import editIcon from '../../../../assets/images/icon/tb_app_icon_editing.svg'
import editInScriptIcon from '../../../../assets/images/icon/tb_app_icon_edit_sequence.svg'
import primaryUserIcon from '../../../../assets/images/icon/tb_app_icon_primary_user.svg'
import unpairIcon from '../../../../assets/images/icon/tb_app_icon_remove_user.svg'
import viewIcon from '../../../../assets/images/icon/tb_app_icon_view.svg'
import retryIcon from '../../../../assets/images/icon/refresh_black_24dp.svg'

export const Wrapper = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  line-height: 50px;
  font-family: 'Raleway', 'Helvetica';
  font-size: 16px;
  color: ${props => props.theme.colors.darkgrey};
  &:hover, &:focus {
    text-decoration: none;
    background-color: ${props => props.theme.colors.beige10};
    cursor: pointer;
  }
`

export const Icon = styled.i.attrs(props => {
  const iconTypes = {
    editAll: 'fa-refresh',
    music: 'fa-music',
    download: 'fa-download'
  }
  if (Object.keys(iconTypes).includes(props.type)) {
    return {
      className: `fa ${iconTypes[props.type]}`
    }
  }
})`
  position: relative;
  left: 0;
  display: inline-block;
  margin-right: 1rem;
  font-size: 20px;
  color: ${props => props.theme.colors.darkgrey};
  ${props => {
    const iconTypes = {
      edit: editIcon,
      editInScript: editInScriptIcon,
      delete: deleteIcon,
      primaryUser: primaryUserIcon,
      unpair: unpairIcon,
      view: viewIcon,
      retry: retryIcon
    }
    if (Object.keys(iconTypes).includes(props.type)) {
      return `
        background: url(${iconTypes[props.type]}) 50% no-repeat;
        width: 20px;
        height: 20px;
      `
    }
  }}
`

Icon.propTypes = {
  type: PropTypes.string.isRequired
}

const OverflowMenuItem = ({ type = 'edit', text, clicked, style, className }) => {
  return (
    <Wrapper onClick={clicked} style={style} className={className}>
      <Icon type={type} />
      <span>{text}</span>
    </Wrapper>
  )
}

OverflowMenuItem.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  clicked: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string
}

OverflowMenuItem.defaultProps = {
  style: {},
  className: ''
}

export default OverflowMenuItem

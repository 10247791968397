import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import brandIconUrl from '../../../assets/images/logo/icon-creme.svg'
import noConnectionIconUrl from '../../../assets/images/icon/no-connection.svg'
import RobotSwitcher from '../../../features/robotSwitcher/RobotSwitcher'

const Wrapper = styled.nav`
  z-index: 1;
  display: flex;
  min-height: 56px;
  height: 56px;
  line-height: 56px;
  margin: 0;
  padding: 0 0 0 15px;
  list-style-type: none;
  background-color: ${props => props.theme.colors.darkgrey};
  transition: padding 1s ease;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.32);
  @media (max-width: 575px) {
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  @media (min-width: 768px) {
    border-bottom: 1px solid #090909;
  }
  @media (min-width: 1025px) {
    padding: 0 calc(15px + 10%);
  }
` // min-height: 56px; ios < 10 fix ipad air 2

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const ItemBrand = styled(Item)`
  flex: 1;
  @media (max-width: 575px) {
    flex: 0;
  }
`

const Brand = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;

  &:hover {
    cursor: pointer;
  }
  span {
    color: ${props => props.theme.colors.cremewhite};
    font-size: 18px;
    font-family: 'Montserrat', 'Helvetica';
    @media (max-width: 767px) {
      display: none;
    }
  }
`

const BrandIcon = styled.div`
  display: block;
  background: url(${brandIconUrl}) no-repeat 50% 50%;
  width: 45px;
  height: 45px;
  background-size: contain;
  @media (min-width: 768px) and (min-height: 550px) {
    width: 65px;
    height: 65px;
  }
`

const ItemTitle = styled(Item).attrs({ as: 'h1' })`
  display: ${props => props.showDesktopTitle ? 'flex' : 'none'};
  margin: 0;
  color: ${props => props.theme.colors.cremewhite};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  opacity: ${props => props.showDesktopTitle ? 1 : 0};
  transition: opacity 300ms ease;
  white-space: nowrap;
  margin-left: 'auto';
  margin-right: 'auto';

  @media (min-width: 0) and (max-width: 375px) {
    font-size: 14px;
  }

  @media (min-width: 300px) and (max-width: 575px) {
    display: flex;
    opacity: 1;
  }

  @media (min-width: 576px) {
    width: 100%;
    text-align: center;
    display: inline;
    line-height: 56px;
  }

  @media (min-width: 768px) {
    padding-right: 90px;
  }

`

const ItemButton = styled(Item)`
  transition: background-color 300ms ease;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.grey};
  }
`

const ItemHamburger = styled(ItemButton)`
  position: relative;
  padding: 0 28px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    z-index: 1;
  }
  @media (min-width: 1028px) {
    border-right: 1px solid rgba(232, 228, 218, 0.2);
    border-left: 1px solid rgba(232, 228, 218, 0.2);
  }
  @media (max-width: 575px) {
    padding: 0 25px;
  }
  ${props =>
    props.active &&
    css`
      background-color: #3d7c0c;
      &:hover {
        background-color: #3d7c0c;
      }
    `}
`
ItemHamburger.propTypes = {
  active: PropTypes.bool.isRequired
}

const HamburgerButton = styled.button`
  position: relative;
  background-color: transparent;
  border: 0;
`

const HamburgerStatusIcon = styled.span`
  display: block;
  background: url(${noConnectionIconUrl}) no-repeat 50% 50%;
  position: absolute;
  top: -7px;
  left: -2px;
  color: ${props => props.theme.colors.warning};
  font-size: 16px;
  width: 15px;
  height: 15px;
`

const HamburgerIconBar = styled.span`
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: ${props => props.theme.colors.cremewhite};
  margin: 4px 0;
`

const RobotSwitcherContainer = styled.div`
  align-items: flex-start;
  position: absolute;
  display: flex;
  justify-content: stretch;
  font-size: 11px;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  color: rgba(232, 228, 218, 0.8);
  pointer-events: none;
  top: 0;
  // styling for unconfirmed account banner
  margin-top: ${props => props.unconfirmed ? '70px' : 0 };
  @media(max-width: 575px) {
    margin-top: ${props => props.unconfirmed ? '120px' : 0 };
  }
  @media (min-width: 768px) {
    padding-right: 90px;
  }
  @media (min-width: 1025px) {
    padding: 0px calc(15px + 10%);
    padding-right: calc(105px + 10%);
  }
  * {
    pointer-events: initial;
  }
`

const Navbar = ({
  title,
  showMenu,
  heartbeat,
  children,
  onToggleMenu,
  onGoOverview,
  showDesktopTitle,
  unconfirmed
}) => {
  return (
    <Wrapper>
      <RobotSwitcherContainer unconfirmed={unconfirmed}>
        <RobotSwitcher />
      </RobotSwitcherContainer>
      <ItemBrand onClick={onGoOverview}>
        <Brand>
          <BrandIcon />
          <span>TINYBOTS</span>
        </Brand>
      </ItemBrand>
      <ItemTitle showDesktopTitle={showDesktopTitle}>{title}</ItemTitle>
      <ItemHamburger active={showMenu} onClick={onToggleMenu}>
        <HamburgerButton>
          {heartbeat.status === 'offline' && <HamburgerStatusIcon />}
          <HamburgerIconBar />
          <HamburgerIconBar />
          <HamburgerIconBar />
        </HamburgerButton>
        {showMenu && children}
      </ItemHamburger>
    </Wrapper>
  )
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  showMenu: PropTypes.bool.isRequired,
  children: PropTypes.element,
  heartbeat: PropTypes.object.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
  onGoOverview: PropTypes.func.isRequired,
  showDesktopTitle: PropTypes.bool
}

Navbar.defaultProps = {
  showDesktopTitle: false,
  unconfirmed: PropTypes.bool.isRequired,
}

export default Navbar

/* eslint-disable no-loop-func */
import QUESTION_DEFAULTS from '../../../common/constants/scripts/questions';

export const prepareFilteredArray = (scriptTree, scriptTreeFiltered, level = 0, path = { 0: '0' }, addNewNode) => {
  let lastTempObj = {};
  const newScriptTree = JSON.parse(JSON.stringify(scriptTree));
  const tree = scriptTreeFiltered.slice(0, level);
  // TODO: refactor confusing loop function
  for (let i = level; i < scriptTree.length; i++) {
    let tempObj = newScriptTree[i].find(element => {
      const { id } = element.data;
      return id
        ? id.length === 1 || id === path[i] || (!path[i] && lastTempObj.data && id === lastTempObj.data.id + '.1')
        : false;
    });

    if (tempObj) {
      if (tempObj.type === 'ask') {
        const keysMap = { yes: tempObj.data.id + '.1', no: tempObj.data.id + '.2', other: tempObj.data.id + '.3' };
        Object.keys(tempObj.answers).forEach(answer => {
          tempObj.answers[answer].selected = path[tempObj.level + 1]
            ? path[tempObj.level + 1] === keysMap[answer]
            : answer === 'yes'
        })
      }
      tree[i] = tempObj;
      lastTempObj = tempObj;
    } else {
      break;
    }
  }
  const newScriptTreeFiltered = tree.map((e, i, a) => ({ ...e, animated: addNewNode && (i === a.length - 1 || (i === a.length - 2 && e.type === 'ask')) }));
  return {
    scriptTreeFiltered: newScriptTreeFiltered,
    scriptTree: newScriptTree
  };
};

export const createPath = (found, id) => {
  const path = { [found.level]: found.data.id, [found.level + 1]: id };
  let previousId = found.data.id;

  for (let level = found.level - 1; level > 0; level--) {
    const id = previousId.substring(0, previousId.length - 2);
    path[level] = id;
    previousId = id;
  }
  return path;
};

export const setEmptyAnswers = array => {
  const setEmptyAnswersInsideElement = (sameLevelEl, globalArr, globalInd) => {
    const sameLvlId = sameLevelEl.data.id;
    const indexMap = {
      [`${sameLvlId}.1`]: 'yes',
      [`${sameLvlId}.2`]: 'no',
      [`${sameLvlId}.3`]: 'other',
    };
    const answers = { ...sameLevelEl.answers };

    for (let [key, value] of Object.entries(indexMap)) {
      answers[value].empty = !globalArr[globalInd + 1] || !globalArr[globalInd + 1].some(e => e.data.id === key);
    }
    return answers;
  };
  return array.map((globalElement, globalInd, globalArr) => globalElement.map(sameLevelEl => {
    return {
      ...sameLevelEl,
      answers: sameLevelEl.type === 'ask' ? setEmptyAnswersInsideElement(sameLevelEl, globalArr, globalInd) : null
    }
  })
  );
};

export const setDefaultAnswers = answer => ({ ...answer, default: answer.defaultData && answer.noChildren });

export const calcOpenQuestions = arr => arr.reduce((accumulator, current) => {
  const openQuestionsOnLevel = current.reduce((acc, cur) => {
    const defaultAnswersNumber = cur.type === 'ask'
      ? Object.keys(cur.answers).reduce((a, c) => {
        return a + (cur.answers[c].default ? 1 : 0);
      }, 0)
      : 0;
    return acc + defaultAnswersNumber;
  }, 0);
  return accumulator + openQuestionsOnLevel
}, 0);

export const convertReminderToScript = (reminderTask, reminderName) => {
  const { callToAction, category, greeting, interval, occurrences, pronounceTime, salutation } = reminderTask;

  const runRecursive = (sayCommand, iteration, occurrences) => {
    const { greet, sayTime, notify, text } = sayCommand;
    if (iteration < occurrences) {
      sayCommand.then = {
        wait: {
          seconds: interval * 60,
          then: {
            say: { greet, sayTime, notify, text }
          }
        }
      };
      runRecursive(sayCommand.then.wait.then.say, iteration + 1, occurrences)
    }

  };
  const command = {
    say: {
      greet: !!greeting,
      sayTime: !!pronounceTime,
      notify: !!salutation,
      text: callToAction
    }
  };

  occurrences > 1 && runRecursive(command.say, 0, occurrences - 1);

  return {
    category,
    command,
    name: reminderName,
    type: 'reminder',
    id: null,
    templateId: null
  }
};

export const convertQuestionToScript = (questionTask, questionName) => {
  const { category, greeting, pronounceTime, question, salutation } = questionTask;

  const command = {
    ask: {
      greet: !!greeting,
      sayTime: !!pronounceTime,
      notify: !!salutation,
      text: question,
      ...QUESTION_DEFAULTS.answers
    }
  };

  return {
    category: category,
    command,
    name: questionName,
    type: 'question',
    id: null,
    templateId: null
  }
};

export const convertReminderOrQuestion = type => (data, title) => type === 'reminder'
  ? convertReminderToScript(data, title)
  : convertQuestionToScript(data, title);

export const convertScriptFeedbackToArray = feedbackCommand => {
  const scriptFeedbackArray = [];

  let runRecursive = (obj) => {
    const [type, data] = Object.entries(obj)[0];
    const answer = Object.keys(data).find(e => ['yes', 'no', 'other', 'then'].includes(e)) || null;
    const restProps = Object.entries(data).reduce((acc, cur) => (
      ['yes', 'no', 'other', 'then'].includes(cur[0]) ? acc : { ...acc, [cur[0]]: cur[1] }
    ), {});
    const scriptBlock = { type, answer, ...restProps };

    scriptFeedbackArray.push(scriptBlock);
    scriptBlock.answer && Object.keys(data[scriptBlock.answer]).length && runRecursive(data[scriptBlock.answer]);
  };
  runRecursive(feedbackCommand);

  return scriptFeedbackArray;
};

export const convertQuestionFeedbackToArray = questionTask => {
  const { time, question } = questionTask;
  return [{
    type: 'ask',
    answer: question.answer ? { JA: 'yes', NEE: 'no', ANDERS: 'other', NONE: 'other' }[question.answer] : null,
    sayTime: !!question.pronounceTime,
    notify: !!question.salutation,
    greet: !!question.greeting,
    text: question.question,
    executionTime: time
  }]
};

export const convertReminderFeedbackToArray = reminderTask => {
  const { time, reminder } = reminderTask;

  return [{
    type: 'say',
    answer: null,
    sayTime: !!reminder.pronounceTime,
    notify: !!reminder.salutation,
    greet: !!reminder.greeting,
    text: reminder.callToAction,
    executionTime: time
  }]
};

export const convertFutureScriptToArrayDeprecated = command => {
  const [commandType, commandData] = Object.entries(command)[0];
  const { id, yes, no, other, then, ...feedbackCommand } = commandData;

  return [{ ...feedbackCommand, executionTime: null, answer: null, type: commandType }];
};

export const setUniqueScriptName = (names, title) => {
  if (!names.length) {
    return title;
  }
  const runRecursive = (currentTitle, count) => {
    const existingName = names.find(name => name === currentTitle);
    const newCurrentTitle = `${title}(${count})`;
    return existingName ? runRecursive(newCurrentTitle, ++count) : currentTitle;
  };
  return runRecursive(title, 1);
};

/**
 * Used to remember 'yes', 'no', 'other' responses when creating a question script,
 * so that response doesn't reset to 'OK' when switching before saving:
 * @param {scriptTree} array - The current tree.
 * @param {questionState} object - The state of questions being edited.
 */
export const prepareAnswersForQuestion = (scriptTree, questionState) => {
  const question = scriptTree[1][0];
  const answers = question.answers || [];

  const emptyAnswers = Object.keys(answers).filter(key => answers[key].empty);
  const parentNode = {
    ...question, answers: {
      yes: { ...question.answers.yes, empty: emptyAnswers.includes('yes') },
      no: { ...question.answers.no, empty: emptyAnswers.includes('no') },
      other: { ...question.answers.other, empty: emptyAnswers.includes('other') }
    }
  };

  const childNodes = ['yes', 'no', 'other'].map(answer => {
    // use default text if empty
    const say = questionState.answers[answer].say.text
      ? questionState.answers[answer].say
      : QUESTION_DEFAULTS.answers[answer].say
    const response = !emptyAnswers.includes(answer)
      ? scriptTree[2].find(e => e.decision === answer)
      : {
        type: 'say',
        decision: answer,
        level: 2,
        parentId: '1',
        answers: null,
        data: {
          id: `1.${{ yes: 1, no: 2, other: 3 }[answer].say}`,
          ...say
        }
      };

    return response;
  });

  return { parentNode, childNodes };
};

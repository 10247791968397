import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from 'react'
const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto'
        },
        backdropProgress: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: dark => dark ? 'black' : 'white',
            opacity: 0.5
        }
    })
);

export const PopUpLoading = ({ loading, dark }: { loading: boolean, dark?: boolean }) => {
    const classes = useStyles(dark)
    return (
        loading ? <>
            <div className={classes?.backdropProgress} ></div>
            <CircularProgress data-testid="popup-loading" size={100} className={classes?.buttonProgress} />
        </> : null
    )
}
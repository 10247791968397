const QUESTION_DEFAULTS = {
    answers: {
        yes: {
            say: {
                greet: false,
                sayTime: false,
                notify: false,
                text: 'OK',
            }
        },
        no: {
            say: {
                greet: false,
                sayTime: false,
                notify: false,
                text: 'OK',
            }
        },
        other: {
            say: {
                greet: false,
                sayTime: false,
                notify: false,
                text: 'OK',
            }
        }
    }
};

export default QUESTION_DEFAULTS;

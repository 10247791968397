/**
 * @file Http queries for offline notifications
 * @author Max van Loosbroek
 */

import axios from 'axios';
import { RobotContactDto, UpdateNotificationSettingsDto, ContactResponseBody } from './types';
import { getUrls } from '../../../common/constants';

export const getContacts = (robotId: number) => {
    const url = getUrls('clank', 'contacts').replace('{robotId}', robotId);
    return axios.get<ContactResponseBody>(url);
};

export const putContact = (robotId: number, contact: RobotContactDto) => {
    const url = getUrls('clank', 'contacts').replace('{robotId}', robotId);
    return axios.put<ContactResponseBody>(url, contact);
};

export const deleteContact = (robotId: number, robotContactId: number) => {
    const url = getUrls('clank', 'contact').replace('{robotId}', robotId).replace('{robotContactId}', robotContactId);
    return axios.delete(url);
};

export const patchNotificationSettings = ({ robotId, robotContactId, settings }: { robotId: number, robotContactId: number, settings: UpdateNotificationSettingsDto }) => {
    const url = getUrls('clank', 'settings').replace('{robotId}', robotId).replace('{robotContactId}', robotContactId);
    return axios.patch<ContactResponseBody>(url, settings);
}

export const unsubscribeContact = ({ robotId, robotContactId, code }: { robotId: string, robotContactId: string, code: string }) => {
    const url = getUrls('clank', 'unsubscribe').replace('{robotId}', robotId).replace('{robotContactId}', robotContactId).replace('{code}', code);
    return axios.post<ContactResponseBody>(url);
}

export const verifyContact = ({ robotId, robotContactId, code }: { robotId: string, robotContactId: string, code: string }) => {
    const url = getUrls('clank', 'verify').replace('{robotId}', robotId).replace('{robotContactId}', robotContactId).replace('{code}', code);
    return axios.post<ContactResponseBody>(url);
}
import { SET_USER, SESSION_LOGOUT, ENABLE_MFA_SUCCESS, DISABLE_MFA_SUCCESS, ENABLE_MFA_ERROR, DISABLE_MFA_ERROR, USER_CLEAR_ERROR, RESET_ROBOT, RESET_ROBOT_SUCCESS, RESET_ROBOT_ERROR, RESET_ROBOT_SUCCESS_STATUS } from '../constants/actionTypes'
import { EMAIL_CHANGE_UPDATE_EMAIL } from '../../../unconfirmedEmail/redux/constants/actionTypes'
import { UserState } from './types'
import { AnyAction } from 'redux'
import { requestEmailChange, verifyEmailChange } from '../actions'

export const initialState: UserState = {
  status: '',
  userId: null,
  email: '',
  mfaEnabledAt: false,
  error: undefined,
  resetRobotStatus: undefined,
  patching: false,
  awaitingVerification: false
}

const setUser = (state: UserState, action: AnyAction) => ({ ...state, ...action.user })

const sessionLogout = (clearedState: UserState) => clearedState

const emailUpdate = (state: UserState, action: AnyAction) => ({ ...state, email: action.payload, patching: false, error: undefined, awaitingVerification: false })

const setMfaEnabled = (state: UserState, mfaEnabledAt: boolean) => ({ ...state, mfaEnabledAt, error: undefined })

const setError = (state: UserState, error?: any) => ({ ...state, error, patching: false })

export const user = (state: UserState = initialState, action: AnyAction): UserState => {
  switch (action.type) {
    case requestEmailChange.pending.type: {
      return { ...state, patching: true, awaitingVerification: false, error: undefined }
    }
    case requestEmailChange.rejected.type: return setError(state, { ...action.payload, action: requestEmailChange.rejected.type })
    case requestEmailChange.fulfilled.type:{
      return { ...state, patching: false, awaitingVerification: true, error: undefined }
    }
    case verifyEmailChange.pending.type: return { ...state, patching: true, error: undefined }
    case verifyEmailChange.rejected.type: return setError(state, { ...action.payload, action: verifyEmailChange.rejected.type })
    case verifyEmailChange.fulfilled.type: return emailUpdate(state, action)
    case SET_USER: return setUser(state, action)
    case ENABLE_MFA_SUCCESS: return setMfaEnabled(state, true)
    case DISABLE_MFA_SUCCESS: return setMfaEnabled(state, false)
    case RESET_ROBOT: return setError(state, undefined)
    case RESET_ROBOT_SUCCESS: return { ...state, resetRobotStatus: { status: 'pending' } }
    case ENABLE_MFA_ERROR: return setError(state, { ...action.error, action: 'ENABLE_MFA_ERROR' } || 'unknown error')
    case RESET_ROBOT_ERROR: return setError(state, { ...action.error, action: 'RESET_ROBOT_ERROR' } || 'unknown error')
    case DISABLE_MFA_ERROR: return setError(state, { ...action.error, action: 'DISABLE_MFA_ERROR' } || 'unknown error')
    case EMAIL_CHANGE_UPDATE_EMAIL: return emailUpdate(state, action)
    case RESET_ROBOT_SUCCESS_STATUS: return { ...state, resetRobotStatus: action.payload }
    case SESSION_LOGOUT: return sessionLogout(initialState)
    case USER_CLEAR_ERROR: return setError(state, undefined)
    default: return state
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import { useTranslation } from 'react-i18next';

const Banner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
  background-color: #ffffff;
  z-index: 2;
`;

const Inner = styled.div`
	display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  margin: 0 auto;
  @media (min-width: 1025px) {
    width:65%;
  }
  @media (max-width: 530px) {
    padding: 10px 0;
    width: 95%;
  }
  @media (max-width: 330px) {
    padding: 10px 0;
    width: 98%;
  }
`;

const Item = styled.div`
	margin: 8px;
  max-width: 530px;
  @media (max-width: 530px) {
    margin-bottom: 12px;
  }
`;

const Text = styled.p`
	font-family: Montserrat;
  font-weight: normal;
  margin: 0;
`;

const Title = styled(Text).attrs(() => ({as: 'h1'}))`
	font-size: 22px;
  color: #000000;
  @media(max-width: 530px){
    font-size: 18px;
  }
`;

const Message = styled(Text)`
	font-size:16px;
  line-height: 1.13;
  color:#333333;
  @media(max-width: 530px){
    font-size:14px;
  }
`;

const Subtitle = styled.div`
	margin-top: 16px;
  width: 100%;
`;

const Button = styled.button`
	line-height: 1.14;
  text-transform: uppercase;
  height: 36px;
  min-height: 36px;
  background-color: #ffffff;
  font-family: Montserrat;
  font-size:14px;
  outline: none;
  margin-right: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  ${props => props.btnType === 'cancel' && css`
		color: #bebebe;
    border: none;
	`}
  ${props => props.btnType === 'submit' && css`
		border-radius: 4px;
    border: solid 1px #459400;
    color: #459400;
	`}
  
`;
Button.propTypes = {
	btnType: PropTypes.string.isRequired
};

const ButtonLink = styled(Button).attrs(() => ({as: 'a'}))`
	text-decoration: none;
  padding: 8px;
  white-space: nowrap;
  &:hover, &:visited, &:active, &:focus {
  	text-decoration: none;
  	color: #459400;
  }
`;

const UpdateTessaBanner = ({ isRobotOffline, onTheFly, onSubmit, onCancel }) => {
	const { t } = useTranslation();

	return (
		<Banner>
			<Inner>
				<Item>
					<Title>
						{ t('UPDATE_TESSA.TITLE') }
					</Title>
					<Subtitle>
						<Message>
							{ !isRobotOffline && onTheFly
								? t('UPDATE_TESSA.MESSAGE_ONLINE')
								: t('UPDATE_TESSA.MESSAGE_OFFLINE')
							}
						</Message>
					</Subtitle>
				</Item>
				<Item>
					<Button btnType="cancel" onClick={onCancel}>
						{ t('UPDATE_TESSA.BUTTON_CANCEL') }
					</Button>
					{ !isRobotOffline && onTheFly
						? (
							<Button btnType="submit" onClick={onSubmit}>
								{ t('UPDATE_TESSA.BUTTON_SUBMIT_ONLINE') }
							</Button>
						)
						: (
							<ButtonLink
								btnType="submit"
								as="a"
								target="_blank"
								href={t('LINKS.UPDATE')}
								rel="noopener">
								{ t('UPDATE_TESSA.BUTTON_SUBMIT_OFFLINE') }
							</ButtonLink>
						)
					}
				</Item>
			</Inner>
		</Banner>
	)
};

UpdateTessaBanner.propTypes = {
	isRobotOffline: PropTypes.bool.isRequired,
	onTheFly: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

export default UpdateTessaBanner;

import {
  SONGS_SEQUENCE_SET_SELECTED,
  SONGS_SEQUENCE_CHANGE_ORDER,
  SONGS_SEQUENCE_CLEAR,
  PLAYLIST_SAVE_START,
  PLAYLIST_SAVE_SUCCESS,
  PLAYLIST_SAVE_ERROR,
  PLAYLIST_SAVE_HIDE_ERROR
} from '../constants/actionTypes';

export const initialState = {
  done: false,
  songsSequence: [],
  savePlaylistError: false
};

export const playlistSongsSequence = function (state = initialState, action) {
  switch (action.type) {
    case SONGS_SEQUENCE_SET_SELECTED:
      return {
        ...initialState,
        songsSequence: action.payload
          .filter(song => song.selected)
          .sort((a, b) => a.order && b.order
            ? a.order-b.order
            : a.order ? -1 : b.order ? 1 : 0
          ),
        done: true
      };

    case SONGS_SEQUENCE_CHANGE_ORDER:
      const changedSongsSequence = [...state.songsSequence];
      changedSongsSequence.splice(action.payload.end, 0, changedSongsSequence.splice(action.payload.start, 1)[0]);
      return {...state, songsSequence: changedSongsSequence};

    case PLAYLIST_SAVE_START:
      return {...state, done: false};

    case PLAYLIST_SAVE_SUCCESS:
      return {...state, done: true };

    case PLAYLIST_SAVE_ERROR:
      return {...state, done: true, savePlaylistError: true};

    case PLAYLIST_SAVE_HIDE_ERROR:
      return {...state, savePlaylistError: false};

    case SONGS_SEQUENCE_CLEAR:
      return {...initialState};

    default: return state;
  }
};
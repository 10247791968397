import {
	ACTIVATE_CLEAR,
	ACTIVATE_FINISH
} from '../constants/actionTypes';

export const initialState = {
	done: false,
	success: false,
	error: false
};

const activateClear = initialState => initialState;

const activateFinish = (state, action) => ({...state, done: true, ...action.payload});

export const activate = (state = initialState, action) => {
	switch (action.type) {
		case ACTIVATE_CLEAR: return activateClear(initialState);
		case ACTIVATE_FINISH: return activateFinish(state, action);
		default: return state;
	}
};

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'

interface PopUpTextProps {
  type: string,
  children: React.ReactNode
}

const PopUpText = styled('p')<PopUpTextProps>`
  margin: 15px 0;
  font-size: 14px !important;
  line-height: 18px;
  color: ${props => props.theme.colors.darkgrey};
  white-space: pre-line;
  strong {
    font-family: Montserrat-Bold;
    font-size: 16px;
  }
  
  ${props => props.type === 'warning' && css`
    strong {
      color: ${props.theme.colors.warning}
    }
  `}
  
  ${props => props.type === 'info' && css`
    strong {
      color: ${props.theme.colors.lightgreen}
    }
  `}
`;

PopUpText.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default PopUpText;

import { getUrls } from '../constants';
import axios from 'axios';

export const updateRobot = robotId => {
  const url = getUrls('heartbeat', 'update').replace('{robotId}', robotId);

  return axios({
    'method' : 'POST',
    'url' : url,
    'headers': {
      "Content-Type": "application/json;charset=utf-8"
    }
  });
};

export const heartbeatStatus = robotId => {
  const url = getUrls('heartbeat', 'status').replace('{robotId}',robotId);

  return axios({
    'method' : 'GET',
    'url' : url,
    'headers': {
      "Content-Type": "application/json;charset=utf-8"
    }
  });
};
import { Type } from "class-transformer"
import { IsString, IsOptional, IsBoolean, MinLength, MaxLength, IsArray, ValidateNested } from "class-validator"

export interface FormFieldOption {
  display: string
  value: string | null
}

export class Condition {
  @IsString()
  key: string

  @IsString({ each: true })
  matchesValue: string[]
}

export class FormField {
  @IsString()
  key: string

  @IsOptional()
  @IsString()
  displayName?: string

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => Condition)
  conditions?: Condition[]

  @IsOptional()
  @IsBoolean()
  required?: boolean

  @IsOptional()
  @IsString()
  @IsOptional()
  defaultValue?: string | null

  @IsOptional()
  @IsString()
  type?: 'textInput' | 'select'

  @IsOptional()
  @IsArray()
  options?: FormFieldOption[]

  @IsOptional()
  @IsString()
  @MaxLength(2048)
  value: string | null
}
import 'reflect-metadata'
import { IsIn, IsInt, IsOptional, IsString, IsBoolean, IsArray, MinLength, MaxLength } from 'class-validator'

export class BaseNext {
    @IsOptional()
    @IsInt()
    id?: number

    @IsOptional()
    @IsString()
    public tempId?: string

    @IsIn(['then', 'closedQuestion', 'goto', 'multipleChoice'])
    nextType?: 'then' | 'closedQuestion' | 'goto' | 'multipleChoice'

    @IsOptional()
    @IsInt()
    scriptStepId?: number

    @IsOptional()
    @IsInt()
    nextStepId?: number | null

    @IsOptional()
    @IsString()
    scriptStepTempId?: string

    @IsOptional()
    @IsString()
    nextStepTempId?: string
}

export class NextThen extends BaseNext {
    @IsIn(['then'])
    @IsString()
    nextType: 'then'
}

export type IntentionType = 'command' | 'other' | 'offline'

export class NextMultipleChoiceBase extends BaseNext {
    @IsIn(['multipleChoice'])
    @IsString()
    nextType: 'multipleChoice'

    @IsString()
    @IsIn(['command', 'other', 'offline'])
    intentionType: IntentionType
}

export class NextMultipleChoiceStandard extends BaseNext {
    @IsIn(['multipleChoice'])
    @IsString()
    nextType: 'multipleChoice'

    @IsString()
    @IsIn(['other', 'offline'])
    intentionType: 'other' | 'offline'
}

export type CommandIds = number | 'primary'

export type SimilarCommands = {
    [commandId in CommandIds]: {
        commands: string[]
        command: string
    } | undefined
}

export class NextMultipleChoiceCommand extends NextMultipleChoiceBase {
    @IsString()
    @IsIn(['command'])
    intentionType: 'command'
        
    @IsArray()
    @MaxLength(64, {each: true})
    @IsString({each: true})
    secondaryCommands: string[]

    @MinLength(1)
    @MaxLength(64)
    @IsString()
    primaryCommand: string

    similarCommands?: SimilarCommands
}

export class NextClosedQuestion extends BaseNext {
    @IsIn(['closedQuestion'])
    @IsString()
    nextType: 'closedQuestion'

    @IsOptional()
    @IsString()
    @IsIn(['yes', 'no', 'other'])
    answer: 'yes' | 'no' | 'other'
}

export class NextGoTo extends BaseNext {
    @IsIn(['goto'])
    @IsString()
    nextType: 'goto'

    @IsBoolean()
    maxReached: boolean
}

export type ScriptNext = NextThen | NextClosedQuestion | NextGoTo | NextMultipleChoiceCommand | NextMultipleChoiceStandard
export type ScriptStoreNext = ScriptNext & { tempId: string }
import axios, { AxiosError } from 'axios'
import { getUrls } from '../../../common/constants'
import { FullScriptGet, ScriptVersion, FullScriptPut } from '../tessaScript/types/ScriptVersion'
import { ScriptExecutionGet } from '../tessaScript/types/ScriptExecution'
import { TessaScript, TessaScriptDenormalized } from '../tessaScript/types/DeprecatedScript'
import {
  NextMultipleChoiceCommand} from '../tessaScript/types/Next'
import { Report } from '../tessaScript/types/Report'
import { REPORTS_MOCK } from '../tessaScript/constants/reportsMock'

// New script format

export const fetchScripts = (robotId: number) => {
  const url = getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId)
  return axios.get<ScriptVersion[]>(url)
}

export const fetchTemplateScripts = (robotId: number) => {
  const url = getUrls('micro_manager', 'scripts-v3-templates').replace('{robotId}', robotId)
  return axios.get<ScriptVersion[]>(url)
}

export const fetchScriptSpeechInteractions = (robotId: number) => {
  const url = getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId)
  return axios.get<ScriptVersion[]>(url)
}

export const fetchScript = (robotId: number | string, scriptReferenceId: number | string) => {
  const url = getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId) + '/' + scriptReferenceId
  return axios.get<FullScriptGet>(url)
}

export const fetchOrgScript = (robotId: number, scriptReferenceId: number) => {
  const url = getUrls('micro_manager', 'scripts-v3-templates').replace('{robotId}', robotId) + '/' + scriptReferenceId
  return axios.get<FullScriptGet>(url)
}

export const fetchScriptVersion = (robotId: number | string, scriptReferenceId: number | string, scriptVersionId: number | string) => {
  const url =
    getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId) +
    '/' +
    scriptReferenceId +
    '/version/' +
    scriptVersionId
  return axios.get<FullScriptGet>(url)
}

export const archiveScript = (robotId: number, scriptReferenceId: number) => {
  const url = getUrls('micro_manager', 'scripts').replace('{robotId}', robotId) + '/' + scriptReferenceId + '/archive'
  return axios.patch(url)
}

export const putScript = (robotId: number, script: FullScriptPut) => {
  const url = getUrls('micro_manager', 'scripts-v3').replace('{robotId}', robotId)
  return axios.put<FullScriptGet>(url, script)
}

export const checkMultipleChoiceCommandSimilarity = ({
  robotId,
  command,
  otherNexts
}: {
  robotId: number
  command: string
  otherNexts: NextMultipleChoiceCommand[]
}) => {
  const url = getUrls('micro_manager', 'command-similarity').replace('{robotId}', robotId)
  return axios.post<{
    valid: boolean
    commands: string[]
    message: string
  }>(url, {
    command,
    otherNexts
  })
}

export const fetchScriptExecution = (robotId: number, planned: Date, scriptReferenceId: number, scheduleId: number) => {
  const url =
    getUrls('micro_manager', 'scripts').replace('{robotId}', robotId) +
    '/' +
    scriptReferenceId +
    '/executions/' +
    scheduleId
  return axios.get<ScriptExecutionGet>(url, {
    params: {
      planned: planned.toISOString()
    }
  })
}

export const fetchReports = async (robotId: number): Promise<Report[]> => {
  try {
    const url = getUrls('robby', 'reports').replace('{robotId}', robotId)
    const { data: reports } = await axios.get<Report[]>(url)
    return reports
  } catch (error) {
    return []
  }
}

// Deprecated (old script format)

interface ScriptsDeprecated {
  scripts: TessaScript[]
}

export const fetchScriptsDeprecated = (robotId: number) => {
  const url = getUrls('micro_manager', 'mscripts-deprecated').replace('{robotId}', robotId)
  const scripstUrl = url.replace('{type}', 'script')
  const reminderUrl = url.replace('{type}', 'reminder')
  const questionUrl = url.replace('{type}', 'question')
  return Promise.all([
    axios.get<ScriptsDeprecated>(scripstUrl),
    axios.get<ScriptsDeprecated>(reminderUrl),
    axios.get<ScriptsDeprecated>(questionUrl)
  ]).then(([scripts, reminders, questions]) => {
    return [...scripts.data.scripts, ...reminders.data.scripts, ...questions.data.scripts]
  })
}

export const fetchScriptDeprecated = (robotId: number | string, scriptId: number | string) => {
  const url = getUrls('micro_manager', 'script-deprecated')
    .replace('{robotId}', robotId)
    .replace('{scriptId}', scriptId)
  return axios.get<TessaScriptDenormalized>(url)
}

export const deleteScriptDeprecated = (robotId: number, scriptId: number) => {
  const url = getUrls('micro_manager', 'script-deprecated')
    .replace('{robotId}', robotId)
    .replace('{scriptId}', scriptId)
  return axios.delete(url)
}

export const addScriptDeprecated = (robotId: number, script: TessaScriptDenormalized) => {
  const url = getUrls('micro_manager', 'scripts-deprecated').replace('{robotId}', robotId)
  return axios.put(url, script)
}

export const fetchScriptDeprecatedFeedback = (robotId: number, scriptId: number, data: any) => {
  const url = getUrls('micro_manager', 'script-deprecated')
    .replace('{robotId}', robotId)
    .replace('{scriptId}', scriptId)
  return axios.post(url, data)
}

interface OldNewTaskIdMapping {
  oldTaskId: number
  newTaskId: number
}
interface OldNewScriptIdMapping {
  scriptV1Id?: number
  scriptReferenceId?: number
  tasks?: OldNewTaskIdMapping[]
}
type ConversionResponse = OldNewScriptIdMapping[]

export const convertScripts = async (robotId: number | string, capabilities: string[], timeout = 500) => {
  if (!capabilities.includes('scriptExecution-v2')) {
    console.info('Not converting scripts for outdated robot')
    return
  }
  const url = getUrls('micro_manager', 'convert-scripts').replace('{robotId}', robotId)
  const convert = () => axios.post<ConversionResponse>(url)
  const retryIfBusy = async (err: any | AxiosError) => {
    const errorMessage = (err as AxiosError)?.response?.data?.message as string
    if (errorMessage.toLowerCase().includes('conversion already started')) {
      await new Promise(resolve => setTimeout(resolve, timeout))
      return convert()
    }
    throw err
  }
  return convert().catch(retryIfBusy).catch(retryIfBusy).catch(retryIfBusy)
}

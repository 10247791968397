/* eslint-disable react-hooks/exhaustive-deps */

import * as serviceWorker from './serviceWorker'
import React, { useState, useEffect, Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getTime, setLang } from './common/redux/actions'
import { createSession } from './features/auth/redux/actions'
import { getEnvConfig } from './common/constants'
import { setWindowTitle } from './common/utils'
import PrivateRoute from './common/hoc/PrivateRoute/PrivateRoute'
import NotAuthenticatedRoute from './common/hoc/NotAuthenticatedRoute/NotAuthenticatedRoute'
import MainWrapper from './common/components/MainWrapper/MainWrapper'
import VersionWarning from './common/components/VersionWarning/VersionWarning'
import { PopUpProvider } from './common/components/PopUp/PopUp'
import PopUp from './common/components/PopUp/PopUp'
import Spinner from './common/components/Spinner/Spinner'
import Music from './features/music/Music'
import SignUp from './features/auth/signup/SignUp'
import VerifyRobotContact from './features/offlineNotifications/VerifyRobotContact/VerifyRobotContact'
import UnsubscribeRobotContact from './features/offlineNotifications/UnsubscribeRobotContact/UnsubscribeRobotContact'
import { appNotificationsSelector, fetchAppNotifications } from './features/appNotifications/redux/reducer'
import AppNotificationPopUp from './features/appNotifications/AppNotificationPopUp'

const ScriptSingle = lazy(() => import('./features/mScripts/tessaScript/ScriptSinglePage'))
const ScriptsOverview = lazy(() => import('./features/mScripts/tessaScriptOverview/ScriptsOverview'))
const Task = lazy(() => import('./features/task/Task'))
const Overview = lazy(() => import('./features/overview/Overview'))
const DirectSpeech = lazy(() => import('./features/directSpeech/DirectSpeech'))
const Error = lazy(() => import('./features/error/Error'))
const Settings = lazy(() => import('./features/settings/Settings'))
const EmailChange = lazy(() => import('./features/unconfirmedEmail/UnconfirmedEmailChange'))
const EmailChangeSuccess = lazy(() => import('./features/unconfirmedEmail/UnconfirmedEmailChangeSuccess/UnconfirmedEmailChangeSuccess'))
const Pairing = lazy(() => import('./features/pairing/Pairing'))
const PairingSuccess = lazy(() => import('./features/pairing/PairingSuccess/PairingSuccess'))
const PairingError = lazy(() => import('./features/pairing/PairingError/PairingError'))
const AccountActivation = lazy(() => import('./features/activate/AccountActivation'))
const Landing = lazy(() => import('./features/auth/landing/Landing'))
const Login = lazy(() => import('./features/auth/login/Login'))
const MFA = lazy(() => import('./features/auth/mfa/MFA'))
const ForgotPassword = lazy(() => import('./features/auth/forgotPassword/ForgotPassword'))
const ForgotPasswordSuccess = lazy(() => import('./features/auth/forgotPassword/ForgotPasswordSuccess/ForgotPasswordSuccess'))
const ResetPassword = lazy(() => import('./features/auth/resetPassword/ResetPassword'))
const ResetPasswordSuccess = lazy(() => import('./features/auth/resetPassword/ResetPasswordSuccess/ResetPasswordSuccess'))
const DeleteUserAccountSuccess = lazy(() => import('./features/generalSettings/UserProfile/DeleteUserAccount/DeleteUserAccountSuccess/DeleteUserAccountSuccess'))
const RobotSettingsPage = lazy(() => import('./features/robotSettings/RobotSettings'))
const GeneralSettings = lazy(() => import('./features/generalSettings/GeneralSettings'))
const InformationPage = lazy(() => import('./features/information/InformationPage'))
const GeneralSettingsMFA = lazy(() => import('./features/generalSettings/GeneralSettingsMFA'))
const UserProfilePage = lazy(() => import('./features/generalSettings/UserProfile/UserProfilePage'))
const Invite = lazy(() => import('./features/invite/Invite'))
const AcceptInvite = lazy(() => import('./features/invite/AcceptInvite'))
const SpeechInteractionSingle = lazy(() => import('./features/speechInteraction/SpeechInteractionSingle/SpeechInteractionSingle'))
const SpeechInteractionOverview = lazy(() => import('./features/speechInteraction/SpeechInteractionOverview/SpeechInteractionOverview'))

const App = ({
  getTimeAction,
  createSessionAction,
  setLangAction,
  appNotifications,
  fetchAppNotifications,
  session,
  robots
}) => {
  const { t } = useTranslation();
  const onProduction = getEnvConfig().environmentType === 'production';
  const [showPWAUpdated, setShowPWAUpdated] = useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);
  const [appInitialized, setAppInitialized] = useState(false);

  const onUpdate = (registration) => {
    setWaitingWorker(registration.waiting);
    setShowPWAUpdated(true);
  }

  const update = () => {
    setShowPWAUpdated(false)
    if (waitingWorker) {
      waitingWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
      waitingWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }

  useEffect(() => {
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({ onUpdate })
    const init = async () => {
      const { name, version } = getEnvConfig()
      document.title = setWindowTitle(name, version)

      const session = JSON.parse(window.localStorage.getItem('Tinybots.session'))
      session && createSessionAction(session)

      const lang = JSON.parse(window.localStorage.getItem('Tinybots.lang')) ?? 'nl'
      setLangAction(lang)

      await getTimeAction()

      setAppInitialized(true)
    }
    init()
  }, [])

  useEffect(() => {
    session.isAuthenticated && fetchAppNotifications()
  }, [session])

  return (
    <PopUpProvider>
      {showPWAUpdated && (
        <PopUp
          type='warning'
          title={t('POPUP.UPDATE.TITLE')}
          text={t('POPUP.UPDATE.MESSAGE')}
          cancelButton={t('POPUP.UPDATE.BUTTON_CANCEL')}
          onCancel={() => setShowPWAUpdated(false)}
          submitButton={t('POPUP.UPDATE.BUTTON_SUBMIT')}
          onSubmit={() => update()}
        />
      )}
      <MainWrapper>
        {!appInitialized && <Spinner />}
        {!onProduction && (
          <VersionWarning>
            <span>{t('VERSION_WARNING.MESSAGE1')}</span>
            <a
              href='https://my.tinybots.io'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span>
                &nbsp;
                {t('VERSION_WARNING.MESSAGE2')}
              </span>
            </a>
          </VersionWarning>
        )}
        {appNotifications.show && appNotifications.notifications.length ? (
          <AppNotificationPopUp />
        ) : null}
        {appInitialized && (
          <Suspense fallback={<Spinner />}>
            <Switch>
              <NotAuthenticatedRoute exact path='/' component={Landing} />
              <NotAuthenticatedRoute exact path='/login' component={Login} />
              <NotAuthenticatedRoute
                exact
                path='/login/invite/:inviteCode/:email'
                component={Login}
              />
              <NotAuthenticatedRoute exact path='/mfa' component={MFA} />
              <NotAuthenticatedRoute
                exact
                path='/mfa/invite/:inviteCode/:email'
                component={MFA}
              />
              <NotAuthenticatedRoute
                path='/signup/invite/:inviteCode/:email'
                component={SignUp}
              />
              <NotAuthenticatedRoute path='/signup' component={SignUp} />
              <PrivateRoute
                exact
                path='/overview'
                requiresRobot
                component={Overview}
              />
              <PrivateRoute
                exact
                path='/overview/:inviteStatus?'
                requiresRobot
                component={Overview}
              />
              <PrivateRoute
                exact
                path='/invite-success'
                requiresRobot
                component={Overview}
              />
              <PrivateRoute path='/music' requiresRobot component={Music} />
              <PrivateRoute
                exact
                requiresRobot
                path="/task/:type(script)/:v2(v2)/:id?/:taskId?/:time?/:editSequence?"
                component={Task}
              />
              <PrivateRoute
                exact
                path='/robot-settings'
                requiresRobot
                component={RobotSettingsPage}
              />
              <PrivateRoute
                exact
                requiresRobot
                path="/task/:type(music|script|reminder|question)/:id?/:taskId?/:time?"
                component={Task}
              />
              <PrivateRoute
                exact
                path="/script/overview/:id?"
                requiresRobot
                component={ScriptsOverview}
              />
              <PrivateRoute
                exact
                path="/:purpose(speech-interaction)/link-script/:id?"
                requiresRobot
                component={ScriptsOverview}
              />
              <PrivateRoute
                exact
                path="/:purpose(speech-interaction)/new-script/:v2(v2)/template/:id?"
                requiresRobot
                component={ScriptSingle}
              />
              <PrivateRoute
                exact
                path="/:type(script)/template/:id?"
                requiresRobot
                component={ScriptSingle}
              />
              <PrivateRoute
                exact
                path="/script/:v2(v2)/template/:id?"
                requiresRobot
                component={ScriptSingle}
              />
              <PrivateRoute
                exact
                path='/pairing'
                requiresRobot={robots.length > 0}
                component={Pairing}
              />
              <PrivateRoute
                exact
                requiresRobot={robots.length > 0}
                path='/pairing/success'
                component={PairingSuccess}
              />
              <PrivateRoute
                exact
                requiresRobot={robots.length > 0}
                path='/pairing/error'
                component={PairingError}
              />
              <PrivateRoute
                exact
                path='/direct'
                requiresRobot
                component={DirectSpeech}
              />
              <PrivateRoute
                exact
                path='/settings'
                requiresRobot
                component={Settings}
              />
              <PrivateRoute
                exact
                path='/general-settings'
                requiresRobot
                component={GeneralSettings}
              />
              <PrivateRoute
                exact
                path='/information'
                requiresRobot
                component={InformationPage}
              />
              <PrivateRoute
                exact
                path='/general-settings/user-profile'
                requiresRobot={robots.length > 0}
                component={UserProfilePage}
              />
              <PrivateRoute
                exact
                path='/general-settings/mfa'
                requiresRobot
                component={GeneralSettingsMFA}
              />
              <PrivateRoute
                exact
                path='/email/change'
                component={EmailChange}
              />
              <PrivateRoute
                exact
                path='/email/success'
                component={EmailChangeSuccess}
              />
              <PrivateRoute
                exact
                path='/accept-invite/:inviteCode/:email'
                component={AcceptInvite}
              />
              <PrivateRoute
                exact
                path='/speech-interactions'
                requiresRobot
                component={SpeechInteractionOverview}
              />
              <PrivateRoute
                exact
                path='/speech-interactions/new'
                requiresRobot
                component={SpeechInteractionSingle}
              />
              <PrivateRoute
                exact
                path='/speech-interactions/edit/:id'
                requiresRobot
                component={SpeechInteractionSingle}
              />
              <Route exact path='/error' component={Error} />
              <Route
                exact
                path='/invite/:inviteCode/:email'
                component={Invite}
              />
              <Route
                exact
                path='/offline-notifications/email-verification'
                component={VerifyRobotContact}
              />
              <Route
                exact
                path='/offline-notifications/email-unsubscribe'
                component={UnsubscribeRobotContact}
              />
              <Route
                exact
                path='/activeren/:key'
                component={AccountActivation}
              />
              <Route exact path='/forgot-password' component={ForgotPassword} />
              <Route
                exact
                path='/delete-account/success'
                component={DeleteUserAccountSuccess}
              />
              <Route
                exact
                path='/forgot-password/success'
                component={ForgotPasswordSuccess}
              />
              <Route
                exact
                path='/reset-wachtwoord/success'
                component={ResetPasswordSuccess}
              />
              <Route
                exact
                path='/reset-wachtwoord/:key?'
                component={ResetPassword}
              />
              <Redirect to='/' />
            </Switch>
          </Suspense>
        )}
      </MainWrapper>
    </PopUpProvider>
  )
}

App.propTypes = {}

const mapDispatchToProps = {
  getTimeAction: getTime,
  createSessionAction: createSession,
  setLangAction: setLang,
  fetchAppNotifications
}

const mapStateToProps = ({ appNotifications, robots, session }) => ({
  robots,
  session,
  appNotifications: {
    ...appNotifications,
    notifications: appNotificationsSelector(appNotifications)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App)

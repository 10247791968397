import {
  FILES_UPLOAD_UPDATE_FINISH,
  FILES_UPLOAD_UPDATE_STATUS,
  FILES_UPLOAD_UPDATE_ERROR,
  FILES_UPLOAD_HIDE_ERROR,
  FILES_UPLOAD_START,
  FILES_UPLOAD_MAX_QUEUE,
  FILES_FREE_SPACE_GET,
  FILES_UPLOAD_UPDATE_RESET
} from '../constants/actionTypes';

export const initialState = {
  musicUploadError: false,
  maxQueueError: false,
  submitted: false,
  completed: false,
  uploaded: 0,
  freeSpaceByte: 0,
  totalSpaceByte: 0
};

export const musicUpload = function (state = initialState, action) {
  switch (action.type) {
    case FILES_UPLOAD_MAX_QUEUE:
      return { ...state, maxQueueError: true };

    case FILES_FREE_SPACE_GET:
      return { ...state, freeSpaceByte: action.payload.freeSpaceByte, totalSpaceByte: action.payload.totalSpaceByte };

    case FILES_UPLOAD_START:
      return { ...state, submitted: true };

    case FILES_UPLOAD_UPDATE_STATUS:
      return { ...state, uploaded: action.payload };

    case FILES_UPLOAD_UPDATE_ERROR:
      return { ...state, musicUploadError: true, completed: true };

    case FILES_UPLOAD_UPDATE_FINISH:
      return { ...state, completed: true, files: [] };

    case FILES_UPLOAD_UPDATE_RESET:
      return { ...state, submitted: false, completed: false, files: [] };

    case FILES_UPLOAD_HIDE_ERROR:
      return { ...state, musicUploadError: false };

    default: return state;
  }
};
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'

interface PopUpTitleProps {
  type: string,
  children: React.ReactNode
}

const PopUpTitle = styled('h3')<PopUpTitleProps>`
  margin: 0;
  font-size: 20px;
  color: ${props => props.theme.colors?.darkgrey};
  
  strong { font-family: Montserrat-Bold; }
  
  ${props => props.type === 'warning' && css`
    color: ${props.theme.colors.warning};
    strong { 
      color: ${props.theme.colors.warning}; 
    }
  `}
`;

PopUpTitle.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default PopUpTitle;
/**
 * @file Reducer for the state of the permissions
 * @author Max van Loosbroek
 */

import { createSlice, createEntityAdapter, createAction } from '@reduxjs/toolkit'
import { Permission, PermissionId, PermissionsState } from './types'
import { fetchMCQScripts, fetchPermissions, savePermissionState } from './thunks'
import { ScriptVersion } from '../../mScripts/tessaScript/types/ScriptVersion'

export const permissionsAdapter = createEntityAdapter<Permission>({ selectId: entity => entity.permission })
export const permissionsOriginalAdapter = createEntityAdapter<Permission>({ selectId: entity => entity.permission })

export const initialStatePermissions: PermissionsState = {
  getting: true,
  getError: false,
  patching: false,
  patchError: false,
  mcqScripts: [],
  permissions: permissionsAdapter.getInitialState(),
  permissionsOriginal: permissionsAdapter.getInitialState()
}

export const togglePermission =
  createAction<{ permission: PermissionId; enabled: boolean }>('permissions/togglePermission')

export const createPermissionsSlice = (initialState: PermissionsState) => createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearError: state => ({ ...state, getError: false, patchError: false })
  },
  extraReducers: builder => {
    builder.addCase(togglePermission, ({ permissions }, { payload: { permission, enabled } }) => {
      permissionsAdapter.updateOne(permissions, {
        id: permission,
        changes: {
          enabledAt: enabled
        }
      })
    })
    builder.addCase(fetchPermissions.pending, state => ({
      ...state,
      getting: true,
      getError: false
    }))
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      permissionsAdapter.setAll(state.permissions, action.payload)
      permissionsOriginalAdapter.setAll(state.permissionsOriginal, action.payload)
      state.getting = false
      state.getError = false
    })
    builder.addCase(fetchPermissions.rejected, state => ({
      ...state,
      getting: false,
      getError: true
    }))
    builder.addCase(savePermissionState.pending, state => ({
      ...state,
      patching: true,
      patchError: false
    }))
    builder.addCase(fetchMCQScripts.pending, state => ({
      ...state,
      getting: true,
      getError: false
    }))
    builder.addCase(fetchMCQScripts.rejected, state => ({
      ...state,
      getting: false,
      getError: true
    }))
    builder.addCase(fetchMCQScripts.fulfilled, (state, action) => {
      state.mcqScripts = action.payload
      state.getting = false
      state.getError = false
    })
    builder.addCase(savePermissionState.fulfilled, (state, action) => {
      if (action.payload) {
        permissionsAdapter.setAll(state.permissions, action.payload)
        permissionsOriginalAdapter.setAll(state.permissionsOriginal, action.payload)
      }
      state.patching = false
      state.patchError = false
    })
    builder.addCase(savePermissionState.rejected, state => ({
      ...state,
      patching: false,
      patchError: true
    }))
  }
})

const permissionsSlice = createPermissionsSlice(initialStatePermissions)

// Extract the action creators object and the reducer
const { reducer } = permissionsSlice

export const permissions = reducer
export const { clearError } = permissionsSlice.actions

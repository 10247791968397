import { AnyAction, EntityState, ThunkDispatch, createAsyncThunk, unwrapResult } from "@reduxjs/toolkit"
import { RootState } from "../../../../root.reducer"
import { convertScripts, fetchScript, fetchOrgScript, putScript, fetchScripts, fetchScriptExecution, checkMultipleChoiceCommandSimilarity, fetchReports } from "../../api"
import { prepareScriptState, getFullScriptPut } from "../helpers/helpers"
import { NextMultipleChoiceCommand, ScriptStoreNext } from "../types/Next"
import { getTaskScript } from "../../../task/redux/thunks/getTaskScript"

export const fetchTessaScript = createAsyncThunk(
  'tessaScript/fetchTessaScript',
  async (
    {
      robotId,
      scriptId,
      v2,
      history,
      capabilities,
      templateGroup
    }: {
      robotId: number
      scriptId: number
      v2?: boolean
      history: any
      capabilities: string[]
      templateGroup?: string
    },
    { dispatch }
  ) => {
    const dispatchResult = await dispatch(getReports({ robotId }))
    const reports = unwrapResult(dispatchResult)
    if (v2 === false) {
      let converted
      try {
        const res = await convertScripts(robotId, capabilities)
        if (res === undefined) {
          const response = await fetchScript(robotId, scriptId)
          return prepareScriptState(response.data as any, reports, capabilities)
        }
        converted = res?.data
      } catch (e) {
        throw Error('Conversion error')
      }
      const convertedScript =
        converted &&
        converted.find(c => {
          return c.scriptV1Id?.toString() === scriptId.toString()
        })
      if (convertedScript) {
        history.push('/script/v2/template/' + convertedScript.scriptReferenceId)
      } else {
        throw Error('no script found')
      }
    } else {
      const response = await (templateGroup ? fetchOrgScript(robotId, scriptId) : fetchScript(robotId, scriptId))
      return prepareScriptState(response.data as any, reports, capabilities, templateGroup)
    }
  }
)
export const getReports = createAsyncThunk(
  'tessaScript/getReports',
  async (
    {
      robotId
    }: {
      robotId: number
    }
  ) => {
    return fetchReports(robotId)
  }
)
export const putTessaScript = createAsyncThunk(
  'tessaScript/putTessaScript',
  async (
    { robotId, newScript, capabilities }: { robotId: number; newScript: boolean, capabilities: string[] },
    thunkAPI
  ) => {
    const state = (thunkAPI.getState() as RootState).tessaScript
    const fullScript = getFullScriptPut(state, newScript)
    try {
      const { data } = await putScript(robotId, fullScript as any)
      return prepareScriptState(data as any, [], capabilities)
    } catch (e) {
      const permissionError = (e as any).response.status === 403 && (e as any)?.response?.data?.message.includes('permissions')
      if (permissionError) {
        throw new Error('Missing permissions')
      } else {
        throw e
      }
    }
  }
)
export const getScriptNames = createAsyncThunk(
  'tessaScript/getScriptVersions',
  async ({ robotId }: { robotId: number }) => {
    const response = await fetchScripts(robotId)
    return response.data
  }
)
const updateInvalidCommands = (
    next: EntityState<ScriptStoreNext>,
    currentBranch: ScriptStoreNext,
    commandId: number | 'primary',
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
  next.ids
    .map(id => next.entities[id]! as NextMultipleChoiceCommand)
    .filter(n =>
      n?.scriptStepTempId === currentBranch.scriptStepTempId
      && n?.intentionType === 'command'
    )
    .map(n => {
      const keys = (n.similarCommands && Object.keys(n.similarCommands)) ?? []
      for (const key of keys) {
        const value = n.similarCommands?.[key as any]
        // if not the same command as current and it is invalid
        // object keys are converted to strings, so we use a weakly typed equality check here
        if(value && (key != commandId || n.tempId !== currentBranch.tempId)) {
          dispatch(checkMultipleChoiceCommand({
            command: value.command,
            currentBranch: n as any,
            commandId: key as number | 'primary',
            checkOthers: false
          }))
        }
      }
    })
}
export const checkMultipleChoiceCommand = createAsyncThunk(
  'tessaScript/checkMultipleChoiceCommand',
  async ({ command, currentBranch, commandId, checkOthers } : {
    command: string,
    checkOthers: boolean,
    currentBranch: NextMultipleChoiceCommand & { tempId: string },
    commandId: number | 'primary'
  }, { getState, dispatch }) => {
    if (!command) {
      return { tempId: currentBranch.tempId, commandId };
    }
    const state = (getState() as RootState)
    const next = state.tessaScript.scriptNext
    const otherNexts = next.ids
      .map(id => next.entities[id]! as NextMultipleChoiceCommand)
      .filter(n =>
        n?.tempId !== currentBranch.tempId
        && n?.scriptStepTempId === currentBranch.scriptStepTempId
        && n?.intentionType === 'command')
      .map(({ scriptStepTempId, intentionType, primaryCommand, secondaryCommands, nextStepTempId, nextType }) => ({
        scriptStepTempId,
        intentionType,
        primaryCommand,
        secondaryCommands,
        nextStepTempId,
        nextType
      } as NextMultipleChoiceCommand))
    const robotId = state.robots?.[0]?.id
    const { data } = await checkMultipleChoiceCommandSimilarity({
      robotId,
      command,
      otherNexts
    })
    if(checkOthers) {
      updateInvalidCommands(next, currentBranch, commandId, dispatch)
    }
    return { ...data, commandId, tempId: currentBranch.tempId, command }
  }
)
export const getScriptExecution = createAsyncThunk(
  'tessaScript/getScriptExecution',
  async (
    {
      robotId,
      planned,
      scriptReferenceId,
      scheduleId
    }: {
      robotId: number
      planned: Date
      scriptReferenceId: number
      scheduleId: number
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as any
    const versionId = state?.tessaScript?.script?.id
    const { data } = await fetchScriptExecution(
      robotId,
      planned,
      scriptReferenceId,
      scheduleId
    )
    const executionVersionId = data.scriptExecution.scriptVersionId
    if (executionVersionId !== versionId)
      await thunkApi.dispatch(
        getTaskScript({robotId, scriptId: scriptReferenceId, v1: false, scriptVersionId: executionVersionId})
      )
    return data
  }
)
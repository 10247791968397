import styled from 'styled-components';

const Button = styled.button`
	display: inline-block;
  padding: 8px 22px;
  text-align: center;
  vertical-align: center;
  height: 36px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: Montserrat;
  color: ${props => props.theme.colors.cremewhite};
  font-size: 16px;
  line-height: 16px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.darkgreen};
  background-color: ${props => props.theme.colors.darkgreen};
  &:hover {
  	border: 1px solid ${props => props.theme.colors.lightgreen};
  	background-color: ${props => props.theme.colors.lightgreen};
  }
  &[disabled]{
  	border:0;
  	cursor: not-allowed;
  	background-color: ${props => props.theme.colors.darkgreen40};
  }
  @media(max-width: 450px) {
  	font-size: 14px;
  }
`;

export default Button;

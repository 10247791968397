/**
 * @file Functions needed in the UI for scripts, fixing html functionality
 */

export const sanitizeHTML = (text: string) => {
  const htmlRegex = /<[^>]+>/g
  // remove html elements
  const textSanitized =
    text &&
    text
      .replace(htmlRegex, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/&hairsp;/g, '')
  return textSanitized
}

export const sanitizePartially = (text: string) => {
  let textSanitized = text
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/<\/?div[^>]*>/g, '')
    .replace(/<\/?ul[^>]*>/g, '')
    .replace(/<\/?li[^>]*>/g, '')
    .replace(/<\/?section[^>]*>/g, '')
    .replace(/<\/?b[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&hairsp;/g, ' ')
  return textSanitized
}

export const getCaretCharacterOffsetWithin = (element: HTMLElement) => {
  var start = 0
  var end = 0
  var doc: Document = element.ownerDocument || (element as any).document
  var win = doc.defaultView || ((doc as any).parentWindow as Window)
  var sel
  if (typeof win.getSelection != 'undefined') {
    sel = win.getSelection()
    if (sel && sel.rangeCount > 0) {
      var range = (win as Window).getSelection()?.getRangeAt(0)
      if (!range) {
        return
      }
      var preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.startContainer, range.startOffset)
      start = preCaretRange.toString().length
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      end = preCaretRange.toString().length
    }
  } else if ((sel = (doc as any).selection) && sel.type !== 'Control') {
    var textRange = sel.createRange()
    var preCaretTextRange = (doc.body as any).createTextRange()
    preCaretTextRange.moveToElementText(element)
    preCaretTextRange.setEndPoint('EndToStart', textRange)
    start = preCaretTextRange.text.length
    preCaretTextRange.setEndPoint('EndToEnd', textRange)
    end = preCaretTextRange.text.length
  }
  return { start, end }
}

export const sanitizeKeypress = (event: React.KeyboardEvent) => {
  const code = event.keyCode || event.charCode
  if (code === 13) {
    event.preventDefault()
  }
}

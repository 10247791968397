import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Backdrop from '../Backdrop/Backdrop';
import accountIcon from '../../../assets/images/logo/tessa_creme.svg';
import directSpeechIcon from '../../../assets/images/icon/directspeech.svg';
import musicIcon from '../../../assets/images/icon/fa-music.svg';
import feedbackIcon from '../../../assets/images/icon/feedback.svg';
import informationIcon from '../../../assets/images/icon/information-light.svg';
import unreadNotificationIcon from '../../../assets/images/icon/tb_app_icon_unread.svg';
import helpIcon from '../../../assets/images/icon/help.svg';
import settingsIcon from '../../../assets/images/icon/settings.svg';
import exitIcon from '../../../assets/images/icon/exit.svg';
import userIcon from '../../../assets/images/icon/user-white.svg';

const MenuWrapper = styled.ul`
	position: absolute;
	top: 56px;
	right:0;
	width: 297px;
	min-width: 245px;
	margin: 0;
	padding: 0;
	background-color: ${props => props.theme.colors?.darkgrey};
	border: 1px solid ${props => props.theme.colors?.cremewhite20};
	border-radius: 0;
	user-select: none;
	z-index: 4;
	::-moz-selection { background: transparent; }
		@media(min-height: 0px) and (max-height: 620px){
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	@media(min-height:551px) and (max-height:620px) {
		max-height: 500px;
	}
	@media(min-height:451px) and (max-height:550px) {
		max-height: 400px;
	}
	@media(min-height:0px) and (max-height:450px) {
		max-height: 300px;
	}
	@media (max-width : 575px) {
		width: 260px;
	}
	&:hover {
		cursor: default;
	}
`;

const Item = styled.li`
	list-style-type: none;
	border-bottom: 1px solid ${props => props.theme.colors?.lightgrey};
	&:last-of-type {
		border-bottom: 0;
	}
`;

const Section = styled.div`
	list-style-type: none;
	border-bottom: 1px solid ${props => props.theme.colors?.lightgrey};
`;

const Element = styled.div`
	display: flex;
	align-items: center;
	color: #e8e4da;
	font-size: 14px;
	font-family: "Montserrat", "Helvetica";
	font-weight: bold;
	transition: background 300ms ease;
	&:focus{
		text-decoration: none;
		color: #e8e4da;
	}
	${props => props.clickable && css`
		&:hover {
			text-decoration: none;
			cursor: pointer;
			background-color: #4e4e4e;
			color: ${props.theme.colors?.cremewhite};
		}
	`}
`;
Element.propTypes = {
	clickable: PropTypes.bool
};

const Logo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto 0;
	width: 65px;
	min-width: 65px;
	min-height: 35px;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 17px 17px;
	background-image: url(${props => props.url});
`;
Logo.propTypes = {
	url: PropTypes.string.isRequired
};

const AccountLogo = styled(Logo)`
	background-size: 16px 17px;
`;

const DirectSpeechLogo = styled(Logo)`
	background-size: 16px;
`;

const InformationLogo = styled(Logo)`
	background-size: 18px;
`;

const UnreadNotificationLogo = styled(Logo)`
	background-size: 24px;
`;

const SettingsLogo = styled(Logo)`
	background-size: 30px;
`;

const Text = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	height: 56px;
	min-height: 56px;
	font-family: "Montserrat", "Helvetica";
	padding: 5px 20px 5px 0;
`;


const NavbarMenu = ({ onProduction, children, onGoTo, onCloseMenu, onLogout, unreadNotifications }) => {
	const { t } = useTranslation();
	const ref = useRef(null);
	const adjustMenuHeight = () => {
		const { top, bottom } = ref.current.getBoundingClientRect();
		if (bottom > window.innerHeight) {
			const maxHeight = window.innerHeight - top - 20;
			ref.current.style['-webkit-overflow-scrolling'] = 'touch';
			ref.current.style.overflowY = 'scroll';
			ref.current.style.maxHeight = `${maxHeight}px`;
		}
	};
	useEffect(() => {
		if (ref.current) {
			adjustMenuHeight();
		}
	}, [ref]);

	return (
		<>
			<Backdrop onClick={e => {
				onCloseMenu();
				e.stopPropagation();
			}}
			/>
			<MenuWrapper ref={ref}>
				<Section>
					<li>
						<Element clickable onClick={() => onGoTo('/general-settings')}>
							<AccountLogo url={userIcon} />
							<Text>{t('HAMBURGER.LINK_USER')}</Text>
						</Element>
					</li>
					<li>
						<Element clickable onClick={() => onGoTo('/robot-settings')}>

							<AccountLogo url={accountIcon} />
							<Text>{t('HAMBURGER.LINK_ROBOT_SETTINGS')}</Text>
						</Element>
					</li>
				</Section>
				<Item onClick={e => e.stopPropagation()}>
					{children}
				</Item>
				<Section>
					<li>
						<Element clickable onClick={() => onGoTo('/direct')}>
							<DirectSpeechLogo url={directSpeechIcon} />
							<Text>{t('HAMBURGER.LINK_DIRECT_SPEECH')}</Text>
						</Element>
					</li>
					<li>
						<Element clickable onClick={() => onGoTo('/music/overview')}>
							<DirectSpeechLogo url={musicIcon} />
							<Text>{t('HAMBURGER.LINK_MUSIC')}</Text>
						</Element>
					</li>
					<li>
						<Element
							as="a"
							href="https://help.tinybots.nl/portal/nl/newticket"
							target="_blank"
							rel="noopener"
							clickable
						>
							<Logo url={feedbackIcon} />
							<Text>{t('HAMBURGER.LINK_FEEDBACK')}</Text>
						</Element>
					</li>
					<li>
						<Element
							onClick={() => onGoTo('/information')}
							clickable
						>
							{unreadNotifications && <UnreadNotificationLogo data-testid="unread-icon" url={unreadNotificationIcon} />}
							{!unreadNotifications && <InformationLogo data-testid="information-icon" url={informationIcon} />}
							<Text>{t('HAMBURGER.LINK_INFORMATION')}</Text>
						</Element>
					</li>
					<li>
						<Element
							as="a"
							href="https://www.tinybots.nl/help"
							target="_blank"
							rel="noopener"
							clickable
						>
							<Logo url={helpIcon} />
							<Text>{t('HAMBURGER.LINK_HELP')}</Text>
						</Element>
					</li>
					{!onProduction && (
						<li>
							<Element clickable onClick={() => onGoTo('/settings')}>
								<SettingsLogo url={settingsIcon} />
								<Text>{t('HAMBURGER.LINK_SETTINGS')}</Text>
							</Element>
						</li>
					)}
				</Section>
				<Item>
					<Element clickable onClick={onLogout}>
						<Logo url={exitIcon} />
						<Text>{t('HAMBURGER.LINK_LOGOUT')}</Text>
					</Element>
				</Item>

			</MenuWrapper>
		</>
	);
};

NavbarMenu.propTypes = {
	onProduction: PropTypes.bool.isRequired,
	children: PropTypes.element.isRequired,
	onGoTo: PropTypes.func.isRequired,
	onCloseMenu: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	unreadNotifications: PropTypes.bool.isRequired
};

export default NavbarMenu;

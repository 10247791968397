
/**
 * @file Manage state for app notifications
 * @author Max van Loosbroek
 */

import { createSlice, createAsyncThunk, createEntityAdapter, createAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../root.reducer';
import { AppNotification, AppNotificationsState } from '../types';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { getNotifications, markNotificationAsSeen } from './api';
import { isAppValidVersion } from '../helpers';

export const robotAppNotificationsAdapter = createEntityAdapter<AppNotification>({ selectId: (entity) => entity.uuid });
export const initialStateAppNotifications: AppNotificationsState = robotAppNotificationsAdapter.getInitialState({
    getting: true,
    getError: false,
    patching: false,
    patchError: false,
    show: false,
    hiddenAt: new Date(0).toISOString(),
    fetchedAt: new Date(0).toISOString()
});

export const setCheckErrors = createAction<boolean>('appNotifications/setCheckErrors')
export const clearState = createAction('appNotifications/clearState')
export const showNotification = createAction('appNotifications/showNotification')
export const hideNotification = createAction('appNotifications/hideNotification')

export const fetchAppNotifications = createAsyncThunk(
    'appNotifications/fetchAppNotifications',
    async (_, thunkApi) => {
        const { appNotifications } = thunkApi.getState() as RootState
        const minutesDiff = differenceInMinutes(new Date(Date.now()), new Date(appNotifications.fetchedAt))
        if (minutesDiff < 5) {
            // Only get notifications when they haven't been fetched recently
            return
        }
        const response: { data: AppNotification[] } = await getNotifications();
        const notifications = response.data;
        
        const filteredNotifications = notifications.filter(n => {
            return isAppValidVersion(n.appVersion)
        })
        
        return filteredNotifications;
    }
);

export const fetchAndShowNotificationsIfTimePassed = createAsyncThunk(
    'appNotifications/fetchAndShowNotificationsIfTimePassed',
    async (params: { force?: boolean } | undefined, thunkApi) => {
        const { appNotifications } = thunkApi.getState() as RootState
        const hoursDiff = differenceInHours(new Date(Date.now()), new Date(appNotifications.hiddenAt))
        if (hoursDiff > 2 || params?.force) {
            await thunkApi.dispatch(fetchAppNotifications())
            thunkApi.dispatch(showNotification())
        }
    }
);

export const markSeen = createAsyncThunk(
    'appNotifications/markSeen',
    async ({ userId, notificationUuid }: { userId: number, notificationUuid: string }) => {
        await markNotificationAsSeen(notificationUuid);
        return notificationUuid;
    }
)

export const markAllSeen = createAsyncThunk(
    'appNotifications/markAllSeen',
    async ({ userId }: { userId: number }, thunkApi) => {
        const { appNotifications } = thunkApi.getState() as RootState
        for (const notificationUuid of appNotifications.ids as string[]) {
            await thunkApi.dispatch(markSeen({ userId, notificationUuid }))
            thunkApi.dispatch(hideNotification())
        }
    }
)

export const appNotificationsSlice = createSlice({
    name: 'appNotifications',
    initialState: initialStateAppNotifications,
    reducers: {
        clearError: state => ({ ...state, getError: false, patchError: false }),
    },
    extraReducers: builder => {
        builder.addCase(fetchAppNotifications.pending, (state) => ({
            ...state,
            getting: true,
            getError: false
        }));
        builder.addCase(fetchAppNotifications.fulfilled, (state, action) => {
            state.getting = false
            state.getError = false
            if (action.payload) {
                state.fetchedAt = new Date(Date.now()).toISOString()
                robotAppNotificationsAdapter.setAll(state, action.payload)
            }
        });
        builder.addCase(fetchAppNotifications.rejected, (state) => ({
            ...state,
            getting: false,
            getError: true
        }));
        builder.addCase(markSeen.pending, (state) => ({
            ...state,
            patching: true,
            patchError: false
        }));
        builder.addCase(markSeen.fulfilled, (state, action) => {
            state.patching = false
            state.patchError = false
            robotAppNotificationsAdapter.removeOne(state, action.payload)
        });
        builder.addCase(markSeen.rejected, (state) => ({
            ...state,
            patching: false,
            patchError: true
        }));
        builder.addCase(showNotification, (state) => ({
            ...state,
            show: true
        }));
        builder.addCase(hideNotification, (state) => ({
            ...state,
            show: false,
            hiddenAt: new Date(Date.now()).toISOString()
        }));
    }

});

const selfSelector = (state: AppNotificationsState) => state
export const appNotificationsSelector = createSelector(selfSelector, robotAppNotificationsAdapter.getSelectors().selectAll);

export const appNotificationSelector = createSelector(
    appNotificationsSelector,
    (appNotifications) => {
        return appNotifications[0]
    }
);

export const unreadSelector = createSelector(
    appNotificationsSelector,
    (appNotifications) => {
        return appNotifications?.length > 0
    }
);

// Extract the action creators object and the reducer
const { reducer } = appNotificationsSlice;

export const appNotifications = reducer;
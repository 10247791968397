/**
 * @file Contains components for item lists such as on the settings pages
 * @author Max van Loosbroek
 */

import { ThemeContext } from 'styled-components';
import React, { FunctionComponent, HTMLAttributes, useContext } from 'react';
import { Grid, styled, useMediaQuery, useTheme } from '@material-ui/core';

export interface ItemProps {
    cursor?: React.CSSProperties['cursor'],
    children: React.ReactNode,
    disabled?: boolean,
    onClick?: (event: React.MouseEvent) => any,
    height?: 24 | 32 | 48 | 'auto',
    minHeight?: number,
    style?: React.CSSProperties,
    className?: string
}

const StyledDivBase = styled('div')({
    width: (props: { width: string }) => props.width
});

const StyledDivLeft = styled(StyledDivBase)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexShrink: 0,
});

const StyledDivRight = styled(StyledDivBase)({
    textAlign: 'right',
    flexShrink: 0,
});

export const MiddleColumn = styled('div')({
    flexBasis: '100%',
    justifyContent: 'flex-start',
    position: 'relative',
    textAlign: 'left',
});

export const Item: FunctionComponent<ItemProps & HTMLAttributes<HTMLDivElement>> = ({ children, disabled = false, onClick, height, minHeight, cursor, style, className }) => {
    const themeContext = useContext(ThemeContext);
    const clearButton: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        cursor: cursor ? cursor : (disabled ? 'initial' : 'pointer'),
        background: 'transparent',
        outline: 'none',
        border: 'none',
        padding: '0',
        fontFamily: 'Montserrat-Light',
        width: '100%',
    };
    return (
        <div
            className={className}
            style={{
                minHeight,
                height,
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                color: disabled ? themeContext?.colors.lightgrey : themeContext?.colors.cremewhite,
                ...style
            }}>
            {
                onClick
                    ? <button type="button" style={clearButton} onClick={onClick}>
                        {children}
                    </button> :
                    <div style={clearButton}>
                        {children}
                    </div>
            }
        </div>
    );
};

const useSideWidth = () => {
    const materialThemeContext = useTheme();
    const upSm = useMediaQuery(materialThemeContext.breakpoints.up('sm'));
    const upLg = useMediaQuery(materialThemeContext.breakpoints.up('lg'));
    return (upLg ? 72 : (upSm ? 56 : 32)) + 'px';
}

export const LeftColumn = ({ children }: { children?: React.ReactNode }) => {
    const width = useSideWidth();
    return <StyledDivLeft width={width}>{children}</StyledDivLeft>
}

export const CustomDiv = ({ children }: { children?: React.ReactNode }) => <div>{children}</div>

export const RightColumn = ({ children }: { children?: React.ReactNode }) => {
    const width = useSideWidth();

    return <StyledDivRight width={width}>{children}</StyledDivRight>
}

export const Divider = ({ title }: { title?: React.ReactNode }) => (
    <>
        <Grid item xs={12} sm={8} style={{ opacity: 0.6, marginBottom: -16 }}>
            {title}
            <div style={{ borderBottom: 'white 1px solid' }} />
        </Grid>
    </>
  )

export const useHeaderStyle = () => {
    const themeContext = useContext(ThemeContext);
    return {
        backgroundColor: themeContext.colors.darkgrey,
        color: themeContext.colors.cremewhite
    };
}

export const useBodyStyle = () => {
    const themeContext = useContext(ThemeContext);
    return {
        overflowY: 'auto',
        backgroundColor: themeContext.colors.darkgrey,
        color: themeContext.colors.cremewhite,
        padding: '32px',
        paddingLeft: themeContext.variables.paddingNormal,
        paddingRight: themeContext.variables.paddingNormal
    };
}
import {
  PLAYLISTS_SET,
  PLAYLISTS_DELETE_ASK,
  PLAYLISTS_DELETE_REJECT,
  PLAYLISTS_DELETE_CONFIRM,
  PLAYLISTS_DELETE_SUCCESS,
  PLAYLISTS_DELETE_ERROR,
  PLAYLISTS_SELECT,
  PLAYLISTS_SHOW_MENU,
  PLAYLISTS_HIDE_MENU,
  PLAYLISTS_SEARCH,
  PLAYLISTS_GET_ERROR,
  PLAYLISTS_HIDE_ERROR,
  PLAYLISTS_CLEAR,
  PLAYLISTS_UPDATE_PLANNED_PLAYLIST,
  PLAYLISTS_UPDATE_PLANNED_PLAYLIST_FINISH
} from '../constants/actionTypes';

import { setUniqueName } from '../../../utils';

export const initialState = {
  list: [],
  selectedPlaylist: null,
  error: false,
  deletePlaylistError: false,
  hasSelectedItems: false,
  searchedPlaylist: '',
  playlistToDelete: null,
  done: false,
};

export const selectPlaylist = (state = initialState, action) => {
  switch (action.type) {
    case PLAYLISTS_SET: {
      let list = [];
      if (action.payload.length) {
        list = setUniqueName(action.payload.map(playlist => {
          if (playlist.totalDuration) {
            return { ...playlist, minutes: Math.floor(playlist.totalDuration / 60000) };
          }
          return playlist;
        }));
      }
      return {
        ...initialState,
        list,
        done: true,
        error: false
      };
    }


    case PLAYLISTS_GET_ERROR:
      return { ...state, done: true, error: true };

    case PLAYLISTS_DELETE_ASK:
      return { ...state, playlistToDelete: action.payload };

    case PLAYLISTS_DELETE_REJECT:
      return { ...state, playlistToDelete: null };

    case PLAYLISTS_DELETE_CONFIRM:
      return { ...state, playlistToDelete: null, done: false };

    case PLAYLISTS_DELETE_SUCCESS: {
      let isDeletedPlaylistSelected = false;

      const filteredList = state.list.filter(playlist => {
        playlist.id === action.payload && (isDeletedPlaylistSelected = !!playlist.selected);
        return playlist.id !== action.payload;
      });

      return {
        ...state,
        list: filteredList,
        hasSelectedItems: isDeletedPlaylistSelected ? !isDeletedPlaylistSelected : state.hasSelectedItems,
        done: true
      };
    }
    case PLAYLISTS_DELETE_ERROR:
      return { ...state, done: true, deletePlaylistError: true };

    case PLAYLISTS_HIDE_ERROR:
      return { ...state, deletePlaylistError: false };

    case PLAYLISTS_SELECT: {
      let selectedPlaylist;
      let hasSelectedItems;
      const selectionList = state.list.map(playlist => {
        const playListNew = {...playlist};
        if (action.payload.id === playListNew.id) {
          playListNew.selected = !playListNew.selected;
          selectedPlaylist = playListNew.selected ? action.payload : null;
        }
        else {
          playListNew.selected = false;
        }
        return playListNew;
      });
      hasSelectedItems = !!selectedPlaylist;
      return { ...state, hasSelectedItems, selectedPlaylist, list: selectionList };

    }

    case PLAYLISTS_SHOW_MENU: {
      const showMenuList = state.list.map(playlist => {
        const playListNew = {...playlist};
        if (playListNew.id === action.payload.id) {
          playListNew.overflow = true;
        }
        return playListNew;
      });
      return { ...state, list: showMenuList };
    }
    case PLAYLISTS_HIDE_MENU: {
      const hideMenuList = state.list.map(playlist => {
        const playListNew = {...playlist};
        playListNew.overflow = false;
        return playListNew;
      });
      return { ...state, list: hideMenuList };
    }

    case PLAYLISTS_SEARCH: {
      const searchedPlaylist = action.payload;
      const filterList = [...state.list].map(playlist => {
        const playListNew = {...playlist};
        playListNew.hide = action.payload ? !playlist.name.toLowerCase().includes(action.payload.toLowerCase()) : false;
        return playListNew;
      });
      return { ...state, searchedPlaylist, list: filterList };
    }

    case PLAYLISTS_CLEAR:
      return { ...initialState };

    case PLAYLISTS_UPDATE_PLANNED_PLAYLIST:
      return { ...state, done: false };

    case PLAYLISTS_UPDATE_PLANNED_PLAYLIST_FINISH:
      return { ...state, done: true };

    default: return state;
  }

};

export const checkEnabled = songs => {
  const selectedItems = songs.filter(song => song.selected);
  const songsSelected = selectedItems.length;
  const minutes = Math.round(selectedItems.reduce((acc, current) => (acc + current.duration), 0) / 60000);

  return { songsSelected, minutes };
};

export const allItemsSelected = (songs = [], allowPendingStatusSelection) => {
  const filtered = songs.filter(song => !song.hide && (allowPendingStatusSelection || song.status !== 'PENDING'));
  return filtered.length ? filtered.every(song => song.selected) : false;
};

export const setUniqueName = arr => {
  const title = arr[0].title ? 'title' : 'name';
  const count = {};

  return arr.map(item => {
    if (count[item[title]]) {
      const newItem = { ...item, [title]: `${item[title]}(${count[item[title]]})` };
      count[item[title]]++;
      return newItem;
    }
    else {
      count[item[title]] = 1;
      return item;
    }
  })
};

export const selectSongs = (state, action, allowPendingStatusSelection) => {
  return state.songs.map(stateSong => {
    const song = { ...stateSong };
    if (!allowPendingStatusSelection && song.status === 'PENDING') {
      return song;
    }
    if (action.payload) {
      if ((Array.isArray(action.payload) && action.payload.includes(song.id)) || song.id === action.payload.id) {
        song.selected = !song.selected;
      }
    }
    else {
      !song.hide && (song.selected = !state.allSongsSelected);
    }
    return song;
  });
};
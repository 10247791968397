import { NextMultipleChoiceStandard, ScriptStoreNext } from "../types/Next"

export const sortMultipleChoice = (a: ScriptStoreNext, b: ScriptStoreNext) => {
  const intentionTypeA = (a as NextMultipleChoiceStandard)?.intentionType
  const intentionTypeB = (b as NextMultipleChoiceStandard)?.intentionType
  if (intentionTypeB === 'offline' && intentionTypeA !== 'offline') {
    return -1
  } else if (intentionTypeA === 'offline' && intentionTypeB !== 'offline') {
    return 1
  } else if (intentionTypeA === 'other' && intentionTypeB !== 'other') {
    return 1
  } else if (intentionTypeB === 'other' && intentionTypeA !== 'other') {
    return -1
  }
  return 0
}
import { createAction } from '@reduxjs/toolkit'
import { TaskState } from '../types/TaskState'
import { Schedule } from '../types/Schedule'
import { TessaScriptDenormalized } from '../../../mScripts/tessaScript/types/DeprecatedScript'

export const taskSetScriptDeprecated = createAction<TessaScriptDenormalized>('task/taskSetScriptDeprecated')
export const taskEdit = createAction<Partial<TaskState>>('task/taskEdit')
export const taskSetSchedule = createAction<{
  pickedDate: string
  schedule: Schedule
}>('task/taskSetSchedule')

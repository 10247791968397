import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentTitle = styled.h3`
	margin: 10px auto;
  opacity: 0.8;
  font-family: Montserrat-Bold;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  text-align: left;
  color: #000000;
`;

ContentTitle.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ContentTitle;

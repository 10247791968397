import { RecurrenceType } from '../../redux/types/Schedule'
import { RecurringType, TimeUnit } from '../types'

export const getRecurrenceUnit = (type: RecurringType | RecurrenceType): TimeUnit | undefined => {
  switch (type) {
    case 'DAILY':
    case 'everyDay':
      return 'day'
    case 'WEEKLY':
    case 'everyWeek':
      return 'week'
    case 'MONTHLY':
    case 'everyMonth':
      return 'month'
    case 'YEARLY':
    case 'everyYear':
      return 'year'
    case 'other':
      return 'week'
  }
}

export const getRecurrenceType = (type: RecurringType | TimeUnit): RecurrenceType | undefined => {
  switch (type) {
    case 'day':
    case 'everyDay':
      return 'DAILY'
    case 'week':
    case 'everyWeek':
      return 'WEEKLY'
    case 'month':
    case 'everyMonth':
      return 'MONTHLY'
    case 'year':
    case 'everyYear':
      return 'YEARLY'
    case 'other':
      return 'WEEKLY'
  }
}

export const getRecurringType = (type: RecurrenceType | TimeUnit): RecurringType | undefined => {
  switch (type) {
    case 'day':
    case 'DAILY':
      return 'everyDay'
    case 'week':
    case 'WEEKLY':
      return 'everyWeek'
    case 'month':
    case 'MONTHLY':
      return 'everyMonth'
    case 'year':
    case 'YEARLY':
      return 'everyYear'
    default:
      return 'other'
  }
}

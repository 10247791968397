import React from "react"

const checkDomain = (url: string) => {
  if (url.indexOf('//') === 0) { url = window.location.protocol + url }
  return url.toLowerCase().replace(/([a-z])?:\/\//, '$1').split('/')[0]
}

const isExternal = (url: string) => {
  return ((url.indexOf(':') > -1 || url.indexOf('//') > -1) && checkDomain(window.location.href) !== checkDomain(url))
}

export const LinkRenderer = (props: React.HTMLProps<HTMLAnchorElement>) => {
  const target = (props.href && isExternal(props.href)) ? '_blank' : '_self'
  return (
      <a href={props.href} target={target} rel="noreferrer">
          {props.children}
      </a>
  )
}
import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.scss';

const Spinner = ({ type }) => {
  const classes = ['spinner-frame'];
  type === 'transition' && classes.push('spinner-frame_transition');
  return (
    <div className={classes.join(' ')}>
      <div className="spinner">
        <i className="fa fa-spinner fa-pulse fa-5x fa-fw" />
      </div>
    </div>
  )
};

Spinner.propTypes = {
  type: PropTypes.string
};

export default Spinner;
import React, { useState, useCallback } from 'react'
import {
  makeStyles,
  createStyles,
  ButtonBase,
  Backdrop,
  SvgIcon,
  ClickAwayListener
} from '@material-ui/core'
import { setActiveRobotAsync } from '../auth/redux/actions'
import { connect } from 'react-redux'
import { ProfileRobotUser } from '../generalSettings/UserProfile/redux/types'
import styledComponentsTheme from '../../common/constants/styledComponentsTheme/styledComponentsTheme'
import { ArrowDropDown } from '@material-ui/icons'
import { ReactComponent as AddRobotIcon } from '../../assets/images/icon/tb_app_icon_add_robot.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { UserState } from '../auth/redux/reducers/types'
import { RootState } from '../../root.reducer'
import RobotSwitcherItem from './RobotSwitcherItem'
import Spinner from '../../common/components/Spinner/Spinner'
import { useGetDisplayName } from './helpers'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { throttle } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    svgIcon: {
      width: 16
    },
    svgIconAdd: {
      position: 'absolute',
      right: '100%',
      top: 0,
      bottom: 0,
      margin: 'auto',
      height: 20,
      marginRight: 20
    },
    backdrop: {
      // styling for unconfirmed account banner
      marginTop: ({ unconfirmed }: { unconfirmed: boolean }) => unconfirmed ? 56 + 70 : 56,
      '@media(max-width: 575px)': {
        marginTop: ({ unconfirmed }: { unconfirmed: boolean }) => unconfirmed ? 56 + 120 : 56
      }
    },
    switcherButton: {
      zIndex: 1,
      minWidth: 0,
      display: 'flex',
      paddingTop: 31,
      flexGrow: 1,
      marginRight: 92,
      marginLeft: 185,
      height: 56,
      '@media (max-width: 575px)': {
        marginRight: 86
      },
      '@media (max-width: 767px)': {
        marginLeft: 75
      }
    },
    input: {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: styledComponentsTheme.colors.cremewhite,
      lineHeight: '16px'
    },
    menuItem: {
      width: '100%',
      fontSize: 14,
      fontFamily: 'Montserrat-Light',
      whiteSpace: 'normal',
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1
    },
    middleColumn: {
      padding: '10px 0'
    },
    addRobotContainer: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: 'auto',
      justifyContent: 'center'
    },
    addRobot: {
      flexGrow: 1,
      margin: '24px 16px',
      fontFamily: 'Montserrat',
      fontSize: 14,
      border: 'rgba(256,256,256,0.7) 1px dashed',
      height: 36,
      maxWidth: 400
    },
    arrowDropDown: {
      position: 'absolute',
      right: -23,
      top: 0,
      bottom: 0,
      margin: 'auto'
    }
  })
)

const DropDownWrapper = styled.main`
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: auto;
  right: 0;
  left: 0;
  top: 56px;
  bottom: 0;
  overflow-y: 'auto';
  color: ${props => props.theme.colors.cremewhite};
  margin-left: ${props => props.theme.variables.contentLeftMarginFull};
  margin-right: ${props => props.theme.variables.contentRightMarginFull};
  background: ${props => props.theme.colors.darkgrey};
  @media (min-width: ${props => props.theme.variables.smallestWidthBreakpoint}) and (max-width: ${props => props.theme.variables.smallerWidthBreakpoint1}) {
    margin-left: ${props => props.theme.variables.contentLeftMargin575};
    margin-right: ${props => props.theme.variables.contentRightMargin575};
  }

  @media (min-width: ${props => props.theme.variables.smallerWidthBreakpoint2}) and (max-width: ${props => props.theme.variables.midSizeWidthBreakpoint1}) {
    margin-left: ${props => props.theme.variables.contentLeftMargin768};
    margin-right: ${props => props.theme.variables.contentRightMargin768};
  }

  @media (min-width: ${props => props.theme.variables.midSizeWidthBreakpoint2}) and (max-width : 1600px) {
    margin-left: ${props => props.theme.variables.contentLeftMargin1024};
    margin-right: ${props => props.theme.variables.contentRightMargin1024};
  }

  @media (min-width:1601px)  {
    margin-left: ${props => props.theme.variables.contentLeftMargin1600};
    margin-right: ${props => props.theme.variables.contentRightMargin1600};
  }
`

const RobotList = styled.ul`
  list-style-type: none;
  padding: 0;
  overflow-y: auto;
  padding-bottom: 60px;
`

const NoWrap = styled.div`
  position: relative;
  white-space: nowrap;
  max-width: 100%;
  overflow: visible;
`

const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis; 
`

export interface RobotSwitcherProps {
  robots: ProfileRobotUser[]
  allRobots: ProfileRobotUser[]
  robotConfig: { loading?: boolean }
  user: UserState
  setActiveRobotAsync: (...args: any) => any
  showListInitial?: boolean
}

export const RobotSwitcherComponent: React.FunctionComponent<RobotSwitcherProps> = ({
  robots,
  allRobots,
  robotConfig,
  user,
  setActiveRobotAsync,
  showListInitial
}: RobotSwitcherProps) => {
  const [showList, setShowList] = useState(showListInitial ?? false)
  const displayName = useGetDisplayName(robots[0])
  const { t } = useTranslation()
  const unconfirmed = user.status === 'UNCONFIRMED'
  const classes = useStyles({ unconfirmed })
  const history = useHistory()
  const toggleList = useCallback(throttle((showListValue: boolean): void => {
    setShowList(!showListValue)
  }, 50, { 'trailing': false }), []);

  const switchRobot = async (id: number, destination?: string): Promise<void> => {
    setActiveRobotAsync(id, history, destination)
    toggleList(showList)
  }
  const onEdit = async (id: number): Promise<void> => {
    const destination = '/robot-settings'
    await switchRobot(id, destination)
  }
  const handleAddRobot = (): void => {
    history.push('/pairing')
    toggleList(showList)
  }

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={showList}
        onClick={() => toggleList(showList)}
      />
      <ButtonBase className={classes.switcherButton} onClick={() => toggleList(showList)} aria-label='Toggle Switcher Button'>
        <NoWrap>
          <Ellipsis>{displayName}</Ellipsis>
          <ArrowDropDown className={classes.arrowDropDown} />
        </NoWrap>
      </ButtonBase>
      {showList && (
        <ClickAwayListener onClickAway={() => toggleList(showList)}>
          <DropDownWrapper>
            <RobotList>
             {allRobots?.map(robotUser => <RobotSwitcherItem key={robotUser.id} robotUser={robotUser} switchRobot={switchRobot} user={user} edit={async () => await onEdit(robotUser.id)} />)}
            </RobotList>
            <div className={classes.addRobotContainer}>
              <ButtonBase classes={{ root: classes.addRobot }} onClick={handleAddRobot}>
                <span style={{ position: 'relative' }}>
                  <SvgIcon component={AddRobotIcon} classes={{ root: classes.svgIconAdd }} viewBox='0 0 25 20' />
                  {t('ROBOT_SWITCHER.ADD_ROBOT')}
                </span>
              </ButtonBase>
            </div>
          </DropDownWrapper>
        </ClickAwayListener>
      )}
      {
        robotConfig?.loading === true && <Spinner />
      }
    </>
  )
}

export default connect(
  ({
    robotConfig,
    robots,
    allRobots,
    user
  }: RootState) => ({
    robots,
    allRobots,
    robotConfig,
    user
  }),
  {
    setActiveRobotAsync
  }
)(RobotSwitcherComponent)

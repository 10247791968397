import axios from 'axios';
import { getUrls } from '../../../common/constants';
import { ResetRobotStatus } from '../redux/reducers/types';

export const userLogin = (data: {
  username: string,
  password: string,
  mfaCode: string
}) => {
  const url = getUrls('prowl', 'login');
  return axios.post(url, data);
};

export const userLoginEula = (data: {
  username: string,
  password: string,
  mfaCode: string
}) => {
  const url = getUrls('prowl', 'eula-login');
  return axios.post(url, data);
};

export const userSignUp = (data: {
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
}) => {
  const url = getUrls('prowl', 'signup');
  return axios.put(url, data);
};

export const askNewPassword = (data: {
  email: string
}) => {
  const url = getUrls('prowl', 'forgotten');
  return axios.post(url, data);
};

export const activate = (data: {
  access_token: string
}) => {
  const url = getUrls('prowl', 'activatemail');
  return axios.post(url, data);
};

export const refreshToken = (data: { refreshToken: string }) => {
  const url = getUrls('prowl', 'refresh');
  return axios.post(url, data);
};


export const getUserSelf = () => {
  const url = getUrls('prowl', 'selfV4');
  return axios.get(url);
};

export const getBots = () => {
  const url = getUrls('checkpoint', 'listbots');
  return axios.get(url);
};

export const getConfiguration = async (robotId: string) => {
  const url = getUrls('baymax', 'configuration').replace('{robotId}', robotId);
  const res = await axios({
    method: 'GET',
    url,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
  // TODO: remove capabilities override
  const isDev = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.includes('dev.')
  )
  // Only used for local dev purposes to test capabilities and feature blocking
  if (isDev && res.data?.capabilities?.length !== undefined) {
    res.data.capabilities.push('multipleChoice-v1', 'multiLingual-v1', 'report-v1')
  }
  return res
};

export const installUpdate = (robotId: string) => {
  const url = getUrls('baymax', 'install').replace('{robotId}', robotId);
  return axios.post(url);
};

export const downloadUpdate = (robotId: string) => {
  const url = getUrls('baymax', 'download').replace('{robotId}', robotId);
  return axios.post(url);
};

export const passwordAnalysis = (password: string) => {
  const url = getUrls('prowl', 'passwordanalysis');
  return axios.post(url, password);
};

export const resetPassword = (data: {
  newPassword: string,
  resetKey: string
}) => {
  const url = getUrls('prowl', 'reset');
  return axios.post(url, data);
};

export const getSecret = () => {
  const url = getUrls('prowl', 'mfa');
  return axios.post(url);
};

export const enableMFA = ({ otpCode, password }: {
  otpCode: string,
  password: string
}) => {
  const url = getUrls('prowl', 'selfV3');
  return axios.patch(url, { otpCode, password, enableMfa: true });
};

export const disableMFA = ({ otpCode, password }: {
  otpCode: string,
  password: string
}) => {
  const url = getUrls('prowl', 'selfV3');
  return axios.patch(url, { otpCode, password, enableMfa: false });
};

export const resetRobot = ({ otpCode, password, robotId }: {
  otpCode: string,
  password: string,
  robotId: number
}) => {
  const url = getUrls('prowl', 'delete-robot-account').replace('{robotId}', robotId);
  return axios.put(url, { otpCode, password });
};

export const getResetStatus = (robotId: number) => {
  const url = getUrls('prowl', 'delete-robot-account-status').replace('{robotId}', robotId);
  return axios.get<ResetRobotStatus>(url);
};

export const checkPassword = ({ password }: {
  password: string
}) => {
  const url = getUrls('prowl', 'verifypassword');
  return axios.post(url, { password });
};

export const changeEmail = (body: {
  password: string,
  otpCode?: string,
  newEmail: string,
  verificationKey?: string
}) => {
  const url = getUrls('prowl', 'change-email');
  return axios.post<{
    verified: boolean,
    message?: string
  }>(url, body);
};

import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit"
import { archiveScript, deleteScriptDeprecated, fetchScript, convertScripts, fetchTemplateScripts } from "../../api"
import { fetchScripts, fetchScriptDeprecated } from '../../api/index'
import { getCategories } from '../../../../common/constants'
import { Category } from "../types/Category"
import { v4 as uuidv4 } from 'uuid'
import { CategoryItem } from '../types/CategoryItem'
import { getSpeechInteractions } from "../../../speechInteraction/SpeechInteractionOverview/redux/api"
import { SpeechInteraction } from "../../../speechInteraction/types"
import { RootState } from "../../../../root.reducer"
import { robotLanguageSelector } from "../../../robotSettings/redux/reducer"

interface ScriptOverview {
    getting: boolean
    getError: boolean
    conversionError?: boolean
    deleting: boolean
    deleteError: boolean
    categories: EntityState<Category>
    selectedScript: number | null
    searchedScript?: string
    scriptToDelete?: CategoryItem & { isPlanned: boolean; speechInteractions: SpeechInteraction[] }
    done: boolean
}
export type ScriptOverviewState = EntityState<CategoryItem> & ScriptOverview

const getting = (state: ScriptOverviewState) => {
    state.getting = true
    state.getError = false
}

const categoryItemsAdapter = createEntityAdapter<CategoryItem>({ selectId: (entity) => entity.tempId })
const categoriesAdapter = createEntityAdapter<Category>({ selectId: (entity) => entity.id })

export const confirmDeleteScript = createAsyncThunk(
    'robotScriptsOverview/confirmDeleteScript',
    async ({ robotId, item }: { robotId: number; item: CategoryItem }) => {
        if (item.deprecatedId) await deleteScriptDeprecated(robotId, item.deprecatedId)
        if (item.id) await archiveScript(robotId, item.id)
        return item.tempId
    }
)

export const getScriptVersions = createAsyncThunk(
    'robotScriptsOverview/getScriptVersions',
    async ({ robotId, capabilities }: { robotId: number, capabilities: string[] }) => {
        try {
            await convertScripts(robotId, capabilities)
        } catch (e) {
            throw Error('Conversion error')
        }
        const { data: templateScripts } =  await fetchTemplateScripts(robotId)
        const { data: scripts } = await fetchScripts(robotId)
        return [ ...scripts, ...templateScripts ]
    }
)

export const askDeleteScript = createAsyncThunk(
    'tessaScript/askDeleteScript',
    async ({ robotId, item }: { robotId: number; item: CategoryItem }) => {
        let isPlanned: boolean
        let speechInteractions: SpeechInteraction[]
        if (item.id) {
            const { data: script } = await fetchScript(robotId, item.id)
            const { data } = await getSpeechInteractions(robotId, item.id)
            isPlanned = !!script.script.scheduled
            speechInteractions = data
        } else {
            const { data: script } = await fetchScriptDeprecated(robotId, item.deprecatedId!)
            isPlanned = !!script.scheduled
            speechInteractions = []
        }
        return { ...item, isPlanned, speechInteractions }
    }
)

export const clearError = createAction('clearOverviewErrors')
export const clearScripts = createAction('clearOverviewState')
export const selectScript = createAction<number | null>('selectScript')
export const searchScript = createAction<string>('searchScript')
export const toggleMenu = createAction<CategoryItem>('toggleMenu')
export const expandCategory = createAction<Category>('expandCategory')
export const collapseTemplateGroup = createAction<string>('collapseTemplateGroup')
export const rejectDeleteScript = createAction('rejectDeleteScript')
export const setScripts = createAction<string>('setScripts')

export const initialState = categoryItemsAdapter.getInitialState<ScriptOverview>({
    getting: true,
    getError: false,
    deleting: false,
    deleteError: false,
    done: false,
    categories: categoriesAdapter.getInitialState(),
    selectedScript: null
})

export const tessaScriptSlice = createSlice({
    name: 'tessaScriptSlice',
    initialState,
    reducers: {
        clearError: state => ({ ...state, getError: false, patchError: false, conversionError: false }),
    },
    extraReducers: builder => {
        builder
            .addCase(clearScripts, () => initialState)
            .addCase(clearError, state => ({ ...state, getError: false, patchError: false, conversionError: false }))
            .addCase(getScriptVersions.pending, getting)
            .addCase(searchScript, (state, { payload }) => {
                state.searchedScript = payload
                const scripts = categoryItemsAdapter
                    .getSelectors()
                    .selectAll(state)
                const matches = scripts.filter(i => i.name.toLowerCase().includes(payload.toLowerCase() || ''))
                if (matches.length > 0 && payload?.length > 0) {
                    categoriesAdapter.updateMany(state.categories, matches.map(i => {
                        const category = categoriesAdapter.getSelectors().selectAll(state.categories).find(c => c.label === i.category && c.templateGroup === i.templateGroup)
                        return ({
                            id: category!.id,
                            changes: {
                                expanded: true
                            }
                        })
                    }))
                }
            })
            .addCase(getScriptVersions.fulfilled, (state, action) => {
                const categoriesOrder = ['act_visits', 'sustenance', 'selfcare', 'health', 'household', 'other']
                const categories: Category[] = []
                const items: CategoryItem[] = action.payload.map(script => {
                    const { scriptName, scriptCategory, scriptReferenceId, deprecatedId, scriptLanguage, templateGroup } = script
                    return { tempId: uuidv4(), id: scriptReferenceId, deprecatedId, name: scriptName, category: scriptCategory!, type: 'script', selected: false, language: scriptLanguage, templateGroup }
                })
                items.forEach(e => {
                    const foundCategory = categories.find(c => c.label === e.category && c.templateGroup === e.templateGroup)!
                    const { category, tempId } = e
                    const categoryName: string = getCategories().find(cat => category === cat?.label)?.name!
                    foundCategory
                        ? foundCategory.itemIds.push(tempId)
                        : categories.push({
                            id: uuidv4(),
                            label: category!,
                            name: categoryName,
                            expanded: false,
                            itemIds: [e.tempId],
                            templateGroup: e.templateGroup,
                            overflow: false,
                            items: []
                        })
                })
                const newCat = categories
                    .map(category => (
                        {
                            ...category,
                            items: category.items.sort((a: any, b: any) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                        }
                    ))
                    .sort((a, b) => {
                        const indexA = categoriesOrder.indexOf(a.label)
                        const indexB = categoriesOrder.indexOf(b.label)

                        return indexA < indexB ? -1 : indexA > indexB ? 1 : 0
                    })
                categoriesAdapter.setAll(state.categories, newCat)
                state.done = true
                categoryItemsAdapter.setAll(state, items)
            })
            .addCase(getScriptVersions.rejected, (state, action) => {
                if (action.error && action.error.message === 'Conversion error') {
                    state.conversionError = true
                } else {
                    state.getError = true
                }
                state.getting = false
                state.done = true
            })
            .addCase(selectScript, (state, action) => {
                state.selectedScript = action.payload
            })
            .addCase(expandCategory, (state, action) => {
                const cat = state.categories.entities[action.payload.id]
                categoriesAdapter.updateOne(state.categories, { id: action.payload.id, changes: { expanded: !cat?.expanded } })
            })
            .addCase(collapseTemplateGroup, (state, action) => {
                const update = Object.values(state.categories.entities).filter(c => c?.templateGroup === action.payload).map(c => ({
                    id: c!.id,
                    changes: {
                        expanded: false
                    }
                }))
                categoriesAdapter.updateMany(state.categories, update)
            })
            .addCase(toggleMenu, (state, action) => {
                const overflow = !action.payload.overflow
                const toggleItemId = action.payload.tempId
                const openItems = itemsArraySelector({ tessaScriptOverview: state }).filter(i => i.overflow === true && i.tempId !== toggleItemId).map(({ tempId }) =>
                    ({ id: tempId, changes: { overflow: false } })
                )
                const updates = [{ id: toggleItemId, changes: { overflow } }, ...openItems]
                categoryItemsAdapter.updateMany(state, updates)
            })
            .addCase(askDeleteScript.fulfilled, (state, action) => {
                state.scriptToDelete = action.payload
                state.done = true
            })
            .addCase(rejectDeleteScript, (state) => {
                state.scriptToDelete = undefined
            })
            .addCase(confirmDeleteScript.pending, (state, action) => {
                state.deleting = true
                state.deleteError = false
            })
            .addCase(confirmDeleteScript.rejected, (state, action) => {
                state.deleting = false
                state.deleteError = true
            })
            .addCase(confirmDeleteScript.fulfilled, (state, action) => {
                state.scriptToDelete = undefined
                state.deleteError = false
                categoryItemsAdapter.removeOne(state, action.payload)
            })
    }
})

// Extract the action creators object and the reducer

const entitiesSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => state.tessaScriptOverview.entities
const idsSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => state.tessaScriptOverview.ids
const scriptItemsSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => categoryItemsAdapter.getSelectors().selectAll(state.tessaScriptOverview)
const catIdsSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => state.tessaScriptOverview.categories.ids
const catEntitiesSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => state.tessaScriptOverview.categories.entities
const searchStringSelector = (state: Pick<RootState, 'tessaScriptOverview'> | RootState) => state.tessaScriptOverview.searchedScript?.toLowerCase()
export const itemsArraySelector = createSelector(
    idsSelector,
    entitiesSelector,
    searchStringSelector,
    (ids, entities, searchString) => ids.map(id => entities[id]).filter(e => e).filter((c): c is CategoryItem => Boolean(c)).filter(i => i.name.toLowerCase().includes(searchString || ''))
)
export const categoriesArraySelector = createSelector(
    catIdsSelector,
    catEntitiesSelector,
    (ids, entities) => ids.map(id => entities[id]).filter(e => e).filter((c): c is Category => Boolean(c))
)
const selectedIdSelector = (state: Pick<RootState, 'tessaScriptOverview' | 'robotSettings'> | RootState) => state.tessaScriptOverview.selectedScript
export const categoriesSelector = (templateGroup?: string) => createSelector(
    categoriesArraySelector,
    scriptItemsSelector,
    selectedIdSelector,
    searchStringSelector,
    robotLanguageSelector,
    (cats, items, selectedId, searchString, robotLanguage) => {
        const selectedItems = items.filter(i => i?.templateGroup === templateGroup)
        const categoriesWithItems = cats.filter(c => c?.templateGroup === templateGroup).map(c => {
            const mappedItems = selectedItems.filter(i => i.category === c.label && i.templateGroup === c.templateGroup).filter((c): c is CategoryItem => Boolean(c))
                .map(i => ({ ...i, selected: !!selectedId && (i.id === selectedId || i.deprecatedId === selectedId) }))
                .map(i => {
                    if (!i.name.toLowerCase().includes(searchString || '')) {
                        return { ...i, hide: true }
                    }
                    return { ...i }
                })
            return { ...c, items: mappedItems }
        }).map(c => {
            if (!c.items.find(i => !i?.hide)) {
                return { ...c, hide: true }
            }
            const expanded = !!c.items.some(i => i.selected) || !!c.expanded
            return { ...c, expanded }
        })
        return categoriesWithItems
    }
)
export const templatesSelector = createSelector(
    ({ robotSettings, tessaScriptOverview }: Pick<RootState, 'tessaScriptOverview' | 'robotSettings'> | RootState) => ({ robotSettings, tessaScriptOverview }),
    scriptItemsSelector,
    (state, scriptItems) => {
        const templateGroups = [...new Set(scriptItems.map(s => s.templateGroup))].filter(g => g !== undefined) as string[]
        return templateGroups.map(g => {
            return {
                groupName: g,
                scriptCategories:  categoriesSelector(g)(state)
            }
        })
    }
)
export const selectedScriptSelector = createSelector(
    itemsArraySelector,
    selectedIdSelector,
    (items, id) => {
        if (id) {
            return items.find(i => i.id === id || i.deprecatedId === id)
        }
    }
)

const { reducer } = tessaScriptSlice
export const tessaScriptOverview = reducer
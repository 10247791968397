/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { isTokenExpired } from '../../features/auth/utils';
import { getNewTokenAsync, logout } from '../../features/auth/redux/actions';
import { getUrls } from '../constants';

const history = createBrowserHistory();

export const setupInterceptors = store => {
  let inFlightRefreshTokenRequest = null;
  let updatingToken = false;

  axios.interceptors.request.use(config => {
    const { session } = store.getState();
    const token = session.access_token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;

      const isExpired = isTokenExpired(session);

      if (!inFlightRefreshTokenRequest && !updatingToken && isExpired) {
        updatingToken = true;

        return store.dispatch(getNewTokenAsync())
          .then(accessToken => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
          })
          .catch((e) => {
            console.error('Issue getting new token: ', e);
            store.dispatch(logout());
          })
          .finally(() => {
            updatingToken = false;
            return updatingToken;
          });
      }
    }
    return config;
  }, error => Promise.reject(error));

  axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401 && error.response.data.error === 'invalid_token') {
      return new Promise((resolve, reject) => {
        if (store.getState().session.isAuthenticated && !updatingToken) {
          !inFlightRefreshTokenRequest && (inFlightRefreshTokenRequest = store.dispatch(getNewTokenAsync()));
          inFlightRefreshTokenRequest
            .then(() => axios(error.response.config))
            .then(resp => resolve(resp))
            .catch(() => {
              store.dispatch(logout());
            })
            .finally(() => { inFlightRefreshTokenRequest = null; return inFlightRefreshTokenRequest; });
        } else {
          reject(error);
        }
      });
    }
    if (error.response && error.response.status > 299
      && ![400, 401, 403, 404, 409].includes(error.response.status)
      && !error.response.config.url.includes(getUrls('heartbeat', 'status').replace('{robotId}', ''))
      && !error.response.config.url.includes('scripts/convert')) {
      history.push('/error');
      return Promise.reject(error);
    }

    return Promise.reject(error);
  });
};

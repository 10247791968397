import styled from 'styled-components';

const VersionWarning = styled.div`
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.colors.warning60};
  color: #FFF;
  text-align: center;
  line-height: 1.5;
  font-size: 13px;
  z-index: 9999;

  @media (min-width: 0) and (max-width : 375px) {
    font-size: 9px;

    a {
      font-size: 11px;
    }
  }

  @media (min-width: 376px) and (max-width : 480px) {
    font-size: 11px;

    a {
      font-size: 12px;
    }
  }

  a {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
  }
`;

export default VersionWarning;

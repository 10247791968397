import moment from 'moment';

export const padNumber = (n, width, z = '0') => {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const adjustTime = (time = '', caret, maxTime) => {
  time = time.replace(/[\D]/g, '');
  switch(time.length) {
    case 1:
      switch(caret){
        case 0: time = `0${time}`;
        break;
        case 1: time = `${time}0`;
        break;
        default:
      }
      break;
    case 3:
      switch(caret){
        case 1: time = time[0] + time[2];
        break;
        case 2:
        case 3: time = time.slice(0,2);
        break;
        default:
      }
      break;
    default: time = '00'
  }

  return parseInt(time) <= maxTime ? time : maxTime + ''
};

export const parseISOTime = time => ['months', 'weeks', 'days', 'hours', 'minutes', 'seconds'].reduce((parsed, period) => {
  const dur = moment.duration(time);
  switch (period) {
    case 'months':
      parsed[period] = Math.floor(dur.asMonths());
      break;
    case 'weeks':
      parsed[period] = Math.floor(dur.asWeeks());
      break;
    case 'days':
      parsed[period] = Math.floor(dur.asDays());
      break;
    case 'hours':
      parsed[period] = Math.floor(dur.asHours());
      break;
    case 'minutes':
      parsed[period] = Math.floor(dur.asMinutes());
      break;
    case 'seconds':
      parsed[period] = Math.floor(dur.asSeconds());
      break;
    default:
      break;
  }
  return parsed;
}, {});

export const convertSecondsToMinutesAndSeconds = time => {
  const maxTimeInSeconds = 6039;

  if(time > maxTimeInSeconds) {
    return { minutes: '99', seconds: '99' }
  }

  const minutesNumber = time / 60 >= 1
    ? time / 60 > 99 ? 99 : Math.floor(time / 60)
    : 0;
  const secondsNumber = time / 60 >= 1
    ? time / 60 > 99 ? time - 99 * 60 : time % 60
    : time;

  const minutes = minutesNumber > 9 ? minutesNumber.toString() : `0${minutesNumber}`;
  const seconds = secondsNumber > 9 ? secondsNumber.toString() : `0${secondsNumber}`;

  return { minutes, seconds }
};

export const convertMinutesAndSecondsToSeconds = ({ minutes, seconds }) => parseInt(minutes) * 60 + parseInt(seconds);
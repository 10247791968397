/**
 * @file Reducer for the state of single speech interaction editor
 * @author Max van Loosbroek
 */

import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  Update,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { ScriptVersion } from '../../../mScripts/tessaScript/types/ScriptVersion'
import { SpeechInteractionDto } from '../../types'
import { EntityState } from '@reduxjs/toolkit'
import { RootState } from '../../../../root.reducer'
import {
  checkSimilarSpeechInteraction,
  getSpeechInteraction,
  putSpeechInteraction
} from './api'
import { fetchScript } from '../../../mScripts/api'
import { putInteractionSelector } from './selectors'
import checkSimilarMatches from './checkSimilarMatches'

export type CommandEntity = {
  index: number
  value: string
  originalValue?: string
  similarityError?: {
    id: number
    title: string
    commands: string[]
  }[]
}

export type SpeechInteractionStateSingle = {
  getting: boolean
  getError: boolean
  patching: boolean
  patchError: boolean
  script?: Pick<ScriptVersion, 'scriptName' | 'scriptReferenceId'>
  speechInteraction: Omit<SpeechInteractionDto, 'commands'>
  commands: EntityState<CommandEntity>
}

export const commandsAdapter = createEntityAdapter<CommandEntity>({
  selectId: entity => entity.index
})

export const initialState: SpeechInteractionStateSingle = {
  getting: true,
  getError: false,
  patching: false,
  patchError: false,
  speechInteraction: {
    title: '',
    enabled: true
  },
  commands: commandsAdapter.getInitialState()
}

export const fetchSpeechInteraction = createAsyncThunk(
  'speechInteractionSingle/fetchSpeechInteraction',
  async ({ robotId, id }: { robotId: number; id: number }) => {
    const { data: interaction } = await getSpeechInteraction(robotId, id)
    const {
      data: { script }
    } = await fetchScript(robotId, interaction.scriptReferenceId!)
    return { interaction, script }
  }
)

export const checkSimilar = createAsyncThunk(
  'speechInteractionSingle/checkSimilar',
  async (
    {
      robotId,
      command,
      index
    }: { robotId: number; command: string; index: number },
    thunkApi
  ) => {
    const {
      speechInteractionSingle: {
        speechInteraction: { id },
        commands
      }
    } = thunkApi.getState() as RootState
    const currentCommand = commands.entities[index]!
    const { data: interactions } = await checkSimilarSpeechInteraction(
      robotId,
      { command }
    )
    return checkSimilarMatches({
      interactionId: id,
      interactions,
      currentCommand
    })
  }
)

export const upsertSpeechInteraction = createAsyncThunk(
  'speechInteractionSingle/upsertSpeechInteraction',
  async (
    {
      robotId
    }: {
      robotId: number
    },
    thunkApi
  ) => {
    const state = thunkApi.getState() as RootState
    const interaction = putInteractionSelector(state)
    const { data } = await putSpeechInteraction(interaction, robotId)
    return data
  }
)

export const speechInteractionSingleSlice = createSlice({
  name: 'speechInteractionSingleSlice',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    clearError: state => ({ ...state, getError: false, patchError: false }),
    setScript: (
      state,
      action: PayloadAction<
        Pick<ScriptVersion, 'scriptName' | 'scriptReferenceId'>
      >
    ) => {
      state.script = action.payload
      if (state.speechInteraction && action.payload.scriptReferenceId) {
        state.speechInteraction.scriptReferenceId =
          action.payload.scriptReferenceId
      }
    },
    addCommand: (state, action: PayloadAction<string | void>) => {
      state.getting = false
      const index =
        state.commands.ids.length > 0 ? state.commands.ids.length : 0
      const value = action.payload ? action.payload : ''
      commandsAdapter.addOne(state.commands, {
        value,
        index
      })
    },
    updateCommand: (state, action: PayloadAction<Update<CommandEntity>>) => {
      commandsAdapter.updateOne(state.commands, action.payload)
    },
    updateTtile: (state, action: PayloadAction<string>) => {
      state.speechInteraction.title = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSpeechInteraction.pending, state => {
      state.getting = true
      state.getError = false
    })
    builder.addCase(fetchSpeechInteraction.fulfilled, (state, action) => {
      const {
        interaction: {
          id,
          robotId,
          title,
          enabled,
          scriptReferenceId,
          commands
        },
        script
      } = action.payload
      state.script = {
        scriptReferenceId: script.scriptReferenceId,
        scriptName: script.scriptName
      }
      state.speechInteraction = {
        id,
        robotId,
        title,
        scriptReferenceId,
        enabled
      }
      commandsAdapter.setAll(
        state.commands,
        commands.map((c, i) => ({ value: c, index: i, originalValue: c }))
      )
      state.getting = false
      state.getError = false
    })
    builder.addCase(fetchSpeechInteraction.rejected, state => {
      state.getting = false
      state.getError = true
    })
    builder.addCase(upsertSpeechInteraction.pending, state => {
      state.patching = true
      state.patchError = false
    })
    builder.addCase(upsertSpeechInteraction.fulfilled, (state, action) => {
      const {
        id,
        robotId,
        title,
        enabled,
        scriptReferenceId,
        commands
      } = action.payload
      state.patching = false
      state.patchError = false
      state.speechInteraction = {
        id,
        robotId,
        title,
        scriptReferenceId,
        enabled
      }
      commandsAdapter.setAll(
        state.commands,
        commands.map((c, i) => ({ value: c, index: i, originalValue: c }))
      )
    })
    builder.addCase(upsertSpeechInteraction.rejected, state => {
      state.patching = false
      state.patchError = true
    })
    builder.addCase(checkSimilar.fulfilled, (state, { payload }) => {
      commandsAdapter.updateOne(state.commands, {
        id: payload.index,
        changes: {
          similarityError: payload.similarCommands
        }
      })
    })
    builder.addCase(checkSimilar.rejected, (state, action) => {
      commandsAdapter.updateOne(state.commands, {
        id: action.meta.arg.index,
        changes: {
          similarityError: undefined
        }
      })
    })
  }
})

// Extract the action creators object and the reducer
export const {
  clearState,
  clearError,
  setScript,
  addCommand,
  updateCommand,
  updateTtile
} = speechInteractionSingleSlice.actions
const { reducer } = speechInteractionSingleSlice
export const speechInteractionSingle = reducer

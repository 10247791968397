
/**
 * @file Tests for async (thunks) permissions actions
 * @author Max van Loosbroek
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { getPermissions, putPermissions, deletePermission, getMCQScripts, getAllPermissions } from '../api'
import { Permission, PermissionId } from './types'
import { RootState } from '../../../root.reducer'
import { permissionsAdapter } from './reducer'
import { ScriptVersion } from '../../mScripts/tessaScript/types/ScriptVersion'

export const fetchPermissions = createAsyncThunk('permissions/fetchPermissions', async (robotId: number) => {
  const { data }: AxiosResponse<Permission[]> = await getPermissions(robotId)
  const { data: allPermissions } = await getAllPermissions()
  const permissions: Permission[] = allPermissions.permissions.map((permissionType) => {
    return data.find(p => p.permission === permissionType) ??
      { permission: permissionType, enabledAt: false }
  })  
  return permissions
})

export const fetchMCQScripts = createAsyncThunk('permissions/fetchMCQScripts', async (robotId: number) => {
  const { data: scripts }: AxiosResponse<ScriptVersion[]> = await getMCQScripts(robotId)
  return scripts
})

export const savePermissionState = createAsyncThunk(
  'permissions/addPermissions',
  async ({ robotId }: { robotId: number }, { getState }) => {
    const { permissions } = getState() as RootState
    const permissionsToDelete: PermissionId[] = []
    const permissionsToAdd: PermissionId[] = []
    permissionsAdapter
      .getSelectors()
      .selectAll(permissions.permissions)
      .forEach(p => {
        const pOriginal = permissions.permissionsOriginal.entities[p.permission]
        if (!p.enabledAt && pOriginal?.enabledAt) {
          permissionsToDelete.push(p.permission)
        } else if (p.enabledAt && !pOriginal?.enabledAt) {
          permissionsToAdd.push(p.permission)
        }
      })
    for await (const permissionId of permissionsToDelete) {
      await deletePermission({ robotId, permissionId })
    }
    if (permissionsToAdd.length) {
      const { data: newPermissions }: AxiosResponse<Permission[]> = await putPermissions({
        robotId,
        permissionIds: permissionsToAdd
      })
      return newPermissions
    }
  }
)

import { APP_TIME_SET } from '../constants/time.actionTypes';
import moment from  'moment';

export const initialStateTime = {
  msTime: moment().valueOf()
};

export const appTime = function (state = initialStateTime, action) {
  switch (action.type) {
    case APP_TIME_SET:
      return {...state, ...action.payload};

    default: return state;
  }
};
import i18n from '../../localization/i18n'
import { CategoryItem } from '../mScripts/tessaScriptOverview/types/CategoryItem'
import { CommandEntity } from './SpeechInteractionSingle/redux/reducer'

export const removePunctuation = (s: string): string => {
  const punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g
  const punctuationless = s.replace(punctuation, '')
  return punctuationless.replace(/\s/g, '').toLowerCase().trim()
}

export const commandLengthValid = (commandString: string) => {
  const noSpaces = removePunctuation(commandString)
  const validLength = noSpaces.length > 8 && noSpaces.length < 64
  return validLength
}

export const hasDuplicate = (
  otherCommands: CommandEntity[],
  currentValue: string
) => {
  if (!currentValue.length) {
    return false
  }
  const duplicate = otherCommands.find(
    command =>
      removePunctuation(command.value) ===
      removePunctuation(currentValue)
  )
  return duplicate !== undefined
}

export const hasDuplicateTitle = (
  interactions: CategoryItem[],
  currentTitle: string,
  currentId: string | undefined
) => {
  if (!currentTitle.length) {
    return false
  }
  const duplicate = interactions
    .filter(interaction => {
      return interaction.tempId !== currentId && interaction.category !== 'default'
    })
    .find(i => {
      return (
        removePunctuation(i.name) ===
        removePunctuation(currentTitle)
      )
    })
  return duplicate !== undefined
}

export const getTooltip = (commands: string[]) => {
  let tooltipMessage = `**${i18n.t(
    'SPEECH_INTERACTION.OVERVIEW.TOOLTIP.TITLE'
  )}**`
  commands.forEach(c => {
    tooltipMessage += `\n- – ${c}`
  })
  return tooltipMessage
}

/**
 * @file Unauthenticated page to verify robot contact email
 * @author Max van Loosbroek
 */

import React, { useEffect } from "react"
import { History } from 'history'
import Spinner from '../../../common/components/Spinner/Spinner'
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { OfflineNotificationsState } from "../redux/types"
import { verifyRobotContactThunk } from "../redux/reducer"
import ContentTitle from "../../../common/components/ContentTitle/ContentTitle"
import { ContentDiv } from '../../../common/components/ContentText/ContentText'
import ReactMarkdown from 'react-markdown'
import ButtonGroup from "../../../common/components/ButtonGroup/ButtonGroup"
import { makeStyles } from '@material-ui/core'
import Content from "../../../common/components/Content/Content"
import styledComponentsTheme from "../../../common/constants/styledComponentsTheme/styledComponentsTheme"
import Button from "../../../common/components/Button/Button"


const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    text: {
        marginLeft: '45px !important'
    },
    button: {
        marginTop: 'auto',
        marginBottom: 0
    }
}))

export const VerifyRobotContactComponent = (props: {
    match: any,
    history: History,
    robots: any[],
    user: any,
    verifyRobotContact: ({ robotId, robotContactId, code }: { robotId: string, robotContactId: string, code: string }) => any,
    offlineNotifications: OfflineNotificationsState
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const error = props.offlineNotifications.verifyError
    const expired = error === 'Verification code is expired'
    const notFound = error === 'Code not found'
    const success = props.offlineNotifications.verifySuccess
    const accepting = props.offlineNotifications.verifying
    const code = (new URLSearchParams(window.location.search)).get('code')!
    const robotId = (new URLSearchParams(window.location.search)).get('robotId')!
    const robotContactId = (new URLSearchParams(window.location.search)).get('contactId')!

    const inviteAccept = async () => {
        props.verifyRobotContact({ code, robotId, robotContactId })
    }

    useEffect(() => {
        if(error && !expired && !notFound) {
            props.history.push('/error')
        }
    }, [error, expired, props.history, notFound])

    useEffect(() => {
        if (!success && !accepting && !error) {
            inviteAccept()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history, code, accepting])

    const onSubmit = () => {
        window.location.assign("http://www.tinybots.nl")
    }

    let title: string = ''
    let message: string = ''
    let submit: string = ''
    if (success) {
        title = t('OFFLINE_NOTIFICATIONS.SUCCESS.TITLE')
        message = t('OFFLINE_NOTIFICATIONS.SUCCESS.MESSAGE')
        submit = t('OFFLINE_NOTIFICATIONS.SUCCESS.SUBMIT')
    }
    if (expired) {
        title = t('OFFLINE_NOTIFICATIONS.EXPIRED.TITLE')
        message = t('OFFLINE_NOTIFICATIONS.EXPIRED.MESSAGE')
        submit = t('OFFLINE_NOTIFICATIONS.EXPIRED.SUBMIT')
    }
    if (notFound) {
        title = t('OFFLINE_NOTIFICATIONS.NOT_FOUND.TITLE')
        message = t('OFFLINE_NOTIFICATIONS.NOT_FOUND.MESSAGE')
        submit = t('OFFLINE_NOTIFICATIONS.NOT_FOUND.SUBMIT')
    }

    let backgroundColorButton
    let colorButton
    if (success) {
        backgroundColorButton = styledComponentsTheme.colors.darkgreen
        colorButton = 'white'
    } else if (expired || notFound) {
        backgroundColorButton = styledComponentsTheme.colors.ochre
        colorButton = styledComponentsTheme.colors.darkgrey
    }

    return <>
        {(success || expired || notFound) && <Content hideNav innerProps={{ className: classes.wrapper }}>
            <ContentTitle className={classes.text}><ReactMarkdown source={title}></ReactMarkdown></ContentTitle>
            <ContentDiv className={classes.text}><ReactMarkdown source={message}></ReactMarkdown></ContentDiv>
            <ButtonGroup className={classes.button}>
                {submit &&
                    <Button id="submit-button-invite" style={{ width: '100%', border: 'none', backgroundColor: backgroundColorButton, color: colorButton }} onClick={onSubmit}>
                        {submit}
                    </Button>
                }
            </ButtonGroup>
        </Content>}
        {!error && !success && <Spinner />}
    </>
}

const mapStateToProps = ({ offlineNotifications }: { offlineNotifications: OfflineNotificationsState }) => ({ offlineNotifications })
export default connect(mapStateToProps, { verifyRobotContact: verifyRobotContactThunk })(VerifyRobotContactComponent)
import {
	FORGOT_PASSWORD_CLEAR,
	FORGOT_PASSWORD_CLEAR_ERROR,
	FORGOT_PASSWORD_START,
	FORGOT_PASSWORD_FINISH
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	success: false,
	error: false
};

const forgotPasswordClear = initialState => ({...initialState});

const forgotPasswordClearError = state => ({...state, error: false, done: true});

const forgotPasswordStart = state => ({...state, done: false, success: false});

const forgotPasswordFinish = (state, action) => (
	{
		...state,
		done: true,
		error: action.payload.error,
		success: action.payload.success
	}
);

export const forgotPassword = (state = initialState, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD_CLEAR: return forgotPasswordClear(initialState);
		case FORGOT_PASSWORD_CLEAR_ERROR: return forgotPasswordClearError(state);
		case FORGOT_PASSWORD_START: return forgotPasswordStart(state);
		case FORGOT_PASSWORD_FINISH: return forgotPasswordFinish(state, action);
		default: return state;
	}
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RobotSettingsState, RobotSettings, RobotSettingsPatch } from './types'
import {
  getRobotSettings,
  patchRobotSettings,
  patchEndUserName,
  patchAlias
} from '../api'
import { fetchRobots } from '../../auth/redux/actions'
import { RootState } from '../../../root.reducer'
import { aliasLengthValid, endUserNameLengthValid } from '../RobotProfile'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../../common/constants'

const initialState: RobotSettingsState = {
  getting: true,
  getError: false,
  patching: false,
  patchError: false
}

let settingsTimeOut: NodeJS.Timeout

export const fetchSettings = createAsyncThunk(
  'robotSettings/fetchSettings',
  async (robotId: string, { dispatch }) => {
    clearTimeout(settingsTimeOut)
    const response: { data: RobotSettings } = await getRobotSettings(robotId)
    if (response.data && Object.keys(response.data).length > 0) {
      return response.data
    } else {
      settingsTimeOut = setTimeout(() => {
        dispatch(fetchSettings(robotId))
      }, 1500)
      return undefined
    }
  }
)

export const patchSettings = createAsyncThunk(
  'robotSettings/patchSettings',
  async (
    {
      canPatchSettings,
      authUserIsPrimary,
      robotId,
      patch,
      profilePatch: { endUserName },
      robotUserPatch: { alias }
    }: {
      canPatchSettings: boolean
      authUserIsPrimary: boolean
      robotId: string
      patch: Partial<RobotSettingsPatch>
      profilePatch: { endUserName?: string }
      robotUserPatch: { alias?: string }
    },
    thunkApi
  ) => {
    const { robots } = thunkApi.getState() as RootState
    const endUserNameChanged = robots[0].endUserName !== endUserName
    if (
      endUserNameLengthValid(endUserName) &&
      endUserNameChanged &&
      authUserIsPrimary
    ) {
      await patchEndUserName({ robotId, endUserName: endUserName as string })
    }
    const aliasChanged = robots[0].alias !== alias
    if (alias === '' && aliasChanged) {
      await patchAlias({ robotId, alias: null })
    } else if (aliasLengthValid(alias) && aliasChanged) {
      await patchAlias({ robotId, alias: alias as string })
    }
    let data: Partial<RobotSettings> = {}
    if (canPatchSettings) {
      const response = await patchRobotSettings(robotId, patch)
      data = response.data
    }
    await thunkApi.dispatch(fetchRobots())
    return data
  }
)

export const robotSettingsSlice = createSlice({
  name: 'robotSettings',
  initialState: initialState,
  reducers: {
    clearError: state => ({ ...state, getError: false, patchError: false })
  },
  extraReducers: builder => {
    builder.addCase(fetchSettings.pending, state => ({
      ...state,
      getting: true,
      getError: false
    }))
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      moment.tz.setDefault(action.payload?.timeZone ?? DEFAULT_TIMEZONE)
      return {
        ...state,
        getting: false,
        getError: false,
        settings: action.payload ? { ...action.payload } : undefined
      }
    })
    builder.addCase(fetchSettings.rejected, state => ({
      ...state,
      getting: false,
      getError: true
    }))
    builder.addCase(patchSettings.pending, state => ({
      ...state,
      patching: true,
      getError: false
    }))
    builder.addCase(patchSettings.fulfilled, (state, action) => {
      moment.tz.setDefault(action.payload.timeZone ?? DEFAULT_TIMEZONE)
      return {
        ...state,
        patching: false,
        patchError: false,
        settings: { ...state.settings, ...(action.payload as RobotSettings) }
      }
    })
    builder.addCase(patchSettings.rejected, state => ({
      ...state,
      patching: false,
      patchError: true
    }))
  }
})

// Extract the action creators object and the reducer
const { reducer } = robotSettingsSlice

export const robotSettings = reducer

export const robotLanguageSelector = (
  state: Pick<RootState, 'tessaScriptOverview' | 'robotSettings'> | RootState
) => state.robotSettings?.settings?.defaultLanguage

/**
 * @file Helpers for app notifications feature
 * @author Max van Loosbroek
 */

import { compare } from 'compare-versions'
import { getEnvConfig } from '../../common/constants';

export const isAppValidVersion = (minVersion: string) => {
    const { version } = getEnvConfig();
    return compare(version, minVersion, '>=')
}
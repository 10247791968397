import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const RoutesWrapper = styled.div`
	flex: 1;
	position: relative;
	z-index: 0;
	height: 100%;
	max-height: calc(100% - 56px);
`; // height: 100%; ios < 10 fix ipad air 2

// eslint-disable-next-line react/prop-types
const PrivateRouteWrapper = ({ common, children }) => (
	<Wrapper>
		{common}
		<RoutesWrapper>{children}</RoutesWrapper>
	</Wrapper>
);

export default PrivateRouteWrapper;

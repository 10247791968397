import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "../../generalSettings/UserProfile/redux/reducer";
import { getEula, getLatestEula, postAcceptEula } from "../api";
import { EulaDto } from "../types";

export interface EulaState {
    getting: boolean;
    getError: boolean;
    patching: boolean;
    patchError: boolean;
    userEula?: EulaDto;
    latestEula?: EulaDto;
};

export const initialState: EulaState = {
    getting: true,
    getError: false,
    patching: false,
    patchError: false
};

export const clearState = createAction('eulaReducer/clearState')
export const clearError = createAction('eulaReducer/clearError')

export const fetchEulas = createAsyncThunk(
    'eulaState/fetchEulas',
    async (eulaId?: number) => {
        const userEula: EulaDto | undefined = eulaId !== undefined ? (await getEula(eulaId)).data : undefined;
        const latestEula: EulaDto = (await getLatestEula()).data;
        return { userEula, latestEula }
    }
);

export const acceptEula = createAsyncThunk(
    'eulaState/acceptEula',
    async (_, thunkAPI) => {
        await postAcceptEula();
        await thunkAPI.dispatch(fetchUserData());
        return;
    }
);

export const eulaStateSlice = createSlice({
    name: 'eulaState',
    initialState: initialState,
    reducers: {
        clearError: state => ({ ...state, getError: false, patchError: false }),
    },
    extraReducers: builder => {
        builder.addCase(clearState, () =>
            initialState
        );
        builder.addCase(clearError, state => {
            state.getError = false
            state.patchError = false
        });
        builder.addCase(fetchEulas.pending, (state) => {
            state.getting = true
            state.getError = false
        });
        builder.addCase(fetchEulas.rejected, (state) => {
            state.getting = false
            state.getError = true
        });
        builder.addCase(fetchEulas.fulfilled, (state, action) => {
            state.getting = false
            state.getError = false
            state.userEula = action.payload.userEula
            state.latestEula = action.payload.latestEula
        });
        builder.addCase(acceptEula.pending, (state) => {
            state.patching = true
            state.patchError = false
        });
        builder.addCase(acceptEula.rejected, (state) => {
            state.patching = false
            state.patchError = true
        });
        builder.addCase(acceptEula.fulfilled, (state, action) => {
            state.patching = false
            state.patchError = false
        });
    }

});

// Extract the action creators object and the reducer
const { reducer } = eulaStateSlice;

export const eula = reducer;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getBots, getUserSelf } from '../../../auth/api';
import { RelationRole } from '../../RobotUsers/redux/types';
import { patchProfileSelf, patchRelationRole } from '../api';
import { UserProfile, UserProfileState } from './types';

export const initialState: UserProfileState = {
    getting: true,
    getError: false,
    patching: false,
    patchError: false,
    robots: []
};

export const clearState = createAction('userProfile/clearState')
export const clearError = createAction('userProfile/clearError')
export const setClientRelationRole = createAction<{ robotId: number, clientRelationRole: RelationRole }>('userProfile/setClientRelationRoleValue')
export const setProfile = createAction<UserProfile>('userProfile/setProfile')

export const fetchProfileRobots = createAsyncThunk(
    'userProfile/fetchProfileRobots',
    async () => {
        let { data: robots } = await getBots();
        return { robots };
    }
);

export const fetchUserData = createAsyncThunk(
    'userProfile/fetchUserData',
    async () => {
        const user = await getUserSelf();
        const { firstName, lastName, phoneNumber, eulaVersionId, eulaUpToDate } = user.data;
        return { firstName, lastName, phoneNumber: `+${phoneNumber}`, eulaVersionId, eulaUpToDate }
    }
);

export const patchProfile = createAsyncThunk(
    'userProfile/patchProfile',
    async ({ robotId, clientRelationRole, profile }: { robotId?: number, clientRelationRole?: RelationRole, profile?: UserProfile }) => {
        if (clientRelationRole && robotId) await patchRelationRole({ robotId, clientRelationRole });
        if (profile) await patchProfileSelf(profile)
    }
);

export const robotUserSlice = createSlice({
    name: 'robotUser',
    initialState: initialState,
    reducers: {
        clearError: state => ({ ...state, getError: false, patchError: false }),
    },
    extraReducers: builder => {
        builder.addCase(setClientRelationRole, (state, action) => {
            state.robots = state.robots.map(robot => {
                if (robot.id === action.payload.robotId) {
                    robot.clientRelationRole = action.payload.clientRelationRole
                }
                return robot
            })
        })
        builder.addCase(setProfile, (state, action) => {
            state.profile = action.payload
        })
        builder.addCase(clearState, () =>
            initialState
        );
        builder.addCase(clearError, state => {
            state.getError = false
            state.patchError = false
        });
        builder.addCase(fetchProfileRobots.pending, (state) => {
            state.getting = true
            state.getError = false
        });
        builder.addCase(fetchProfileRobots.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
                getting: false,
                getError: false
            }
        });
        builder.addCase(fetchUserData.rejected, (state) => {
            state.getting = false
            state.getError = true
        });
        builder.addCase(fetchUserData.pending, (state) => {
            state.getting = true
            state.getError = false
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.getting = false
            state.getError = false
            state.profile = action.payload
        });
        builder.addCase(fetchProfileRobots.rejected, (state) => {
            state.getting = false
            state.getError = true
        });
        builder.addCase(patchProfile.pending, (state) => {
            state.patching = true
            state.patchError = false
        });
        builder.addCase(patchProfile.fulfilled, (state) => {
            state.patching = false
            state.patchError = false
        });
        builder.addCase(patchProfile.rejected, (state, action) => {
            state.patching = false
            state.patchError = true
        });

    }

});

// Extract the action creators object and the reducer
const { reducer } = robotUserSlice;

export const userProfile = reducer;
import moment from 'moment';

export const isTokenExpired = session => {
  let isExpired = false;
  if (session) {
    const tokenCreationTime = session.token_creation_time ? moment(session.token_creation_time) : null;
    if (tokenCreationTime) {
      const expiresIn = session.expires_in || 7200;
      const expirationDate = moment(tokenCreationTime + moment(expiresIn * 1000) - 600000);
      isExpired = expirationDate.isBefore(moment());
    }
  }
  return isExpired;
};

export const isAuthenticated = token => {
  if (token) {
    if (token.split('.').length === 3) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const exp = JSON.parse(window.atob(base64)).exp;
        if (typeof exp === 'number') {
          return Math.round(new Date().getTime() / 1000) < exp;
        }
      } catch (e) {
        return true; // Pass: Non-JWT token that looks like JWT
      }
    }
    return true; // Pass: All other tokens
  }
  return false; // Fail: No token at all
};

import { createTheme } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';

export const baseTheme = createTheme({
    palette: {
      type: 'dark',
      primary: teal,
      secondary: amber,
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          position: 'absolute',
          bottom: '-18px',
          right: 0,
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: '24px',
          width: '24px'
        }
      }
    },
  });

export const materialThemeScriptSingle = createTheme({
    palette: {
        type: 'light',
        primary: teal,
        secondary: amber,
    },
});
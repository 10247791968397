/**
 * @file Select parts of the feature state or transformed state
 * @author Max van Loosbroek
 */

import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../../../root.reducer"
import { commandLengthValid, hasDuplicate, hasDuplicateTitle } from "../../helpers"
import { SpeechInteractionDto } from "../../types"
import { commandsAdapter } from "./reducer"
import { selectInteractions } from "../../SpeechInteractionOverview/redux/reducer"

const speechInteractionSingleSelector = (state: RootState) =>
  state.speechInteractionSingle
export const loadingSelector = createSelector(
  speechInteractionSingleSelector,
  state => {
    return state.getting || state.patching
  }
)
export const getErrorSelector = createSelector(
  speechInteractionSingleSelector,
  state => {
    return state.getError
  }
)
export const patchErrorSelector = createSelector(
  speechInteractionSingleSelector,
  state => {
    return state.patchError
  }
)
export const scriptSelector = createSelector(
  speechInteractionSingleSelector,
  state => {
    return state.script
  }
)
export const interactionSelector = createSelector(
  speechInteractionSingleSelector,
  state => {
    return state.speechInteraction
  }
)
export const commandsSelector = (state: RootState) =>
  commandsAdapter
    .getSelectors()
    .selectAll(state.speechInteractionSingle.commands)

export const titleValidSelector = createSelector(
  interactionSelector,
  selectInteractions,
  (speechInteraction, interactions) => {
    const duplicateTitleError = hasDuplicateTitle(interactions, speechInteraction.title, speechInteraction.id?.toString())
    const titleValid =
     !duplicateTitleError &&
      speechInteraction.title &&
      speechInteraction.title.length > 2 &&
      speechInteraction.title.length < 128
    return titleValid as boolean
  }
)

export const scriptValidSelector = createSelector(
  scriptSelector,
  interactionSelector,
  (script, speechInteraction) => {
    const scriptValid =
      !!script?.scriptReferenceId && !!speechInteraction.scriptReferenceId
    return scriptValid as boolean
  }
)

export const commandsEmptySelector = createSelector(
  commandsSelector,
  commands => {
    const commandsEmpty = !commands.find(c => c.value?.length > 0)
    return commandsEmpty as boolean
  }
)

export const commandsValidSelector = createSelector(
  commandsSelector,
  commands => {
    const commandsValid = commands.every(
      (currentCommand, currentIndex) => {
        const otherCommands = commands.filter((_, i) => i !== currentIndex)
        const duplicateError = hasDuplicate(otherCommands, currentCommand.value)
        const isEmpty = currentCommand.value.length === 0
        const validLength = commandLengthValid(currentCommand.value)
        const noSimilarityError = !currentCommand.similarityError
        return (isEmpty || validLength) && noSimilarityError && !duplicateError
      }
    )
    return commandsValid as boolean
  }
)
export const validSelector = createSelector(
  titleValidSelector,
  commandsValidSelector,
  scriptValidSelector,
  commandsEmptySelector,
  (titleValid, commandsValid, scriptValid, commandsEmpty) => {
    return titleValid && commandsValid && scriptValid && !commandsEmpty
  }
)
export const putInteractionSelector = createSelector(
  interactionSelector,
  commandsSelector,
  (speechInteraction, commands) => {
    // Remove robotId
    const { robotId, ...interaction } = speechInteraction
    return {
      ...interaction,
      commands: commands.map(c => c.value).filter(c => !!c)
    } as SpeechInteractionDto
  }
)

/**
 * @file Robot serial, alias and endusername
 * @author Max van Loosbroek
 */

import React, { useState, useEffect, ChangeEvent } from 'react'
import { ProfileRobotUser } from '../generalSettings/UserProfile/redux/types'
import {
  Item,
  LeftColumn,
  MiddleColumn,
  RightColumn
} from '../../common/components/ItemList/ItemList'
import { TooltipMaterial } from '../../common/components/TooltipMaterial/TooltipMaterial'
import { SvgIcon, TextField, makeStyles, createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactComponent as RobotIcon } from '../../assets/images/logo/tessa_creme.svg'
import styledComponentsTheme from '../../common/constants/styledComponentsTheme/styledComponentsTheme'
import { isEmpty } from 'lodash'
import { usePrevious } from '../../common/hooks/usePreviousValue'
import fastDeepEqual from 'fast-deep-equal'

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: styledComponentsTheme.colors.cremewhite,
      width: '100%'
    },
    inputContainer: {
      flexGrow: 1,
      marginLeft: 0
    },
    form: {
      flexGrow: 1,
      lineHeight: '20px',
      display: 'flex',
      flexWrap: 'wrap'
    },
    root: {
      width: '100%',
      flexGrow: 1,
      '&::before': {
        opacity: 0.4
      }
    },
    flex: {
      flexGrow: 1,
      display: 'flex'
    },
    disabled: {
      color: styledComponentsTheme?.colors.lightgrey,
      pointerEvents: 'none'
    }
  })
)

export interface RobotProfileProps {
  robot: ProfileRobotUser
  setProfilePatch: React.Dispatch<React.SetStateAction<{ endUserName?: string | undefined }>>
  setRobotUserPatch: React.Dispatch<React.SetStateAction<{ alias?: string | undefined }>>
  onSubmit: (...args: any) => any
  showProfile: boolean
  setShowProfile: (show: boolean) => any
  authUserIsPrimary: boolean
}

const SvgIconStyle = {
  fontSize: '22px'
}

const minLength = 2
const maxLengthAlias = 26
const maxLengthEndUserName = 64

export const endUserNameLengthValid = (endUserName?: string): boolean => {
  if (endUserName === undefined || endUserName === null) return false
  return endUserName.length >= minLength && endUserName.length <= maxLengthEndUserName
}

export const aliasLengthValid = (alias?: string): boolean => {
  if (alias === undefined || alias === null) return false
  return alias.length >= minLength && alias.length <= maxLengthAlias
}

export const RobotProfile: React.FunctionComponent<RobotProfileProps> = ({
  robot,
  setProfilePatch,
  setRobotUserPatch,
  onSubmit,
  showProfile,
  setShowProfile,
  authUserIsPrimary
}: RobotProfileProps) => {
  const classes = useStyles()
  const [endUserName, setEndUserName] = useState<string | undefined>(
    robot.endUserName !== null ? robot.endUserName : undefined
  )
  const [alias, setAlias] = useState<string>(
    robot.alias === null ? robot.robot : robot.alias
  )
  const previousRobot = usePrevious(robot)
  const aliasError = !isEmpty(alias) && !aliasLengthValid(alias)
  const endUserNameError = endUserName !== null && endUserName !== undefined && !endUserNameLengthValid(endUserName)
  const { t } = useTranslation()
  const helperTextEndUserName = t('ROBOT_SETTINGS.PROFILE.ERROR_END_USER')
  const helperTextAlias = t('ROBOT_SETTINGS.PROFILE.ERROR_ALIAS')

  useEffect(() => {
    setProfilePatch({ endUserName })
    setRobotUserPatch({ alias })
  }, [endUserName, alias])

  useEffect(() => {
    if (!fastDeepEqual(robot, previousRobot)) {
      setEndUserName(robot.endUserName ?? undefined)
      setAlias(robot.alias ?? '')
    }
  }, [previousRobot, robot])

  return (
    <>
      <Item height={32} onClick={() => setShowProfile(!showProfile)}>
        <LeftColumn>
          <TooltipMaterial
            placement='bottom-start'
            title={t('ROBOT_SETTINGS.TOOLTIPS.SERIAL')}
          >
            <SvgIcon
              style={SvgIconStyle}
              component={RobotIcon}
              viewBox='0 0 20 23'
            />
          </TooltipMaterial>
        </LeftColumn>
        <MiddleColumn
          style={{
            fontFamily: 'Roboto-Mono, monospace',
            textDecoration: 'underline'
          }}
        >
          <span style={{ fontWeight: 200 }}>
            {robot.robot
              .match(/.{1,4}/g)
              ?.splice(0, 3)
              .join(' ')}
          </span>
          &nbsp;
          <span>
            {robot.robot
              .match(/.{1,4}/g)
              ?.splice(3, 3)
              .join(' ')}
          </span>
        </MiddleColumn>
      </Item>
      <div style={{ display: showProfile ? 'block' : 'none' }}>
        <Item disabled={!authUserIsPrimary} height='auto' style={{ marginTop: 10 }}>
          <LeftColumn />
          <MiddleColumn className={classes.flex}>
            <form className={classes.form} onSubmit={onSubmit}>
              <span style={{ paddingTop: 5 }}>
                {t('ROBOT_SETTINGS.PROFILE.FORM.END_USER.LABEL')}:&nbsp;
              </span>
              <div className={classes.inputContainer}>
                <TextField
                  disabled={!authUserIsPrimary}
                  error={endUserNameError}
                  classes={{ root: classes.root }}
                  inputProps={{
                    'aria-label': 'End user name input'
                  }}
                  InputProps={{
                    classes: {
                      root: classes.input,
                      disabled: classes.disabled
                    },
                    disableUnderline: !authUserIsPrimary
                  }}
                  type='text'
                  id='endUserName'
                  value={!authUserIsPrimary ? endUserName ?? '-' : endUserName}
                  onChange={(
                    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setEndUserName(e.target.value)}
                  placeholder={t('ROBOT_SETTINGS.PROFILE.FORM.END_USER.PLACEHOLDER')}
                  helperText={endUserNameError ? helperTextEndUserName : ''}
                />
              </div>
            </form>
          </MiddleColumn>
          <RightColumn />
        </Item>
        <Item height='auto' style={{ marginTop: 10, marginBottom: 20 }}>
          <LeftColumn />
          <MiddleColumn className={classes.flex}>
            <form className={classes.form} onSubmit={onSubmit}>
              <span style={{ paddingTop: 5 }}>
                {t('ROBOT_SETTINGS.PROFILE.FORM.ALIAS.LABEL')}:&nbsp;
              </span>
              <div className={classes.inputContainer}>
                <TextField
                  error={aliasError}
                  classes={{ root: classes.root }}
                  inputProps={{
                    className: classes.input,
                    'aria-label': 'Alias input'
                  }}
                  type='text'
                  id='alias'
                  value={alias}
                  onChange={e => setAlias(e.target.value)}
                  helperText={aliasError ? helperTextAlias : ''}
                />
              </div>
            </form>
          </MiddleColumn>
          <RightColumn />
        </Item>
      </div>
    </>
  )
}

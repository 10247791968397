export const SET_USER = 'SET_USER'
export const ENABLE_MFA = 'ENABLE_MFA'
export const ENABLE_MFA_SUCCESS = 'ENABLE_MFA_SUCCESS'
export const ENABLE_MFA_ERROR = 'ENABLE_MFA_ERROR'
export const DISABLE_MFA = 'DISABLE_MFA'
export const DISABLE_MFA_SUCCESS = 'DISABLE_MFA_SUCCESS'
export const DISABLE_MFA_ERROR = 'DISABLE_MFA_ERROR'
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR'

export const RESET_ROBOT = 'RESET_ROBOT'
export const RESET_ROBOT_SUCCESS = 'RESET_ROBOT_SUCCESS'
export const RESET_ROBOT_ERROR = 'RESET_ROBOT_ERROR'

export const INVITE_PAIR_ROBOT = 'INVITE_PAIR_ROBOT'
export const INVITE_PAIR_ROBOT_SUCCESS = 'INVITE_PAIR_ROBOT_SUCCESS'
export const INVITE_PAIR_ROBOT_ERROR = 'INVITE_PAIR_ROBOT_ERROR'

export const RESET_ROBOT_STATUS = 'RESET_ROBOT_STATUS'
export const RESET_ROBOT_SUCCESS_STATUS = 'RESET_ROBOT_SUCCESS_STATUS'
export const RESET_ROBOT_ERROR_STATUS = 'RESET_ROBOT_ERROR_STATUS'

export const SET_ROBOTS = 'SET_ROBOTS'
export const SET_ROBOT_CONFIG = 'SET_ROBOT_CONFIG'
export const SET_ACTIVE_ROBOT = 'SET_ACTIVE_ROBOT'
export const SET_LOADING = 'SET_LOADING'
export const SET_LOADING_FINISHED = 'SET_LOADING_FINISHED'
export const ROBOTS_HIDE_UPDATE_BANNER = 'ROBOTS_HIDE_UPDATE_BANNER'
export const ROBOTS_SET_UPDATE_STATUS = 'ROBOTS_SET_UPDATE_STATUS'
export const ROBOTS_SHOW_UPDATE_POPUP = 'ROBOTS_SHOW_UPDATE_POPUP'

export const SESSION_CREATE = 'SESSION_CREATE'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'
export const SESSION_GET_DATA_START = 'SESSION_GET_DATA_START'
export const SESSION_GET_DATA_FINISH = 'SESSION_GET_DATA_FINISH'
export const SESSION_ACTIVATE_ACCOUNT = 'SESSION_ACTIVATE_ACCOUNT'
export const SESSION_ENABLE_ACCOUNT_ACTIVATION = 'SESSION_ENABLE_ACCOUNT_ACTIVATION'
export const SESSION_CLEAR_ERROR = 'SESSION_CLEAR_ERROR'

export const SET_EULA_EXPIRED = 'SET_EULA_EXPIRED'

import {
	LOGIN_CLEAR,
	LOGIN_CLEAR_ERROR,
	LOGIN_START,
	LOGIN_ERROR,
	LOGIN_REQUIRE_MFA
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	requireMFA: false,
	errorAuth: '',
	errorOther: ''
};

const loginClear = clearedState => clearedState;

const loginClearError = state => ({...state, errorAuth: '', errorOther: ''});

const loginStart = state => ({...state, done: false});

const loginRequireMFA = state => ({...state, done: true, requireMFA: true});

const loginError = (state, action) => ({
	...state,
	done: true,
	requireMFA: false,
	errorAuth: action.payload.errorAuth,
	errorOther: action.payload.errorOther
});

export const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_CLEAR: return loginClear(initialState);
		case LOGIN_CLEAR_ERROR: return loginClearError(state);
		case LOGIN_START: return loginStart(state);
		case LOGIN_REQUIRE_MFA: return loginRequireMFA(state);
		case LOGIN_ERROR: return loginError(state, action);
		default: return state;
	}
};

export const SONGS_OVERVIEW_CLEAR = 'SONGS_OVERVIEW_CLEAR';
export const SONGS_OVERVIEW_SET = 'SONGS_OVERVIEW_SET';
export const SONGS_OVERVIEW_GET = 'SONGS_OVERVIEW_GET';
export const SONGS_OVERVIEW_GET_ERROR = 'SONGS_OVERVIEW_GET_ERROR';
export const SONGS_OVERVIEW_SET_FREE_SPACE = 'SONGS_OVERVIEW_SET_FREE_SPACE';
export const SONGS_OVERVIEW_SELECT = 'SONGS_OVERVIEW_SELECT';
export const SONGS_OVERVIEW_SEARCH = 'SONGS_OVERVIEW_SEARCH';
export const SONGS_OVERVIEW_GET_PLAYLISTS = 'SONGS_OVERVIEW_GET_PLAYLISTS';
export const SONGS_OVERVIEW_GET_PLAYLISTS_ERROR = 'SONGS_OVERVIEW_GET_PLAYLISTS_ERROR';
export const SONGS_OVERVIEW_SET_PLAYLISTS = 'SONGS_OVERVIEW_SET_PLAYLISTS';
export const SONGS_OVERVIEW_HIDE_POP_UP = 'SONGS_OVERVIEW_HIDE_POP_UP';
export const SONGS_OVERVIEW_DELETE_SONG = 'SONGS_OVERVIEW_DELETE_SONG';
export const SONGS_OVERVIEW_DELETE_SONG_SUCCESS = 'SONGS_OVERVIEW_DELETE_SONG_SUCCESS';
export const SONGS_OVERVIEW_DELETE_SONG_ERROR = 'SONGS_OVERVIEW_DELETE_SONG_ERROR';

import { HEARTBEAT_SET } from '../constants/heartbeat.actionTypes';
import { SESSION_LOGOUT } from '../../../features/auth/redux/constants/actionTypes';

export const initialStateHeartbeat = {
  status: 'noConnection',
  duration: null,
  isRobotOffline: true,
  timerStarted: false
};

const heartbeatClear = initialState => ({...initialState});

const heartbeatSet = (state, action) => ({...state, ...action.payload, timerStarted: true});

export const heartbeat = function(state = initialStateHeartbeat, action) {
  switch (action.type) {
    case SESSION_LOGOUT: return heartbeatClear(initialStateHeartbeat);
    case HEARTBEAT_SET: return heartbeatSet(state, action);
    default: return state
  }
};

import axios from "axios";
import { getUrls } from "../../common/constants";
import { EulaDto } from "./types";

export const getLatestEula = () => {
    const url = getUrls('prowl', 'latestEula');
    return axios.get<EulaDto>(url);
};

export const getEula = (eulaId: number) => {
    const url = getUrls('prowl', 'eula').replace('{eulaId}', eulaId);
    return axios.get<EulaDto>(url);
};

export const postAcceptEula = () => {
    const url = getUrls('prowl', 'acceptEula');
    return axios.post<EulaDto>(url);
};
/**
 * @file Script more menu (for removing script steps)
 */

import {
  ButtonBase,
  createStyles,
  makeStyles,
  Popover
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useRef, useState } from 'react'
import OverflowMenuItem from '../../../common/components/OverflowMenu/OverflowMenuItem/OverflowMenuItem'

export const useMoreMenuStyles = makeStyles(() =>
    createStyles({
        moreMui: {
            padding: '0px 16px',
            margin: 0,
            '&:after': {
                display: 'block',
                position: 'absolute',
                backgroundColor: '$cremewhite',
                content: '\\00a0',
                height: 14,
                width: 14,
            }
        },
        moreWrapper: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 'auto',
        },
        more: {
            width: 34,
            height: 34,
            borderRadius: 34,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        item: {
          padding: '0 16px'
        }
    })
);

interface MoreMenuProps {
  uuid: string
  moreMenuItems: Array<{
    type: string
    text: string
    fn: () => any
  }>
  open?: boolean
}

export const MoreMenu: React.FunctionComponent<MoreMenuProps> = ({
  uuid,
  moreMenuItems,
  open
}: MoreMenuProps) => {
  const moreBtnRef = useRef(null)
  const anchorEl = moreBtnRef.current ?? undefined
  const classes = useMoreMenuStyles()
  const [moreMenuVisible, setOverflowMenuVisible] = useState(false)
  const more = (e: any): void => {
    e.stopPropagation()
    e.preventDefault()
    setOverflowMenuVisible(true)
  }

  return (
    <>
      <div
        onClick={more}
        className={classes.moreWrapper}
        style={{ cursor: 'pointer' }}
      >
        <ButtonBase className={classes.more} ref={moreBtnRef} onClick={more} aria-label='More Menu Toggle'>
          <MoreVert style={{ fontSize: '20px' }} />
        </ButtonBase>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={moreMenuVisible}
        onClose={() => setOverflowMenuVisible(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.moreMui}>
          {moreMenuItems.map((omItem, i) => (
            <OverflowMenuItem
              className={classes.item}
              key={`${uuid}_menu_item${i}`}
              type={omItem.type}
              text={omItem.text}
              clicked={() => {
                setOverflowMenuVisible(false)
                omItem.fn()
              }}
            />
          ))}
        </div>
      </Popover>
    </>
  )
}

import {
	SIGN_UP_CLEAR,
	SIGN_UP_SAVE_STEP,
	SIGN_UP_SET_PASSWORD_STRENGTH,
	SIGN_UP_SHOW_WEAK_PASSWORD_POP_UP,
	SIGN_UP_HIDE_WEAK_PASSWORD_POP_UP,
	SIGN_UP_HIDE_EMAIL_EXISTS_POP_UP,
	SIGN_UP_START,
	SIGN_UP_FINISH
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	error: false,
	nameStepDone: false,
	contactsStepDone: false,
	passwordStepDone: false,
	showEmailExistsPopUp: false,
	passwordStrength: '',
	weakPassword: '',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	termsAccepted: false
};

const signUpClear = initialState => initialState;

const signUpSaveStep = (state, action) => ({
	...state,
	[`${action.payload.stepName}StepDone`]: true,
	...action.payload.fields
});

const signUpSetPasswordStrength = (state, action) => ({
	...state,
	passwordStrength: action.payload ? action.payload >= 2E12 ? 'strong' : 'weak' : ''
});

const signUpShowWeakPasswordPopUp = (state, action) => ({...state, weakPassword: action.payload});

const signUpHideWeakPasswordPopUp = state => ({...state, weakPassword: ''});

const signUpHideEmailExistsPopUp = state => ({...state, showEmailExistsPopUp: false});

const signUpStart = state => ({...state, done: false, showEmailExistsPopUp: false, weakPassword: ''});

const signUpFinish = (state, action) => ({...state, done: true, ...action.payload});

export const signup = (state = initialState, action) => {
	switch (action.type) {
		case SIGN_UP_CLEAR: return signUpClear(initialState);
		case SIGN_UP_SAVE_STEP: return signUpSaveStep(state, action);
		case SIGN_UP_SET_PASSWORD_STRENGTH: return signUpSetPasswordStrength(state, action);
		case SIGN_UP_SHOW_WEAK_PASSWORD_POP_UP: return signUpShowWeakPasswordPopUp(state, action);
		case SIGN_UP_HIDE_WEAK_PASSWORD_POP_UP: return signUpHideWeakPasswordPopUp(state);
		case SIGN_UP_HIDE_EMAIL_EXISTS_POP_UP: return signUpHideEmailExistsPopUp(state);
		case SIGN_UP_START: return signUpStart(state);
		case SIGN_UP_FINISH: return signUpFinish(state, action);
		default: return state;
	}
};

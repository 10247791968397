import {
  registerDecorator,
  ValidationOptions,
  ValidationArguments
} from 'class-validator'
import { hasText } from '../helpers/helpers'
import { sanitizeHTML } from '../helpers/uiHelpers'

export function htmlNotEmpty (validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'hasText',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate (value: any, _args: ValidationArguments) {
          return typeof value === 'string' && hasText(sanitizeHTML(value)) // you can return a Promise<boolean> here as well, if you want to make async validation
        }
      }
    })
  }
}

import axios from "axios";
import { getUrls } from "../../../common/constants";
import { RelationRole } from "../RobotUsers/redux/types";
import { ProfileRobotUser, UserProfile } from "./redux/types";

export const getRobotsSelfV2 = () => {
    const url = getUrls('checkpoint', 'listbotsV2');
    return axios.get<ProfileRobotUser[]>(url);
};

export const patchProfileSelf = (profile: Partial<UserProfile>) => {
    const url = getUrls('prowl', 'selfProfile');
    return axios.patch<ProfileRobotUser[]>(url, profile);
};

export const patchRelationRole = ({ robotId, clientRelationRole }: { robotId: number, clientRelationRole: RelationRole }) => {
    const url = getUrls('checkpoint', 'robot-user-self').replace('{robotId}', robotId);
    return axios.patch<{clientRelationRole: RelationRole}>(url, { clientRelationRole });
};

export const patchPassword = ({ password, otpCode, newPassword }: { password: string, newPassword: string, otpCode?: string }) => {
    const url = getUrls('prowl', 'selfV3');
    return axios.patch<any>(url, { password, otpCode, newPassword });
};

export const deleteUserAccount = ({ password, otpCode }: { password: string, otpCode?: string }) => {
    const url = getUrls('prowl', 'deleteSelfV3');
    return axios.post<any>(url, { password, otpCode });
};

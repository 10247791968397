import { Type } from 'class-transformer'
import { ArrayMaxSize, IsArray, IsBoolean, IsInt, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BaseScriptNode, ScriptNode, ScriptNodeTTS, ScriptNodeWait, ScriptStoreNode } from './ScriptNode'
import { ScriptStepDTO, ScriptStoreStep } from './ScriptStep'
import { BaseNext, NextClosedQuestion, NextThen, ScriptNext, ScriptStoreNext } from './Next'

export class ScriptVersion {
    @IsOptional()
    @IsInt()
    tempId?: string

    @IsOptional()
    @IsInt()
    id?: number

    @IsOptional()
    @IsInt()
    deprecatedId?: number

    @IsOptional()
    @IsInt()
    scriptReferenceId?: number

    @IsString()
    scriptName: string

    @IsString()
    scriptLanguage: string

    @IsString()
    scriptCategory?: string

    @IsOptional()
    @IsInt()
    duration?: number

    @IsOptional()
    @IsInt()
    scriptTemplateId?: number

    @IsOptional()
    @IsBoolean()
    scheduled?: boolean

    @IsOptional()
    @IsString()
    templateGroup?: string
}

export class ScriptVersionPut {
  @IsOptional()
  @IsInt()
  scriptReferenceId?: number

  @IsOptional()
  @IsString()
  scriptCategory?: string

  @IsString()
  scriptName: string

  @IsString()
  scriptLanguage: string

  @IsOptional()
  @IsInt()
  scriptTemplateId?: number
}

export class FullScriptPut {
    @ValidateNested()
    script: ScriptVersionPut;

    @ArrayMaxSize(256)
    @IsArray()
    @ValidateNested()
    scriptSteps: ScriptStepDTO[];

    @ArrayMaxSize(256)
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => BaseScriptNode, {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: 'nodeType',
        subTypes: [
          { value: ScriptNodeWait, name: 'wait' },
          { value: ScriptNodeTTS, name: 'tts' }
        ]
      }
    })
    scriptNodes: ScriptNode[];

    @ArrayMaxSize(1024)
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => BaseNext, {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: 'nextType',
        subTypes: [
          { value: NextClosedQuestion, name: 'closedQuestion' },
          { value: NextThen, name: 'then' }
        ]
      }
    })
    scriptNext: ScriptNext[];
}

export class FullScriptGet {
  @ValidateNested()
  script: ScriptVersion;

  @IsArray()
  @ValidateNested()
  scriptSteps: ScriptStepDTO[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => BaseScriptNode, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'nodeType',
      subTypes: [
        { value: ScriptNodeWait, name: 'wait' },
        { value: ScriptNodeTTS, name: 'tts' }
      ]
    }
  })
  scriptNodes: ScriptNode[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => BaseNext, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'nextType',
      subTypes: [
        { value: NextClosedQuestion, name: 'closedQuestion' },
        { value: NextThen, name: 'then' }
      ]
    }
  })
  scriptNext: ScriptNext[];
}

export interface FullScriptState {
  script: ScriptVersion;
  scriptSteps: ScriptStoreStep[];
  scriptNodes: ScriptStoreNode[];
  scriptNext: ScriptStoreNext[];
}

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const PopUpButton = styled.button`
  font-family: Montserrat;
  min-width: 100px;
  margin-left: 20px;
  padding: 0;
  outline: none;
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  border: none;
  &:hover{
    cursor:pointer
  }
  ${props => props.disabled && css`
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  `}
  ${props => props.buttonType === 'cancel' && css`
    color: ${props.popUpType === 'warning' ? props.theme.colors.darkgrey : props.theme.colors.lightgreen};
    text-align: end;
    ${props.popUpType === 'notification' && css`
      color: ${props.theme.colors.lightgrey};
    `}
  `}
  ${props => props.buttonType === 'submit' && css`
    padding: 5px 10px;
    min-height: 36px;
    max-height: 50px;
    text-align: center;
    border-radius: 4px;
    ${props.popUpType === 'warning' && css`
      color: ${props.theme.colors.warning};
      border: 1px solid ${props.theme.colors.warning};
    `}
    ${(props.popUpType === 'info' || props.popUpType === 'notification') && css`
      color: ${props.theme.colors.lightgreen};
      border: 1px solid ${props.theme.colors.lightgreen};
    `}
  `}
`;

PopUpButton.propTypes = {
  popUpType: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default PopUpButton;

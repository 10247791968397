import 'reflect-metadata'
import { IsBoolean, IsIn, IsInt, IsOptional, IsString, MaxLength, Min, MinLength, ValidateNested, Max, IsArray, ArrayNotEmpty } from 'class-validator'
import { Type } from 'class-transformer'
import { htmlNotEmpty } from './helpers';
import { FormField } from './FormField'

export type GreetingType = 'notify' | 'sayTime' | 'greet';
export class WaitTime {
    @IsOptional()
    @Min(0)
    @IsInt()
    seconds?: number

    @IsOptional()
    @Min(0)
    @IsInt()
    minutes?: number
}

export class BaseScriptNode {
    @IsOptional()
    @IsInt()
    public id?: number

    @IsOptional()
    @IsString()
    public tempId?: string

    @IsIn(['say', 'wait', 'goto', 'report'])
    nodeType: 'say' | 'wait' | 'goto' | 'report'

    @IsOptional()
    @IsInt()
    scriptStepId?: number

    @IsOptional()
    @IsString()
    scriptStepTempId?: string
}

export class ScriptNodeTTS extends BaseScriptNode {
    @IsIn(['say'])
    @IsString()
    nodeType: 'say'

    @htmlNotEmpty()
    @MinLength(1)
    @IsString()
    text: string

    @IsBoolean()
    @IsOptional()
    notify?: boolean

    @IsBoolean()
    @IsOptional()
    greet?: boolean

    @IsBoolean()
    @IsOptional()
    sayTime?: boolean
}

export class ScriptNodeWait extends BaseScriptNode {
    @IsIn(['wait'])
    @IsString()
    nodeType: 'wait'

    @IsInt()
    @Min(1)
    seconds: number

    @ValidateNested()
    @Type(() => WaitTime)
    waitTime?: WaitTime
}

export class ScriptNodeReport extends BaseScriptNode {
    @IsIn(['report'])
    @IsString()
    nodeType: 'report'

    @IsString()
    reportType: string
    
    @IsArray()
    @ArrayNotEmpty()
    @ValidateNested({ each: true })
    @Type(() => FormField)
    formFields: FormField[]
}

export class ScriptNodeGoTo extends BaseScriptNode {
    @IsIn(['goto'])
    @IsString()
    nodeType: 'goto'

    @IsInt()
    @Min(1)
    @Max(99)
    maxPasses?: number
}

export type ScriptNode = (ScriptNodeTTS | ScriptNodeWait | ScriptNodeGoTo | ScriptNodeReport)
export type ScriptStoreNode = ScriptNode & { tempId: string; showInvalid?: boolean; createdAt?: number; existing?: boolean }

import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit"
import { fetchScriptDeprecated, fetchScriptVersion, fetchScript } from "../../../mScripts/api"
import { prepareScriptState } from "../../../mScripts/tessaScript/helpers/helpers"
import { getReports, fetchTessaScript } from "../../../mScripts/tessaScript/redux/thunks"
import { FullScriptGet } from "../../../mScripts/tessaScript/types/ScriptVersion"
import { taskSetScript } from "../reducer/reducer"
import { taskSetScriptDeprecated } from "../actions/actions"
import { TessaScriptDenormalized } from "../../../mScripts/tessaScript/types/DeprecatedScript"

export const getTaskScript = createAsyncThunk(
  'task/getTaskScript',
  async (
    {
      robotId,
      scriptId,
      v1,
      scriptVersionId
    }: {
      robotId: number
      scriptId: number | string
      v1: boolean
      scriptVersionId?: number
    },
    { dispatch }
  ) => {
    try {
      let response
      if (v1) {
        response = await fetchScriptDeprecated(robotId, scriptId)
      } else if (scriptVersionId) {
        response = await fetchScriptVersion(robotId, scriptId, scriptVersionId)
      } else {
        response = await fetchScript(robotId, scriptId)
      }
      if ((response.data as FullScriptGet).script) {
        // new script type
        const dispatchResult = await dispatch(getReports({ robotId }))
        const reports = unwrapResult(dispatchResult)
        const prepared = prepareScriptState(response.data as FullScriptGet, reports)
        dispatch(fetchTessaScript.fulfilled(prepared, 'tessaScript/fetchTessaScript/fulfilled', {} as any))
        dispatch(taskSetScript(response.data))
      } else {
        dispatch(taskSetScriptDeprecated(response.data as TessaScriptDenormalized))
      }
    } catch (e) {
      dispatch(fetchTessaScript.rejected(null, 'tessaScript/fetchTessaScript/rejected', {} as any))
      dispatch(
        taskSetScript({
          script: {}
        })
      )
    }
  }
)

import {
  SET_ROBOTS,
  SET_ROBOT_CONFIG,
  SESSION_LOGOUT,
  ROBOTS_HIDE_UPDATE_BANNER,
  ROBOTS_SET_UPDATE_STATUS,
  ROBOTS_SHOW_UPDATE_POPUP,
  INVITE_PAIR_ROBOT,
  INVITE_PAIR_ROBOT_ERROR,
  INVITE_PAIR_ROBOT_SUCCESS,
  SET_ACTIVE_ROBOT,
  SET_LOADING,
  SET_LOADING_FINISHED
} from '../constants/actionTypes'

import { PAIRING_TURN_ON_ALL_CAPABILITIES } from '../../../pairing/redux/constants/actionTypes'
import moment from 'moment'

export const initialRobotsState = []

const sortName = (profileRobotUser) => {
  const { robot, endUserName, alias } = profileRobotUser
  const displayName = alias ?? endUserName ?? robot
  return displayName
}

const setRobots = action => ([...action.robots.sort((a, b) => {
  if(sortName(a) < sortName(b)) { return -1; }
  if(sortName(a) > sortName(b)) { return 1; }
  return 0;
})])
const clearRobots = initialRobotState => initialRobotState

const filterRobots = (robots) => {
  const activeRobot = window.localStorage.getItem('activeRobot')
  if (!activeRobot) return robots
  const filtered = robots.filter(r => r.id === parseInt(activeRobot))
  if (filtered.length) {
    return filtered
  }
  return robots
}

export const allRobots = (state = initialRobotsState, action) => {
  switch (action.type) {
    case SET_ROBOTS: return setRobots(action)
    case SESSION_LOGOUT: return clearRobots(initialRobotsState)
    case INVITE_PAIR_ROBOT_SUCCESS: {
      const robot = action.payload[0]
      const existingIndex = state.findIndex(r => r.id === robot.id)
      const existingRobot = state[existingIndex]
      if (existingIndex < 0) {
        return [...state, robot]
      }
      const updatedRobot = { ...existingRobot, ...robot }
      const newState = [...state.filter((elem, i) => i !== existingIndex), updatedRobot]
      return newState }
    default: return state
  }
}

export const robots = (state = initialRobotsState, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROBOT:
      window.localStorage.setItem('activeRobot', action.payload)
      return filterRobots(state)
    case SET_ROBOTS: return filterRobots(setRobots(action))
    case SESSION_LOGOUT: return clearRobots(initialRobotsState)
    case INVITE_PAIR_ROBOT_SUCCESS: {
      const robot = action.payload[0]
      const existingIndex = state.findIndex(r => r.id === robot.id)
      const existingRobot = state[existingIndex]
      if (existingIndex < 0) {
        return [...state, robot]
      }
      const updatedRobot = { ...existingRobot, ...robot }
      const newState = [...state.filter((elem, i) => i !== existingIndex), updatedRobot]
      return filterRobots(newState) }
    default: return filterRobots(state)
  }
}

const session = JSON.parse(window.localStorage.getItem('Tinybots.session'))

export const initialRobotConfigState = {
  timestamp: '',
  capabilities: [],
  hardware: {},
  software: {},
  showUpdatePopUp: false,
  hideUpdateBanner: session && session.tessaUpdating
    ? moment().isBefore(session.tessaUpdating, 'days')
    : false
}

const setRobotConfig = (state, action) => ({...state, ...action.config, capabilities: action.config.capabilities})

const setShowUpdatePopUp = (state, action) => ({...state, showUpdatePopUp: action.payload})

const clearRobotConfig = initialRobotConfigState => initialRobotConfigState

const robotsHideBanner = state => ({ ...state, hideUpdateBanner: true })

const robotsSetUpdateStatus = (state, action) => ({
  ...state,
  software: {
    ...state.software,
    updateStatus: action.payload
  }
})

const pairingTurnOnAllCapabilities = state => ({
  ...state,
  capabilities: ['remoteUpdate-v2', 'scriptExecution-v1', 'remoteRestart-v1']
})

export const robotPairing = (state = { invitePairing: false, invitePairError: false, invitePairSuccess: false }, action) => {
  switch (action.type) {
    case INVITE_PAIR_ROBOT: return { ...state, invitePairing: true, invitePairError: false, invitePairSuccess: false }
    case INVITE_PAIR_ROBOT_ERROR: return { ...state, invitePairing: false, invitePairError: action.payload, invitePairSuccess: false }
    case INVITE_PAIR_ROBOT_SUCCESS: return { ...state, invitePairing: false, invitePairError: false, invitePairSuccess: true }
    default: return state
  }
}

export const robotConfig = (state = initialRobotConfigState, action) => {
  switch (action.type) {
    case ROBOTS_SHOW_UPDATE_POPUP: return setShowUpdatePopUp(state, action)
    case SET_ROBOT_CONFIG: return setRobotConfig(state, action)
    case SESSION_LOGOUT: return clearRobotConfig(initialRobotConfigState)
    case ROBOTS_HIDE_UPDATE_BANNER: return robotsHideBanner(state)
    case ROBOTS_SET_UPDATE_STATUS: return robotsSetUpdateStatus(state, action)
    case PAIRING_TURN_ON_ALL_CAPABILITIES: return pairingTurnOnAllCapabilities(state)
    case SET_LOADING: return { ...state, loading: true }
    case SET_LOADING_FINISHED: return { ...state, loading: false }
    default: return state
  }
}

export const currentRobotSelector = ({ robotConfig, robots }) => {
  return robots.filter(robot => robot.id === robotConfig.activeRobotId)
}

import axios from 'axios';
import { getUrls } from '../../../common/constants';

export const fetchPlaylists = robotId => {
  const url = getUrls('soundwave', 'playlist').replace('{robotId}',robotId);
  return axios.get(url);
};

export const crudPlaylist = (robotId, playlistId, action = 'GET', body) => {
  const url = action.toUpperCase() === 'PUT'
    ? getUrls('soundwave', 'playlist').replace('{robotId}', robotId)
    : getUrls('soundwave', 'crudplaylist').replace('{robotId}', robotId).replace('{playlistId}', playlistId);

  switch(action) {
    case 'DELETE' :
      return axios.delete(url);
    case 'GET' :
      return axios.get(url);
    case 'PUT' :
      return axios.put(url, body);
    default :
      return axios.get(url);
  }
};

export const musicOverview = robotId => {
  const url = getUrls('soundwave', 'collection').replace('{robotId}',robotId);
  return axios.get(url);
};

export const musicMeta = robotId => {
  const url = getUrls('soundwave', 'meta').replace('{robotId}',robotId);
  return axios.get(url);
};

export const uploadSongs = (robotId, data, config) => {
  const url = getUrls('soundwave', 'collection').replace('{robotId}',robotId);
  return axios.post(url, data, config);
};

export const musicGetSongs = (robotId, data) => {
  const url = getUrls('soundwave', 'songs').replace('{robotId}',robotId);
  return axios.post(url, data);
};

export const musicGetSong = (robotId, songId) => {
  const url = getUrls('soundwave', 'crudcollection').replace('{robotId}',robotId).replace('{songId}',songId);
  return axios.get(url);
};

export const musicDeleteSong = (robotId, songId) => {
  const url = getUrls('soundwave', 'crudcollection').replace('{robotId}',robotId).replace('{songId}',songId);
  return axios.delete(url);
};

export const  musicDeleteSongs = (robotId, data) => {
  const url = getUrls('soundwave', 'collection').replace('{robotId}',robotId);
  return axios({
    'method' : 'DELETE',
    'url' : url,
    'data' : data,
    'headers': {
      "Content-Type": "application/json;charset=utf-8",
      "x-time-zone": "Europe/Amsterdam"
    }
  })
};
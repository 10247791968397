/**
 * @file Map form fields, hiding fields when conditions not met
 */

import { FormField } from '../types/FormField'

const checkConditionsMet = (form: FormField[], formField: FormField, deep: boolean) => {
  if (formField.conditions && formField.conditions.length) {
    return formField.conditions.every(condition => {
      const referenceField = form.find(field => field.key === condition.key)
      if (deep && (!referenceField || !checkConditionsMet(form, referenceField, false))) {
        return false
      } else {
        return referenceField !== undefined && condition.matchesValue.includes(referenceField.value ?? '')
      }
    })
  } else {
    return true
  }
}

export const formFieldMapper = (form: FormField[]) => {
  const formFields = form.reduce((fields, formField) => {
    if (formField.conditions && formField.conditions.length) {
      const conditionsMet = checkConditionsMet(form, formField, true)
      return conditionsMet ? [...fields, formField] : fields
    } else {
      return [...fields, formField]
    }
  }, [] as FormField[])
  return formFields
}

/**
 * @file Convert old closed questions to multiple choice questions and prepare multiple choice questions for store
 */

import i18n from '../../../../localization/i18n'
import {
  ScriptStoreNext,
  NextMultipleChoiceCommand,
  NextClosedQuestion,
  NextMultipleChoiceStandard
} from '../types/Next'
import { ScriptStoreNode } from '../types/ScriptNode'
import { ScriptStoreStep } from '../types/ScriptStep'
import { FullScriptState } from '../types/ScriptVersion'
import { v4 as uuidv4 } from 'uuid'
import { getOfflineMessage, sayNodeData } from './createNewStep'

type NewArrays = {
  newNodes: ScriptStoreNode[]
  newNextSteps: ScriptStoreNext[]
  newSteps: ScriptStoreStep[]
}

const getNewNextBase = (nxt: ScriptStoreNext) => {
  const {
    id,
    scriptStepId,
    nextStepId,
    answer,
    scriptStepTempId,
    nextStepTempId,
    tempId
  } = nxt as NextClosedQuestion
  const newNext = {
    id,
    tempId,
    scriptStepId,
    nextStepId,
    scriptStepTempId,
    nextStepTempId,
    nextType: 'multipleChoice',
    intentionType: answer !== 'other' ? 'command' : 'other'
  } as ScriptStoreNext
  return newNext as NextMultipleChoiceCommand
}

const convertOldAnswerNext = ({
  newNext,
  answer
}: {
  newNext: NextMultipleChoiceCommand
  answer: string
}) => {
  newNext.primaryCommand = answer
  newNext.secondaryCommands = []
}

const addOffline = ({
  newNext,
  newNodes,
  newNextSteps,
  newSteps,
  lang
}: {
  newNext: NextMultipleChoiceCommand
  lang: string
} & NewArrays) => {
  // if answer is other, also add an offline option with corresponding step and node
  const offlineMessage = getOfflineMessage(lang)
  const offlineStepTempId = uuidv4()
  newSteps.push({
    tempId: offlineStepTempId,
    stepType: 'say',
    first: false
  })
  newNodes.push({
    scriptStepTempId: offlineStepTempId,
    ...sayNodeData({
      greet: false,
      notify: false,
      sayTime: false
    }),
    text: offlineMessage
  } as ScriptStoreNode)
  const nextOffline: NextMultipleChoiceStandard & { tempId: string } = {
    tempId: uuidv4(),
    scriptStepId: newNext.scriptStepId,
    scriptStepTempId: newNext.scriptStepTempId,
    nextType: 'multipleChoice',
    intentionType: 'offline',
    nextStepTempId: offlineStepTempId
  }
  newNextSteps.push(nextOffline)
  newNextSteps.push({
    tempId: uuidv4(),
    scriptStepTempId: offlineStepTempId,
    nextType: 'then'
  })
}

const getQuestionNextState = ({
  nxt,
  newNext,
  newNextSteps,
  newNodes,
  newSteps,
  lang
}: {
  nxt: ScriptStoreNext
  newNext: NextMultipleChoiceCommand
  lang: string
} & NewArrays) => {
  const { answer: answerOld } = nxt as NextClosedQuestion
  let answer = 'other'
  switch (answerOld) {
    case 'yes':
      answer = 'ja'
      break;
    case 'no':
      answer = 'nee'
  }
  if (answer !== 'other') {
    // if yes or no, add primary command
    convertOldAnswerNext({
      newNext,
      answer
    })
  } else {
    // if answer is other, also add an offline option with corresponding step and node
    addOffline({
      newNext,
      newNextSteps,
      newNodes,
      newSteps,
      lang
    })
  }
  return {
    ...newNext
  } as ScriptStoreNext
}

export const convertQuestions = (script: FullScriptState): FullScriptState => {
  const newNodes: ScriptStoreNode[] = []
  const newSteps: ScriptStoreStep[] = []
  const newNextSteps: ScriptStoreNext[] = []
  script.scriptNext = script.scriptNext.map(nxt => {
    if ((nxt as NextClosedQuestion).answer) {
      const newNext = getNewNextBase(nxt)
      return getQuestionNextState({
        nxt,
        newNext,
        newNextSteps,
        newNodes,
        newSteps,
        lang: script?.script?.scriptLanguage
      })
    } else {
      return nxt
    }
  })
  script.scriptSteps = script.scriptSteps.map(step => {
    if (step.stepType === 'closedQuestion') {
      return { ...step, stepType: 'multipleChoice' }
    } else {
      return step
    }
  })
  return {
    ...script,
    scriptNext: [...script.scriptNext, ...newNextSteps],
    scriptNodes: [...script.scriptNodes, ...newNodes],
    scriptSteps: [...script.scriptSteps, ...newSteps]
  }
}

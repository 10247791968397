import { combineReducers, CombinedState, AnyAction } from 'redux'
import { session, user, robots, allRobots, robotConfig, robotPairing } from './features/auth/redux/reducers'
import { selectPlaylist } from './features/music/SelectPlaylist/redux/reducers'
import { playlistSelectMusic } from './features/music/PlaylistSelectMusic/redux/reducers'
import { playlistSongsSequence } from './features/music/PlaylistMusicSequence/redux/reducers'
import { musicUpload } from './features/music/MusicUpload/redux/reducers'
import { musicOverview } from './features/music/MusicOverview/redux/reducers'
import { ui, appTime, heartbeat } from './common/redux/reducers'
import { taskReducer as task } from './features/task/redux/reducer/reducer'
import { scriptFeedback } from './features/mScripts/ScriptFeedback/redux/reducers'
import { overview } from './features/overview/redux/reducers'
import { speechInteractionSingle } from './features/speechInteraction/SpeechInteractionSingle/redux/reducer'
import { directSpeech } from './features/directSpeech/redux/reducers'
import { settings } from './features/settings/redux/reducers'
import { emailChange } from './features/unconfirmedEmail/redux/reducers'
import { pairing } from './features/pairing/redux/reducers'
import { activate } from './features/activate/redux/reducers'
import { login } from './features/auth/login/redux/reducers'
import { forgotPassword } from './features/auth/forgotPassword/redux/reducers'
import { resetPassword } from './features/auth/resetPassword/redux/reducers'
import { signup } from './features/auth/signup/redux/reducers'
import { robotSettings } from './features/robotSettings/redux/reducer'
import { permissions } from './features/permissions/redux/reducer'
import { tessaScript } from './features/mScripts/tessaScript/redux/reducer'
import { tessaScriptOverview } from './features/mScripts/tessaScriptOverview/redux/reducer'
import { robotUser } from './features/generalSettings/RobotUsers/redux/reducer'
import { userProfile } from './features/generalSettings/UserProfile/redux/reducer'
import { eula } from './features/information/redux/reducer'
import { appNotifications } from './features/appNotifications/redux/reducer'
import { offlineNotifications } from './features/offlineNotifications/redux/reducer'
import { SESSION_LOGOUT } from './features/auth/redux/constants/actionTypes'
import { speechInteractionOverview } from './features/speechInteraction/SpeechInteractionOverview/redux/reducer'

const appReducer = combineReducers({
  robotSettings,
  session,
  user,
  robots,
  allRobots,
  robotPairing,
  robotConfig,
  selectPlaylist: selectPlaylist as any,
  playlistSelectMusic,
  playlistSongsSequence,
  musicUpload: musicUpload as any,
  musicOverview: musicOverview as any,
  speechInteractionOverview,
  speechInteractionSingle,
  ui,
  appTime,
  heartbeat,
  task,
  tessaScript,
  tessaScriptOverview,
  scriptFeedback,
  overview,
  directSpeech,
  settings,
  emailChange,
  pairing,
  activate,
  login,
  forgotPassword,
  resetPassword,
  signup,
  robotUser,
  userProfile,
  eula,
  appNotifications,
  offlineNotifications,
  permissions
})

export type RootState = ReturnType<typeof appReducer>

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
): CombinedState<ReturnType<typeof appReducer>> => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === SESSION_LOGOUT) {
    localStorage.clear()
    return appReducer(undefined, { type: undefined })
  }

  return appReducer(state, action)
}

export default rootReducer

import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { ClickAwayListener, Tooltip, withStyles, styled, TooltipProps } from "@material-ui/core";

const ContentDiv = styled('div')({
    display: 'flex',
    alignItems: 'center'
})

export type CustomTooltipProps = { disableOpenOnTouch?: boolean, children: React.ReactElement, title: string, interactive?: boolean, hideOnMouseLeave?: boolean, closeAfterTimeout?: number, closeCallback?: () => void, hide?: boolean } & TooltipProps

const StyledTooltip = withStyles({
    tooltip: {
        marginBottom: '-10px',
        fontFamily: 'Montserrat',
        '&& p': {
            fontSize: '10px',
            lineHeight: '13px',
            textAlign: 'left',
            '&:last-child': {
                margin: 0
            }
        },
        '&& strong': {
            fontFamily: 'Montserrat-Bold'
        },
        '&& a': {
            textDecoration: 'underline',
            color: 'white',
            fontFamily: 'Montserrat-Bold'
        },
        '&& ul': {
            fontSize: '10px',
            marginTop: -6,
            paddingLeft: 0,
            'list-style-type': 'none',
            lineHeight: 1.4
        },
        visibility: ({hidden}: {hidden?: boolean}) => hidden ? 'hidden' : 'visible'
    },
    tooltipPlacementBottom: {
        margin: "4px 0",
    },
})(Tooltip);


export const TooltipMaterial = ({ disableOpenOnTouch, children, title, interactive, hideOnMouseLeave = true, closeAfterTimeout, closeCallback, hidden, ...rest }: CustomTooltipProps) => {
    const [open, setOpen] = React.useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);


    useEffect(() => {
        if(!open) {
            setTimeout(() => {
                if (!isMounted.current) return
                closeCallback?.();
            }, 200);
        }
    }, [open, closeCallback]);

    const handleScroll = () => {
        if (open) {
            setOpen(false);
        }
    }

    let preventOpen = false;

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, true);
        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        }
    });

    const detectTouchStart = (e: React.TouchEvent) => {
        if (disableOpenOnTouch) {
            e.preventDefault()
            preventOpen = true
        }
    };

    const handleTooltipClose = () => {
        if (!isMounted.current) return
        setOpen(false);
    };

    const handleTooltipOpen = (e: any) => {
        if (!isMounted.current) return
        if (!preventOpen) {
            setOpen(true);
        }
        if (closeAfterTimeout) {
            setTimeout(() => {
                handleTooltipClose();
            }, closeAfterTimeout);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div onMouseLeave={hideOnMouseLeave ? handleTooltipClose : () => { }}>
                <StyledTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    interactive={interactive}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    hidden={!title}
                    title={
                        <React.Fragment>
                            <div>
                                <ReactMarkdown source={title}></ReactMarkdown>
                            </div>
                        </React.Fragment>
                    }
                    arrow
                    {...rest}
                >
                    <ContentDiv onTouchStart={detectTouchStart} onClick={disableOpenOnTouch ? () => { } : handleTooltipOpen} onMouseEnter={handleTooltipOpen}>
                        {children}
                    </ContentDiv>
                </StyledTooltip>
            </div>
        </ClickAwayListener>
    );
}
import {
	SCRIPT_FEEDBACK_CLEAR,
	SCRIPT_FEEDBACK_SET
} from '../constants/actionTypes';

import {
	convertScriptFeedbackToArray,
	convertQuestionFeedbackToArray,
	convertReminderFeedbackToArray,
	convertFutureScriptToArrayDeprecated }
	from '../../../utils';
import { taskClearState } from '../../../../task/redux/reducer/reducer'

export const initialState = {
	scriptFeedbackArray: []
};

const scriptFeedbackClear = () => initialState;

const scriptFeedbackSet = (state, action) => {
	const getFeedbackArray = (type, data) => {
		switch (type) {
			case 'script': return convertScriptFeedbackToArray(data);
			case 'scriptInFuture': return convertFutureScriptToArrayDeprecated(data);
			case 'reminder': return convertReminderFeedbackToArray(data);
			case 'question': return convertQuestionFeedbackToArray(data);
			default: return [];
		}
	};
	const scriptFeedbackArray = getFeedbackArray(action.payload.feedbackType, action.payload.data);

	return {...state, scriptFeedbackArray}
};

export const scriptFeedback = (state = initialState, action) => {
	switch (action.type) {
		case SCRIPT_FEEDBACK_CLEAR:
		case taskClearState.type: return scriptFeedbackClear();
		case SCRIPT_FEEDBACK_SET: return scriptFeedbackSet(state, action);
		default: return state;
	}
};

import {
	DIRECT_SPEECH_CLEAR,
	DIRECT_SPEECH_HIDE_OFFLINE_POPUP,
	DIRECT_SPEECH_SEND_START,
	DIRECT_SPEECH_SEND_FINISH,
	DIRECT_SPEECH_SET_CALL_TO_ACTION,
	DIRECT_SPEECH_TOGGLE_GREETING,
	DIRECT_SPEECH_TOGGLE_PRONOUNCE_TIME
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	success: false,
	error: false,
	showRobotOfflinePopUp: null,
	callToAction: '',
	pronounceTime: true,
	greeting: true
};

const directSpeechClear = () => initialState;

const directSpeechHideOfflinePopUp = state => ({...state, showRobotOfflinePopUp: false});

const directSpeechSendStart = state => ({...state, done: false, success: false});

const directSpeechSendFinish = (state, action) => {
	const {success, error, robotOffline} = action.payload;
	const callToAction = success ? '' : state.callToAction;
	return {...state, success, error, showRobotOfflinePopUp: robotOffline, done: true, callToAction};
};

const directSpeechSetCallToAction = (state, action) => ({...state, callToAction: action.payload});

const directSpeechTogglePronounceTime = state => ({ ...state, pronounceTime: !state.pronounceTime });

const directSpeechToggleGreeting = state => ({...state, greeting: !state.greeting});

export const directSpeech = (state = initialState, action) => {
	switch (action.type) {
		case DIRECT_SPEECH_CLEAR: return directSpeechClear();
		case DIRECT_SPEECH_HIDE_OFFLINE_POPUP: return directSpeechHideOfflinePopUp(state);
		case DIRECT_SPEECH_SEND_START: return directSpeechSendStart(state);
		case DIRECT_SPEECH_SEND_FINISH: return directSpeechSendFinish(state, action);
		case DIRECT_SPEECH_SET_CALL_TO_ACTION: return directSpeechSetCallToAction(state, action);
		case DIRECT_SPEECH_TOGGLE_PRONOUNCE_TIME: return directSpeechTogglePronounceTime(state);
		case DIRECT_SPEECH_TOGGLE_GREETING: return directSpeechToggleGreeting(state);
		default: return state;
	}
};

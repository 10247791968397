import {
	PAIRING_CLEAR,
	PAIRING_HIDE_POP_UP,
	PAIRING_START,
	PAIRING_FINISH,
	PAIRING_ENABLE_SUBMIT,
	PAIRING_UPDATE_START,
	PAIRING_UPDATE_FINISH
} from '../constants/actionTypes';

export const initialState = {
	done: true,
	success: false,
	cpuSerial: '',
	accountStatus: '',
	accountEmail: '',
	submitting: false,
	showPopUp: false,
	extraCharacters: 0,
};

const pairingClear = initialState => initialState;

const pairingStart = state => ({...state, done: false, submitting: true});

const pairingFinish = (state, action) => {
	let extraCharacters = action.payload.extraCharacters;
	if (extraCharacters > 0) {
		extraCharacters = state.extraCharacters + extraCharacters;
	}
	return {...state, done: true, ...action.payload, extraCharacters};
};

const pairingEnableSubmit = state => ({...state, submitting: false});

const pairingHidePopUp = state => ({...state, showPopUp: false, extraCharacters: 0});

const pairingUpdateStart = state => ({...state, done: false});

const pairingUpdateFinish = (state, action) => ({...state, done: true, ...action.payload});

export const pairing = (state = initialState, action) => {
	switch (action.type) {
		case PAIRING_CLEAR: return pairingClear(initialState);
		case PAIRING_START: return pairingStart(state);
		case PAIRING_FINISH: return pairingFinish(state, action);
		case PAIRING_ENABLE_SUBMIT: return pairingEnableSubmit(state);
		case PAIRING_HIDE_POP_UP: return pairingHidePopUp(state);
		case PAIRING_UPDATE_START: return pairingUpdateStart(state);
		case PAIRING_UPDATE_FINISH: return pairingUpdateFinish(state, action);
		default: return state;
	}
};

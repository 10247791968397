/**
 * @file
 * API queries for the speech interaction overview
 * @author Max van Loosbroek
 */

import axios, { AxiosResponse } from 'axios'
import { SpeechInteraction } from '../../types'
import { getUrls } from '../../../../common/constants'

export const getSpeechInteractions = (
  robotId: number,
  scriptReferenceId?: number
) => {
  const url = getUrls('custom_commands', 'speech-interactions').replace(
    '{robotId}',
    robotId
  )
  const requestOptions = scriptReferenceId ? {
    params: { scriptReferenceId }
  } : undefined
  return axios.get<SpeechInteraction[]>(url, requestOptions)
}

export const deleteSpeechInteraction = (robotId: number, id: number | string) => {
  const url =
    getUrls('custom_commands', 'speech-interactions').replace(
      '{robotId}',
      robotId
    ) + `/${id}`
  return axios.delete(url);
}

export const getDefaultSpeechInteractions = (
  robotId: number,
  scriptReferenceId?: number
): Promise<AxiosResponse<SpeechInteraction[]>> => {
  const url = getUrls('custom_commands', 'speech-interactions').replace(
    '{robotId}',
    robotId
  ) + '/default'
  const requestOptions = scriptReferenceId ? {
    params: { scriptReferenceId }
  } : undefined
  return axios.get<SpeechInteraction[]>(url, requestOptions)
}

export const toggleDefaultSpeechInteraction = (
  interactionId: number,
  robotId: number,
  enabled: boolean
) => {
  const url = getUrls('custom_commands', 'default-speech-interactions-toggle').replace(
    '{robotId}',
    robotId
  ).replace(
    '{interactionId}',
    interactionId
  )
  return axios.post<SpeechInteraction>(url, { enabled })
}
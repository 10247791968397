import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PopUp from '../../../../common/components/PopUp/PopUp'
import { unpair } from '../redux/reducer'

export const useUnpair = (robotId: number, self: boolean, robotSwitcher: boolean = false): {
  unPairPopup: any
  startUnpair: (id: number, uuid?: string | undefined) => void
} => {
  const { t } = useTranslation() as { t: (path: string) => string }
  const dispatch = useDispatch()
  const [userId, setUserToDelete] = useState<number | null>(null)
  const [uuid, setUuid] = useState<string>('')
  const startUnpair = (id: number, uuid?: string): void => {
    setUserToDelete(id)
    if (uuid !== undefined) setUuid(uuid)
  }
  const textType = robotSwitcher ? 'POPUP.REMOVE_SELF' : 'GENERAL_SETTINGS.PAIRED_USERS.UNPAIR_POPUP'

  const unPairPopup = userId !== null ? (
    <PopUp
      type='warning'
      title={t(`${textType}.TITLE`)}
      text={t(`${textType}.MESSAGE`)}
      onSubmit={() => {
        if (userId !== null) dispatch(unpair({ userId, robotId, uuid, self }))
        setUserToDelete(null)
      }}
      onCancel={() => setUserToDelete(null)}
      submitButton={t(`${textType}.BUTTON_NEXT`)}
      cancelButton={t(`${textType}.BUTTON_PREV`)}
    />
  ) : null
  return { unPairPopup, startUnpair }
}

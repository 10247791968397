import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentText = styled.p`
	margin: 35px auto;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: ${props => props.theme.colors.lightgrey};
  @media (max-width: 450px){
    margin: 20px auto;
  }
`;

export const ContentDiv = styled.div`
	margin: 35px auto;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: ${props => props.theme.colors.lightgrey};
  @media (max-width: 450px){
    margin: 20px auto;
  }
`;

ContentText.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ContentText;

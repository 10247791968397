/**
 * @file Functions for validating and displaying script scriptVariables.
 */

import React from 'react'
import { TEMPORAL_UNITS, TemporalUnit } from '../types/TemporalUnit'
import styledComponentsTheme from '../../../../common/constants/styledComponentsTheme/styledComponentsTheme'

const REGEX_COMMAND = /\$\{([^}]*)\}/g

const VALID_BASE_VARIABLES = [
  'DAY_OF_WEEK',
  'DAY',
  'DAY_OF_MONTH',
  'DATE',
  'TOMORROW',
  'YESTERDAY',
  'YEAR',
  'MONTH',
  'TIME_SPOKEN',
  'TIME_12H',
  'TIME_24H'
]
type BaseVariable = typeof VALID_BASE_VARIABLES[number]

const getModifierValue = (modifierValue: string): number => {
  const value = parseInt(modifierValue)
  if (isNaN(value) || modifierValue.length > 5) {
    throw new Error('Invalid modifier value')
  } else {
    return value
  }
}

const getModifier = (modifier: string): TemporalUnit => {
  if (TEMPORAL_UNITS.includes(modifier)) {
    return modifier as TemporalUnit
  } else {
    throw new Error('Modifier not found')
  }
}

const getScriptVariable = (scriptVariable: string): BaseVariable => {
  if (VALID_BASE_VARIABLES.includes(scriptVariable)) {
    return scriptVariable as BaseVariable
  } else {
    throw new Error('ScriptVariable not found')
  }
}

export const splitScriptVariable = (scriptVariable?: string) => {
  if(!scriptVariable) {
    throw Error('Script variable empty')
  }
  let operatorIndex: number = -1
  const operatorIndexes = [...scriptVariable.matchAll(/\-|\+/g)].map((matchArray) => matchArray.index!)
  if(operatorIndexes.length === 0) {
    // If neither '+' nor '-' is found, return the original input as the first element of the array
    return [scriptVariable]
  } else if (operatorIndexes.length === 1) {
    operatorIndex = operatorIndexes[0]
  } else {
    throw Error('Script variable cannot have multiple operators')
  }

  if (operatorIndex !== -1) {
    // If '+' or '-' is found, split the string into two parts
    return [
      scriptVariable.substring(0, operatorIndex),
      scriptVariable.substring(operatorIndex, scriptVariable.length - 1),
      scriptVariable.substring(scriptVariable.length - 1)
    ]
  } else {
    // If neither '+' nor '-' is found, return the original input as the first element of the array
    return [scriptVariable]
  }
}

export const getScriptVariables = (text: string) => {
  const variables = text.matchAll(REGEX_COMMAND)
  return [...variables]
}

export const checkScriptVariable = (scriptVariable: string) => {
  let scriptVariableAndModifiers: string[]
  scriptVariableAndModifiers = splitScriptVariable(scriptVariable)
  if (scriptVariableAndModifiers.length == 3) {
    getModifierValue(scriptVariableAndModifiers[1])
    getModifier(scriptVariableAndModifiers[2])
  }
  getScriptVariable(scriptVariableAndModifiers[0])
  return true
}

export const validateScriptVariables = (text: string, capabilities: string[]) => {
  const variables = getScriptVariables(text).map(([_, variable]) => variable)
  if (!capabilities.includes('scriptVariables-v1') && variables.length > 0) {
    throw Error('Script variables not supported')
  }
  return variables.every(checkScriptVariable)
}

export const hasInvalidVariables = (text: string, capabilities: string[]) => {
  try {
    validateScriptVariables(text, capabilities)
  } catch (_) {
    return true
  }
  return false
}

const escapeRegExp = (s: string) => {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const splitInvalid = (text: string, capabilities: string[]) => {
  const variables = getScriptVariables(text)
  const vars = variables.map(([full, variable]) => {
    if (!capabilities.includes('scriptVariables-v1')) {
      return {
        valid: false,
        full
      }
    }
    try {
      checkScriptVariable(variable)
      return {
        valid: true,
        full
      }
    } catch (error) {
      return {
        valid: false,
        full
      }
    }
  })
  const allInvalid = vars
    .map((item) => {
      return escapeRegExp(item.full)
    })
    .join('|')
    let regex
  // TODO: test properly on old safari for ipad
  const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;
  if (!isSafari) {
    regex = new RegExp(`((?=${allInvalid})|(?<=${allInvalid}))`, 'g')
  } else {
    regex = new RegExp(`(${allInvalid})`, 'g')
  }
  return { stringParts: text.split(regex).filter(s => s !== ''), vars }
}

export const highlightScriptVariablesInvalid = (text: string, capabilities: string[], active: boolean): (React.ReactNode | string) => {
  const parts = splitInvalid(text, capabilities)
  const color: any = active ? 'black' : 'rgba(0,0,0,0.65)'
  const elements = parts.stringParts.map((part, i) => {
    const variable = parts.vars.find(v => v.full === part)
    if(variable?.valid) {
      return <span key={i} style={{  whiteSpace: 'pre-wrap', color: styledComponentsTheme.colors.darkgreen, display: 'inline' }}>{part}</span>
    } else if (variable) {
      return <span key={i} style={{ color: 'red', whiteSpace: 'pre-wrap', display: 'inline' }}>{part}</span>
    } else {
      return <span key={i} style={{ whiteSpace: 'pre-wrap', display: 'inline', color }}>{part}</span>
    }
  })
  return <span style={{ whiteSpace: 'break-spaces' }}>{elements}</span>
}

import {
	OVERVIEW_SET_DATE,
	OVERVIEW_SET_TYPE,
	OVERVIEW_SET_TASKS,
	OVERVIEW_GET_TASKS,
	OVERVIEW_TOGGLE_MENU,
	OVERVIEW_TASK_DELETE_START,
	OVERVIEW_TASK_DELETE_FINISH,
	OVERVIEW_CHECK_TASKS_IN_PAST
} from '../constants/actionTypes';
import moment from 'moment';
import { setWeekHeaders, setDayTasks, setWeekTasks } from '../../utils';
import { getDateAndTimeConstants } from '../../../../common/constants';

export const initialState = {
	pickedDate: null,
	done: true,
	calendarView: 'day',
	dayTasks: [],
	weekTasks: [],
	weekHeaders: []
};

const overviewSetDate = (state, action) => ({...state, pickedDate: action.payload});

const overviewSetType = (state, action) => ({...state, calendarView: action.payload});

const overviewGetTasks = state => ({
	...state,
	dayTasks: [],
	weekTasks: [],
	weekHeaders: [],
	done: false
});

const overviewSetTasks = (state, action) => {
	const dayTasks = state.calendarView === 'day' ? setDayTasks(action.tasks, action.now) : [];
	const weekHeaders = state.calendarView === 'week' ? setWeekHeaders(state.pickedDate) : [];
	const weekTasks = state.calendarView === 'week'
		? setWeekTasks(action.tasks, getDateAndTimeConstants('overviewWeek'), weekHeaders, action.now)
		: [];
	return {...state, dayTasks, weekTasks, weekHeaders, done: true};
};

const overviewToggleMenu = (state, action) => {
	const dayTasks = state.dayTasks.map(item => ({
		...item,
		overflow: item.id === action.payload.id ? !item.overflow : item.overflow
	}));
	return {...state, dayTasks};
};

const overviewTaskDeleteStart = state => ({...state, done: false});

const overviewTaskDeleteFinish = (state, action) => {
	const dayTasks = action.payload
		? state.dayTasks.filter(task => task.id !== action.payload)
		: [...state.dayTasks];
	return { ...state, done: true, dayTasks };
};

const overviewCheckTasksInPast = (state, action) => {
	if ((state.calendarView === 'day' && (!state.dayTasks.length || state.dayTasks.filter(task => task.overflow).length))
		|| (state.calendarView === 'week' && !state.weekTasks.length)) {
		return state;
	}

	const dayTasks = state.calendarView === 'day' ? setDayTasks(state.dayTasks, action.payload) : [];

	const weekTasks = state.calendarView === 'week'
		? state.weekTasks.map(time => ({
			...time,
			days: time.days.map(day => ({
				...day,
				tasks: day.tasks.map(task => ({
					...task,
					past: moment(task.time)
						.startOf('minute')
						.subtract(1, 'minutes')
						.isBefore(action.payload)
				}))
			}))
		}))
		: [];

	return {...state, dayTasks, weekTasks};
};

export const overview = (state = initialState, action) => {
	switch (action.type) {
		case OVERVIEW_SET_DATE: return overviewSetDate(state, action);
		case OVERVIEW_SET_TYPE: return overviewSetType(state, action);
		case OVERVIEW_GET_TASKS: return overviewGetTasks(state);
		case OVERVIEW_SET_TASKS: return overviewSetTasks(state, action);
		case OVERVIEW_TOGGLE_MENU: return overviewToggleMenu(state, action);
		case OVERVIEW_TASK_DELETE_START: return overviewTaskDeleteStart(state);
		case OVERVIEW_TASK_DELETE_FINISH: return overviewTaskDeleteFinish(state, action);
		case OVERVIEW_CHECK_TASKS_IN_PAST: return overviewCheckTasksInPast(state, action);
		default: return state;
	}
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { adjustScheduleEndTime } from '../../utils'
import { taskDelete, getPlaylist, convertTaskScriptV2, checkRobotStatus, taskSave } from '../thunks/thunks'
import { RecurrenceType, Schedule } from '../types/Schedule'
import { TaskState } from '../types/TaskState'
import { taskSetScriptDeprecated, taskEdit, taskSetSchedule } from '../actions/actions'

export const initialState: TaskState = {
  showRobotOfflinePopUp: false,
  pickedDate: null,
  taskId: null,
  title: '',
  time: null,
  schedule: null,
  done: false,
  playlist: null,
  script: null,
  scriptDeprecated: null,
  reminder: null,
  question: null,
  editSequence: false,
  shouldUpdateDeletedPlaylist: false,
  endTimeReset: false,
  schedulePatternType: null,
  plannedTaskTime: null,
  taskDeleted: false,
  changesMade: false
}

export const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {
    taskClearState: () => initialState,
    taskClearErrors: state => ({ ...state, saveError: undefined, convertError: undefined }),
    taskSetMusic: (state, action: { payload: { name: string } }) => ({
      ...state,
      playlist: action.payload,
      done: true,
      title: action.payload.name
    }),
    taskSetScript: (state, action) => ({
      ...state,
      script: action.payload,
      done: true,
      title: action.payload.script.scriptName
    }),
    taskSetSchedulePatternType: (state, action) => {
      return { ...state, schedulePatternType: action.payload }
    },
    taskSetRecurringInterval: (
      state,
      {
        payload: { recurrenceType, recurrenceInterval }
      }: {
        payload: {
          recurrenceType: RecurrenceType
          recurrenceInterval: number
        }
      }
    ) => {
      return { ...state, schedule: { ...state.schedule!, recurrenceType, recurrenceInterval } }
    },
    setLegacyTask: state => ({ ...state, done: true }),
    taskHideRobotOfflinePopUp: state => ({ ...state, showRobotOfflinePopUp: false }),
    taskSetChangesMade: (state, action: { payload?: any }) => ({ ...state, ...action.payload })
  },
  extraReducers: builder => {
    builder.addCase(taskSetScriptDeprecated, (state, { payload }) => ({
      ...state,
      scriptDeprecated: payload,
      done: true,
      title: payload.name as string
    }))
    builder.addCase(taskEdit, (state, action) => ({ ...state, ...action.payload }))
    builder.addCase(taskSetSchedule, (state, action) => {
      const { schedule, endTimeReset } = adjustScheduleEndTime({
        pickedDate: action.payload.pickedDate,
        schedule: action.payload.schedule
      })
      return { ...state, schedule, pickedDate: action.payload.pickedDate, endTimeReset }
    })
    // delete
    builder.addCase(taskDelete.pending, state => ({ ...state, done: false }))
    builder.addCase(taskDelete.fulfilled, state => ({
      ...state,
      done: true,
      taskDeleted: true
    }))
    builder.addCase(taskDelete.rejected, state => ({
      ...state,
      done: true,
      taskDeleted: false
    }))
    // save
    builder.addCase(taskSave.pending, state => ({ ...state, done: false }))
    builder.addCase(taskSave.fulfilled, (state, action) => ({
      ...state,
      done: true,
      plannedTaskTime: action.payload
    }))
    builder.addCase(taskSave.rejected, (state, action) => ({
      ...state,
      done: true,
      saveError: action?.error?.message
    }))
    // get playlist
    builder.addCase(getPlaylist.fulfilled, (state, action) => {
      if (action.payload) {
        return { ...state, playlist: action.payload, done: true, title: action.payload.name }
      }
    })
    // convert
    builder.addCase(convertTaskScriptV2.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.onSuccess()
        if (action.payload.taskId) {
          return { ...state, done: true, convertError: false, taskId: action.payload.taskId }
        } else {
          return { ...state, done: true, convertError: false }
        }
      }
    })
    builder.addCase(convertTaskScriptV2.rejected, state => ({
      ...state,
      done: true,
      convertError: true
    }))
    // check robot status
    builder.addCase(checkRobotStatus.pending, state => ({ ...state, done: false }))
    builder.addCase(checkRobotStatus.fulfilled, (state, { payload }) => ({
      ...state,
      done: true,
      showRobotOfflinePopUp: payload
    }))
    builder.addCase(checkRobotStatus.rejected, (state, { payload }) => ({
      ...state,
      done: true,
      showRobotOfflinePopUp: true
    }))
  }
})

export const {
  taskClearState,
  taskClearErrors,
  taskSetScript,
  taskSetSchedulePatternType,
  taskHideRobotOfflinePopUp,
  setLegacyTask,
  taskSetChangesMade,
  taskSetMusic,
  taskSetRecurringInterval
} = taskSlice.actions
const { reducer } = taskSlice
export const taskReducer = reducer

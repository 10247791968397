import axios from 'axios';
import { getUrls } from '../../../common/constants';

export const newTask = (data: any, robotId: number, timeZone: string) => {
  const url = getUrls('eve', 'newTask').replace('{robotId}', robotId);

  return axios({
    method: 'PUT',
    url,
    data,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'x-time-zone': timeZone
    }
  });
};

export const deleteTask = (data: any, robotId: number) => {
  const url = getUrls('eve', 'deleteTask').replace('{robotId}', robotId);

  return axios({
    method : 'DELETE',
    url,
    data,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "x-time-zone": "Europe/Amsterdam"
    }
  });
};

export const deleteTaskDeprecated = (data: any, robotId: number) => {
  const url = getUrls('eve', 'deleteTask').replace('{robotId}', robotId);

  return axios({
    method : 'DELETE',
    url,
    data,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "x-time-zone": "Europe/Amsterdam"
    }
  });
};

import styled from 'styled-components';

const ButtonGroup = styled.div`
	display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 18px;
  }
`;

export default ButtonGroup;

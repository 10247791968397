/**
 * @file Assign treeposition to steps, treepositions are string to indicate the position of a step in the script
 */

import { Update } from "@reduxjs/toolkit"
import { ScriptState } from "../redux/ScriptState"
import { startStepSelector, stepsSelector } from "../redux/selectors"
import { NextGoTo } from "../types/Next"
import { ScriptStepWithPosition } from "../types/ScriptStep"
import { sortMultipleChoice } from "./sortMultipleChoice"

const getPosition = ({branchIndex, parentPosition}: { branchIndex?: number, parentPosition: string }) => {
  const parentIndex = parseInt(parentPosition.slice(-1));
  const parentIsAnswerResponse = parentPosition.slice(-2, -1) === '-';
  if (branchIndex) {
      return parentPosition + `-${branchIndex}`;
  } else if (parentIsAnswerResponse) {
      return parentPosition + '.1';
  } else {
      return (parentPosition || '1').slice(0, -1) + (parentIndex + 1);
  }
}

const assignTreePositionRecursive = ({
  step,
  branchIndex,
  parentPosition,
  processedSteps,
  steps
}: {
  step: ScriptStepWithPosition
  branchIndex?: number
  parentPosition: string
  processedSteps: string[]
  steps: ScriptStepWithPosition[]
}) => {
  if (!step) {
      return;
  }
  if (processedSteps.find(s => s === step.tempId)) {
      throw Error('Infinite script')
  }

  step.treePosition = getPosition({ branchIndex, parentPosition })
  processedSteps.push(step.tempId)
  const branches = step.nextSteps.filter(s => s.nextType === 'closedQuestion' || s.nextType === 'multipleChoice')

  if (step.stepType === 'multipleChoice') {
    step.nextSteps = step.nextSteps.sort(sortMultipleChoice)
  }

  step.nextSteps.forEach((next, index) => {
      // exclude goto links outside normal flow (not used for determining treePosition)
      if ((next as NextGoTo)?.maxReached === false) {
          return;
      }
      const childStep = steps.find(s => s.tempId === next.nextStepTempId);
      if (!childStep) return
      const branchIndex = branches.length > 0 && index + 1;
      if (childStep && branchIndex) {
          assignTreePositionRecursive({ step: childStep, parentPosition: step.treePosition as string, branchIndex, processedSteps, steps });
      } else if (childStep) {
          assignTreePositionRecursive({ step: childStep, parentPosition: step.treePosition as string, processedSteps, steps });
      }
  });
}


export const assignTreePositions = (state: ScriptState, startingPosition: string = '0', startStep?: string): Update<(ScriptStepWithPosition)>[] => {
  const stateClone: ScriptState = JSON.parse(JSON.stringify(state))
  const firstStepId = startStep || startStepSelector(stateClone)?.tempId
  const steps = stepsSelector(stateClone)
  const firstStep = steps.find(s => s.tempId === firstStepId)
  const processedSteps: string[] = []
  if (firstStep) {
      assignTreePositionRecursive({ step: firstStep, parentPosition: startingPosition, processedSteps, steps });
      return steps.map(s => (
          { id: s.tempId, changes: { treePosition: s.treePosition } }
      )).filter(s => s.id !== undefined);
  }
  return []
};
/**
 * @file Functions to add new steps to state.
 */

import { EntityAdapter, EntityId } from "@reduxjs/toolkit"
import { entityArray } from "../redux/reducer"
import { ScriptStoreNext } from "../types/Next"
import { ScriptStoreNode } from "../types/ScriptNode"
import { ScriptStoreStep, StepType } from "../types/ScriptStep"
import { getStepsToRemove } from "../helpers/helpers"
import { createNewStep } from "../helpers/createNewStep"
import { startStepSelector, stepSingleSelector } from "../redux/selectors"
import { assignTreePositions } from "./assignTreePositions"
import { ScriptState } from "../redux/ScriptState"

export const addScriptStep = ({ scriptStepsAdapter, scriptNodesAdapter, scriptNextAdapter, payload, state }: {
    scriptStepsAdapter: EntityAdapter<ScriptStoreStep>,
    scriptNodesAdapter: EntityAdapter<ScriptStoreNode>,
    scriptNextAdapter: EntityAdapter<ScriptStoreNext>,
    payload: {
        stepType: StepType
        parentNextTempId?: string | undefined
        tempId?: string,
        lang?: string
    },
    state: ScriptState
}) => {
    const { parentNextTempId, stepType, tempId } = payload
    if (stepType === 'report' && state.reports.length < 1) {
        throw new Error('No reports found for report step')
    }
    const parentNext = parentNextTempId ? state.scriptNext.entities[parentNextTempId] : undefined
    const parentId = parentNext?.scriptStepTempId as EntityId
    const parentStep = (parentId && stepSingleSelector(parentId as string)(state)) || undefined
    const parentNode = Object.values(state.scriptNodes.entities).find(node => node?.scriptStepTempId === parentStep?.tempId)
    const first = !parentNext
    const isClosedQuestion = stepType === 'closedQuestion'
    const isMultipleChoice = stepType === 'multipleChoice'
    const isQuestion = isClosedQuestion || isMultipleChoice

    const replacedFirstStep = first && startStepSelector(state)
    const currentNextStepTempId = replacedFirstStep ? replacedFirstStep.tempId : parentNext?.nextStepTempId
    if (isQuestion && first) {
        scriptNodesAdapter.removeAll(state.scriptNodes)
        scriptStepsAdapter.removeAll(state.scriptSteps)
        scriptNextAdapter.removeAll(state.scriptNext)
    }
    const lang = state.script.scriptLanguage
    const { newScriptNodes, newScriptSteps, newScriptNext } = createNewStep({ stepType, first, nextStepTempId: currentNextStepTempId, parentNode, uuid: tempId, lang, reports: state.reports })
    const currentNodes = entityArray(state.scriptNodes)
    const currentNext = entityArray(state.scriptNext)
    scriptNodesAdapter.addMany(state.scriptNodes, newScriptNodes)
    scriptStepsAdapter.addMany(state.scriptSteps, newScriptSteps)
    scriptNextAdapter.addMany(state.scriptNext, newScriptNext)
    if (replacedFirstStep && replacedFirstStep.tempId) {
        scriptStepsAdapter.updateOne(
            state.scriptSteps,
            { id: replacedFirstStep.tempId, changes: { first: false } }
        )
    }
    if (isQuestion && currentNextStepTempId && currentNextStepTempId !== tempId) {
        const stepsToRemove = getStepsToRemove(currentNodes, currentNext, currentNextStepTempId)
        scriptNodesAdapter.removeMany(state.scriptNodes, stepsToRemove.nodeTempIds)
        scriptStepsAdapter.removeMany(state.scriptSteps, stepsToRemove.stepTempIds)
        scriptNextAdapter.removeMany(state.scriptNext, stepsToRemove.nextTempIds)
    }
    if (parentNext) {
        scriptNextAdapter.updateOne(state.scriptNext, { id: parentNext.tempId, changes: { nextStepTempId: newScriptSteps[0].tempId } })
    }
    const treePositions = assignTreePositions(state)
    scriptStepsAdapter.updateMany(state.scriptSteps, treePositions)
    state.stateHasChanged = true
    return newScriptSteps[0]
}